const generateID = (() => {
  let id = 0
  return () => ++id
})()

export type StartJobFN<ReturnType = void> = (onProgress: (progress: number) => void) => {
  promise: Promise<ReturnType>
  cancel: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PendingJob<T = any> = {
  id: number
  description?: string
  start: StartJobFN<T>
  resolve?: (value: T) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject?: (reason?: any) => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RunningJob<T = any> = {
  id: number
  description?: string
  promise: Promise<T>
  cancel: () => void
  progress: number
}

type FinishedJob = {
  id: number
  description?: string
  success: boolean
}

export type JobInfo = {
  id: number
  description?: string
  progress: number
  status: 'pending' | 'running' | 'success' | 'error'
}

export default class JobQueue {
  private pendingJobs: PendingJob[] = []
  private runningJobs: RunningJob[] = []
  private finishedJobs: FinishedJob[] = []

  public constructor(private maxParallelCount = 5, public onUpdate?: (data: JobInfo[]) => void) {}

  private handleUpdate() {
    const jobList: JobInfo[] = this.pendingJobs
      .map(
        ({ id, description }): JobInfo => ({
          id,
          description,
          progress: 0,
          status: 'pending'
        })
      )
      .concat(
        this.runningJobs.map(
          ({ id, description, progress }): JobInfo => ({
            id,
            description,
            progress,
            status: 'running'
          })
        )
      )
      .concat(
        this.finishedJobs.map(
          ({ id, description, success }): JobInfo => ({
            id,
            description,
            progress: 1,
            status: success ? 'success' : 'error'
          })
        )
      )
    this.onUpdate?.(jobList)
  }

  private checkPending() {
    if (this.runningJobs.length < this.maxParallelCount && this.pendingJobs.length) {
      // Start the next job
      const [{ start, resolve, reject, ...rest }] = this.pendingJobs.splice(0, 1)
      const job: RunningJob = {
        ...rest,
        promise: Promise.resolve(),
        cancel: () => undefined,
        progress: 0
      }
      const { promise, cancel } = start((progress) => {
        job.progress = progress
        this.handleUpdate()
      })
      job.promise = promise
      job.cancel = cancel
      const finish = (success: boolean) => {
        this.finishedJobs.push({
          ...rest,
          success
        })
        this.runningJobs = this.runningJobs.filter((x) => x !== job)
        this.checkPending()
      }
      job.promise
        .then((x) => {
          finish(true)
          return x
        })
        .catch((e) => {
          finish(false)
          throw e
        })
        .then(resolve)
        .catch(reject)
      this.runningJobs.push(job)
    }
    this.handleUpdate()
  }

  enqeueJob(job: Omit<PendingJob, 'id'>): number {
    const id = generateID()
    this.pendingJobs.push({
      id,
      ...job
    })
    this.checkPending()
    return id
  }

  cancelJob(id: number): void {
    this.cancelJobs([id])
  }

  cancelJobs(ids: number[]): void {
    for (const id of ids) {
      this.runningJobs.find((x) => x.id === id)?.cancel()
    }
    this.pendingJobs = this.pendingJobs.filter((x) => !ids.includes(x.id))
    this.runningJobs = this.runningJobs.filter((x) => !ids.includes(x.id))
    this.finishedJobs = this.finishedJobs.filter((x) => !ids.includes(x.id))
    this.checkPending()
  }

  clearFinishedJobs(): void {
    this.finishedJobs = []
    this.handleUpdate()
  }
}
