import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import JobQueue, { JobInfo } from '../../../scripts/JobQueue'
import { UploadsState } from '../types/uploadsTypes'

const queue = new JobQueue(5)

const initialState: UploadsState = {
  jobs: []
}

const uploads = createSlice({
  name: 'uploads',
  initialState,
  reducers: {
    updateJobInfo(state, { payload }: PayloadAction<JobInfo[]>) {
      state.jobs = payload
    }
  }
})

export const initUploads = createAsyncThunk('uploads/init', (_x: never, { dispatch }) => {
  queue.onUpdate = (jobs) => dispatch(uploads.actions.updateJobInfo(jobs))
})

export const enqeueUpload = (job: Parameters<JobQueue['enqeueJob']>[0]): number => {
  return queue.enqeueJob(job)
}

export const removeUploads = (ids: number[]): void => {
  return queue.cancelJobs(ids)
}

export default uploads
