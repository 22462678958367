import React from 'react'

export type ToolbarProps = {
  children?: React.ReactNode
  right?: React.ReactNode
}

const Toolbar: React.FC<ToolbarProps> = ({ right, children }) => {
  return (
    <div className="listview-header">
      <div className="container d-flex flex-row">
        <div className="d-flex flex-row align-items-center flex-grow-1 flex-shrink-1">{children}</div>
        {!!right && <div className="d-flex flex-row align-items-center">{right}</div>}
      </div>
    </div>
  )
}

export default Toolbar
