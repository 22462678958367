import { t } from '@lingui/macro'
import fileIcon from 'jsx:./../../../assets/icons/file.svg'
import React, { FormEvent, useState } from 'react'
import { Button, Form, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router-dom'
import { Icon } from '../../../components/Icon'
import Modal, { ModalRef, useModalActions } from '../../../components/Overlays/Modal'
import { useUploadSteelHeatingFile } from '../../../data/remote/FileData'
import { useLocalizedPath } from '../../../data/remote/Language'
import { useSettings } from '../../../data/remote/Settings'
import { SteelHeatingsData, useCreateSteelHeating } from '../../../data/remote/SteelHeatings'

import useCombinedRef from '../../../scripts/useCombinedRef'
import { truncate } from 'lodash'

export type AddSteelHeatingModalProps = {
  onSuccess?: (data: SteelHeatingsData) => unknown
  supplierId?: string
  advancedPermission?: boolean
  steelHeatingData?: SteelHeatingsData[]
}

export const AddSteelHeatingModal = React.forwardRef<ModalRef, AddSteelHeatingModalProps>(
  function CreateSteelHeatingModal({ supplierId, advancedPermission, steelHeatingData }, ref) {
    const handleResetForm = () => {
      reset()
      setFileData(null)
      setInitiated(false)
    }

    const modalActions = useModalActions()
    const { settings } = useSettings()
    const modalRef = useCombinedRef(modalActions.ref, ref)
    const createSteelHeating = useCreateSteelHeating()

    const [isDragandDrop, setIsDragandDrop] = useState(false)
    const [dragging, setDragging] = useState(false)
    const [fileData, setFileData] = useState<FileList | null>(null)
    const [initiated, setInitiated] = useState(false)
    const [uploadSteelHeatingFiles] = useUploadSteelHeatingFile(
      {
        type: 'steelheatingfile',
        supplierId
      },
      modalActions,
      isDragandDrop,
      fileData,
      handleResetForm
    )

    const {
      reset,
      register,
      handleSubmit,
      formState: { errors }
    } = useForm<{
      content: string
      file: FileList
    }>()
    const localizedPath = useLocalizedPath()
    const navigation = useHistory()

    const onSubmit = handleSubmit((data) => {
      if (steelHeatingData) {
        const existingSteelHeating = steelHeatingData.find((steelHeating) => steelHeating.identifier === data.content)
        if (existingSteelHeating) {
          const localizedPathString = localizedPath(!advancedPermission ? '/de/wek-protokolle' : '/de/stahlwerke')
          const path = generatePath(localizedPathString + '/:supplierId/:id', {
            supplierId,
            id: existingSteelHeating.id
          })
          modalActions.close()
          reset()
          setFileData(null)
          setInitiated(false)
          navigation.push(path)
        } else {
          if (!createSteelHeating.running && supplierId) {
            createSteelHeating.run(
              {
                supplier: supplierId,
                identifier: data.content,
                ...data
              },
              (data) => {
                const localizedPathString = localizedPath(!advancedPermission ? '/de/wek-protokolle' : '/de/stahlwerke')
                const path = generatePath(localizedPathString + '/:supplierId/:id', {
                  supplierId,
                  id: data.id
                })
                modalActions.close()
                handleResetForm()
                navigation.push(path)
              }
            )
          }
        }
      }
    })

    const onClose = () => {
      handleResetForm()
      modalActions.close()
    }

    const handleFileSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      uploadSteelHeatingFiles()
    }

    const formRef = React.useRef<HTMLFormElement>(null)
    return (
      <Modal ref={modalRef} centered onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t`Schmelze hinzufügen`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-75 mx-auto">
            <Form onSubmit={fileData ? handleFileSubmit : onSubmit} name="addSteelHeatingForm" ref={formRef}>
              <Form.Group>
                {advancedPermission && (
                  <div className="text-center">
                    <div className="mb-3">
                      <Form.Label>{t`Laden Sie eine PDF hoch, um die Daten automatisch zu extrahieren`}</Form.Label>
                      <div
                        className={`uploadheating mt-3 ${dragging ? 'dragging' : ''}`}
                        onDrop={(e) => {
                          e.preventDefault()
                          setFileData(e.dataTransfer.files)
                          setDragging(false)
                        }}
                        onDropCapture={(e) => {
                          e.preventDefault()
                          setFileData(e.dataTransfer.files)
                          setDragging(false)
                        }}
                        onDragOver={(e) => {
                          e.preventDefault()
                          setDragging(true)
                          setIsDragandDrop(true)
                        }}
                        onDragLeave={() => {
                          setDragging(false)
                          setIsDragandDrop(false)
                          setFileData(null)
                        }}
                      >
                        <Form.Label htmlFor="file" className="w-100 text-center my-1">
                          <div className="d-flex justify-content-center">
                            <div className="d-flex flex-row justify-content-center align-items-center">
                              <Icon svg={fileIcon} className="mr-2 fileIcon" />
                              <span>
                                {truncate(fileData?.[0].name, { length: 30 }) || t`Datei auswählen oder hier ablegen`}
                              </span>
                            </div>
                          </div>
                        </Form.Label>
                        <Form.Control
                          as="input"
                          accept={settings?.uploads.steelCertificates.types.join(',')}
                          {...register('file', {
                            required: { value: false, message: t`Dieses Feld ist erforderlich` }
                          })}
                          isInvalid={!!errors.file}
                          onChange={(e) => {
                            const target = e.target as HTMLInputElement
                            setFileData(target.files)
                            if (target.files) {
                              setIsDragandDrop(true)
                            }
                          }}
                          onClick={() => {
                            // if a file was set, setIsDragandDrop to true
                            if (fileData) {
                              setIsDragandDrop(true)
                            }
                          }}
                          type="file"
                          className="w-100 hidden-input"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <hr className="w-50" />
                      <span className="px-2">{t`Oder`}</span>
                      <hr className="w-50" />
                    </div>
                    <Form.Label className="mt-3">{t`Daten manuell eingeben`}</Form.Label>
                  </div>
                )}
                <div className="d-flex justify-content-center align-items-center mt-3">
                  <div className="mb-3 text-center">
                    <div className="flex-row d-flex justify-content-center align-items-center">
                      <div className="mr-3 font-weight-bold">{t`Schmelzennummer`}</div>
                      <Form.Control
                        as="input"
                        {...register('content', {
                          required: { value: false, message: t`Dieses Feld ist erforderlich` }
                        })}
                        isInvalid={!!errors.content}
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">{errors.content?.message}</Form.Control.Feedback>
                  </div>
                </div>

                <Button
                  variant="primary"
                  type="submit"
                  size="lg"
                  className="w-100 d-flex justify-content-center align-items-center"
                  onClick={() => {
                    setInitiated(true)
                    formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
                  }}
                  disabled={createSteelHeating.running || initiated}
                >
                  {initiated && <Spinner className="mr-2" animation="border" size="sm" />}
                  {initiated ? t`Schmelze wird hinzugefügt` : t`Schmelze hinzufügen`}
                </Button>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
)
