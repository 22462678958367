import { t } from '@lingui/macro'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { usePermission } from './User'

export type RegulationCategoryDataMinimal = {
  id: number
  localizedTitle: string
  title: LocalizedTitles
  files: number[]
}

export type RegulationCategoryData = RegulationCategoryDataMinimal

export const useRegulationCategory = createApiItemHook<RegulationCategoryData>('/regulationcategories')

export type UseRegulationCategoriesParams = never

export const useRegulationCategories = createApiDataHook<
  RegulationCategoryDataMinimal[],
  UseRegulationCategoriesParams
>('/regulationcategories')

export type CreateRegulationCategoryInput = {
  readonly title: {
    readonly de: string
    readonly en?: string
  }
}
export const useCanCreateRegulationCategories = (): boolean => usePermission('Alle_Dateien_verwalten')
export const useCreateRegulationCategory = (): {
  run: (data: CreateRegulationCategoryInput, onSuccess?: (data: RegulationCategoryDataMinimal) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: CreateRegulationCategoryInput, onSuccess?: (data: RegulationCategoryDataMinimal) => unknown],
    RegulationCategoryDataMinimal
  >(
    () => ({
      createRequestData: ({ ...data }) => {
        return [`/regulationcategories`, prefixApiParams<CreateRegulationCategoryInput>(data), { method: 'POST' }]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/regulationcategories')
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}
