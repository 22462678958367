import { t } from '@lingui/macro'
import { useEffect, useMemo } from 'react'
import { useCurrentRoute } from '../data/remote/RouteData'
import { useAuthentication } from '../data/remote/User'

export const useTitle = (): void => {
  const websiteTitle = 'VETTER ' + t`Lieferantenportal`
  const currentRoute = useCurrentRoute()

  const { loggedIn } = useAuthentication()

  const title = useMemo(() => {
    if (currentRoute?.title === 'Home') {
      return loggedIn ? t`Dashboard` : t`Login`
    }
    return currentRoute?.title
  }, [currentRoute?.title, loggedIn])

  useEffect(() => {
    if (!title) {
      return
    }

    const documentTitle = !title.includes(websiteTitle) ? `${title} | ${websiteTitle}` : title

    const prevTitle = document.title
    document.title = documentTitle
    return () => {
      document.title = prevTitle
    }
  }, [title, websiteTitle])
}
