import React from 'react'

const languageIcons = {
  de: require('../assets/flags/de.png'),
  en: require('../assets/flags/en.png')
} as const

const languageTitles = {
  de: 'Deutsch',
  en: 'Englisch'
}

export type FlagProps = {
  language: keyof typeof languageIcons
}

const Flag: React.FC<FlagProps> = ({ language }) => {
  return (
    <div className="flag-icon">
      <img src={languageIcons[language]} title={languageTitles[language]} />
    </div>
  )
}

export default Flag
