import React, { useEffect, useMemo, useState } from 'react'

import { ApiHookData } from '../../scripts/api'
import { Column, ColumnProps, ListItemProps } from './Column'
import { HeatingStatus, Status } from '../../types/StatusTypes'
import { useAuthentication } from '../../data/remote/User'

export type ColumnData<T, FilterType extends string = string> = {
  items: T[] | undefined
  current: T | undefined
  search: string
  searchTitle?: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  type?: 'default' | 'complaint' | 'steelworks' | 'certificate'
  filter: FilterType
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>
  loading: boolean
  filterOptions?: Record<FilterType, string>
  renderItem: (item: T, index: number, items: T[]) => ListItemProps
  disableSearch: boolean
  arrow?: 'left' | 'right'
  activeTitle?: boolean
  status?: Status | HeatingStatus
}

export type UseColumnDataArgs<T, FilterType extends string = string> = {
  isActiveItem: (item: T) => boolean
  shouldHaveActiveItem?: boolean
  onActiveItemMiss?: () => void
  renderItem: (item: T, index: number, items: T[]) => ListItemProps
  onFilterChange?: (args: { filter: string; search: string }) => unknown
  arrow?: 'left' | 'right'
  activeTitle?: boolean
  type?: 'default' | 'complaint' | 'steelworks' | 'certificate'
} & (
  | {
      filterOptions?: undefined
      disableSearch?: false
      useItems: (search: string) => ApiHookData<T[]>
    }
  | {
      filterOptions?: undefined
      disableSearch: true
      useItems: () => ApiHookData<T[]>
    }
  | {
      filterOptions: Record<FilterType, string>
      disableSearch: true
      useItems: (filter?: FilterType) => ApiHookData<T[]>
    }
  | {
      useItems: (search: string, filter: FilterType) => ApiHookData<T[]>
      filterOptions: Record<FilterType, string>
      disableSearch?: false
    }
)

export function useColumnData<T, FilterType extends string = string>(
  getArgs: (params: {
    setFilter: React.Dispatch<
      React.SetStateAction<{
        search: string
        filter: FilterType
      }>
    >
  }) => UseColumnDataArgs<T, FilterType>,
  dependencies: unknown[]
): ColumnData<T, FilterType> {
  const [filter, setFilter] = useState<{
    search: string
    filter: FilterType
  }>({ search: '', filter: '' as FilterType })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const args = useMemo(() => getArgs({ setFilter }), dependencies)
  const defaultFilterOption = (Object.keys(args.filterOptions ?? {})[0] ?? '') as FilterType
  useEffect(() => {
    setFilter((filter) => ({
      search: filter.search,
      filter: filter.filter || defaultFilterOption
    }))
  }, [defaultFilterOption])

  const { isActiveItem, shouldHaveActiveItem, onActiveItemMiss } = args
  let data: ApiHookData<T[]> | undefined

  if (args.disableSearch && !args.filterOptions) {
    data = args.useItems()
  } else if (!args.disableSearch && !args.filterOptions) {
    data = args.useItems(filter.search, filter.filter)
  } else if (args.disableSearch && !!args.filterOptions) {
    data = args.useItems(filter.filter)
  } else {
    data = args.useItems(filter.search as string & FilterType, filter.filter)
  }
  const { loading, data: items } = data
  const current = useMemo(() => Object.values(items ?? {}).find(isActiveItem), [isActiveItem, items])

  useEffect(() => {
    if (!loading && !current && shouldHaveActiveItem) {
      onActiveItemMiss?.()
    }
  }, [current, loading, onActiveItemMiss, shouldHaveActiveItem])

  const { onFilterChange } = args

  return useMemo(
    () => ({
      items,
      current,
      search: filter.search,
      setSearch(nextSearch) {
        setFilter(({ filter, search }) => {
          const _nextSearch = typeof nextSearch === 'function' ? nextSearch(search) : nextSearch

          onFilterChange?.({ search: _nextSearch, filter })
          return {
            search: _nextSearch,
            filter
          }
        })
      },
      filter: filter.filter,
      setFilter(nextFilter) {
        setFilter(({ filter, search }) => {
          const _nextFilter = typeof nextFilter === 'function' ? nextFilter(filter) : nextFilter
          onFilterChange?.({ search, filter: _nextFilter })
          return {
            search,
            filter: _nextFilter
          }
        })
      },
      loading,
      filterOptions: args.filterOptions,
      renderItem: args.renderItem,
      disableSearch: !!args.disableSearch,
      arrow: args.arrow,
      activeTitle: args.activeTitle,
      type: args.type
    }),
    [
      args.activeTitle,
      args.arrow,
      args.disableSearch,
      args.filterOptions,
      args.renderItem,
      args.type,
      current,
      filter,
      items,
      loading,
      onFilterChange
    ]
  )
}

export type DataColumnProps<T> = Omit<ColumnProps, 'items' | 'onSearch' | 'filter'> &
  ColumnData<T> & {
    renderItem: (item: T, index: number, items: T[]) => ListItemProps
  }

function DataColumn<T>({
  setSearch,
  filter,
  setFilter,
  items,
  filterOptions,
  renderItem,
  disableSearch,
  type = 'default',
  arrow,
  searchTitle,
  ...props
}: DataColumnProps<T>): React.ReactElement {
  const isSupplier = useAuthentication().user?.isSupplier
  const filterMenu = useMemo(
    () =>
      Object.entries(filterOptions ?? {}).map(([key, title]) => ({
        title,
        onSelect() {
          setFilter(key)
        },
        active: filter === key
      })),
    [filter, filterOptions, setFilter]
  )

  let _items = useMemo(
    () =>
      Array.isArray(items)
        ? items?.map((x, ...args) => ({
            active: x === props.current,
            ...renderItem(x, ...args)
          }))
        : [],
    [items, props, renderItem]
  )

  if (isSupplier && type === 'complaint') {
    // remove items with status 0
    _items = _items.filter((item) => item.hasComplaint && item.status && item.status >= 1)
  }

  return (
    <Column
      {...props}
      type={type}
      searchTitle={searchTitle ? searchTitle : undefined}
      onSearch={disableSearch ? undefined : setSearch}
      filter={filterMenu.length ? filterMenu : undefined}
      items={_items}
      arrow={arrow}
    />
  )
}

export default DataColumn
