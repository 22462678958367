import { SteelCertificatesData } from '../../../data/remote/SteelCertificates'
import { SteelHeatingsData } from '../../../data/remote/SteelHeatings'
import { SupplierDataMinimal } from '../../../data/remote/Supplier'
import FilesHeaderSteelworks from './FilesHeaderSteelworks'
import SteelworksTable from './SteelworksTable'

type SteelWorksCertificateViewProps = {
  steelCertificate: SteelCertificatesData
  steelHeating: SteelHeatingsData
  supplier: SupplierDataMinimal
}

const SteelWorksCertificateView: React.FC<SteelWorksCertificateViewProps> = ({
  steelCertificate,
  steelHeating,
  supplier
}) => {
  return (
    <>
      <FilesHeaderSteelworks certificate={steelCertificate} steelHeating={steelHeating} supplier={supplier}>
        <SteelworksTable steelCertificate={steelCertificate} />
      </FilesHeaderSteelworks>
    </>
  )
}

export default SteelWorksCertificateView
