import { t } from '@lingui/macro'
import React, { useCallback, useEffect, useMemo } from 'react'
import DataTable, { dynamicRows } from '../../../components/DataTable/DataTable'
import TableStatusIcon from '../../../components/DataTable/TableStatusIcon'
import { CertificateDataMinimal, getStatusText } from '../../../data/remote/Certificate'

export type SelectCertificatesTableProps = {
  certificates: CertificateDataMinimal[] | undefined
  loading: boolean
  value?: number[]
  onChange?: (value: number[]) => unknown
}

const SelectCertificatesTable: React.FC<SelectCertificatesTableProps> = ({
  certificates,
  loading,
  value,
  onChange
}) => {
  useEffect(() => {
    if (!loading) {
      const validValues = certificates ? (value ?? []).filter((id) => certificates.find((cert) => cert.id === id)) : []
      if (validValues.length !== (value ?? []).length) {
        onChange?.(validValues)
      }
    }
  }, [certificates, loading, onChange, value])

  const columns = useMemo(
    () => ({ name: t`Dateiname`, /* date: t`Änderungsdatum`, */ status: t`Status`, selected: t`Übernehmen` }),
    []
  )

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const codeId = +e.target.value
      if (e.target.checked !== (value ?? []).includes(codeId)) {
        if (e.target.checked) {
          onChange?.((value ?? []).concat([codeId]))
        } else {
          onChange?.((value ?? []).filter((x) => x !== codeId))
        }
      }
    },
    [onChange, value]
  )

  const renderTableRow = useCallback(
    (item: CertificateDataMinimal) => ({
      name: item.file.properties.title,
      //date: dayjs(item.tstamp).format('L'),
      status: <TableStatusIcon status={item.status.approval} tooltip={getStatusText(item.status)} />,
      selected: <input type="checkbox" value={item.id} onChange={handleChange} />
    }),
    [handleChange]
  )

  return (
    <div className="form-table-wrap">
      <DataTable columns={columns} items={dynamicRows(certificates, loading, t`Keine Zertifikate gefunden`)}>
        {renderTableRow}
      </DataTable>
    </div>
  )
}

export default SelectCertificatesTable
