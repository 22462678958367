import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Typo3GalleryData } from '../../data/remote/PageContent'
import ContentImage from './ContentImage'
import _ from 'lodash'

export type GalleryProps = {
  data: Typo3GalleryData
}

const Gallery: React.FC<GalleryProps> = ({ data }) => {
  return (
    <div className={`content-container gallery gallery-${data.position.vertical}`}>
      {_.map(data.rows, (row, i) => (
        <Row key={`row-${i}`}>
          {_.map(row.columns, (image, i) => {
            return (
              <Col key={`image-${i}`}>
                <ContentImage {...image} />
              </Col>
            )
          })}
        </Row>
      ))}
    </div>
  )
}

export default Gallery
