import React from 'react'
import { Typo3ImageData } from '../../data/remote/PageContent'

const getPaddingBottom = (dimensions: { width: number; height: number }, type: string): string => {
  if (type === 'video' && !dimensions.width && !dimensions.height) {
    return (9 / 16) * 100 + '%'
  }
  return (dimensions.height / dimensions.width) * 100 + '%'
}

const ContentImage: React.FC<Typo3ImageData> = ({ publicUrl, properties }) => {
  const { alternative, title, description, dimensions, mimeType, type, previewImage } = properties

  return (
    <figure>
      <div className="image-wrap" style={{ paddingBottom: getPaddingBottom(dimensions, type) }}>
        {mimeType === 'video/youtube' || mimeType === 'video/vimeo' ? (
          <iframe src={publicUrl} className="border-0" />
        ) : type === 'video' ? (
          <video src={publicUrl} className="img-fluid" controls preload="preload" poster={previewImage} />
        ) : (
          <img src={publicUrl} className="img-fluid" alt={alternative} title={title} />
        )}
      </div>
      {description && <figcaption>{description}</figcaption>}
    </figure>
  )
}

export default ContentImage
