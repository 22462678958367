import { t } from '@lingui/macro'
import React, { useState, useEffect } from 'react'

import DataColumn, { useColumnData } from '../../../components/ListView/DataColumn'
import { RegulationCategoryDataMinimal, useRegulationCategories } from '../../../data/remote/RegulationCategory'
import { useRegulationFiles } from '../../../data/remote/RegulationFile'
import { RegulationFolderDataMinimal, useRegulationFolders } from '../../../data/remote/RegulationFolder'
import { SupplierDataMinimal } from '../../../data/remote/Supplier'
import { filterApiHookData } from '../../../scripts/api'
import isSubsetOf from '../../../scripts/isSubsetOf'
import SelectFilesRegulationFilesTable from './Columns/SelectFilesRegulationFilesTable'

export type SelectFilesInputProps = {
  value?: number[]
  onChange?: (nextValue: number[]) => void
  supplier?: SupplierDataMinimal
}

const SelectFilesInput: React.FC<SelectFilesInputProps> = ({ value, onChange, supplier }) => {
  const [{ categoryId, folderId }, setState] = useState<{ categoryId?: number; folderId?: number }>({})

  const categoryData = useColumnData<RegulationCategoryDataMinimal>(
    () => ({
      disableSearch: true,
      useItems() {
        return filterApiHookData(useRegulationCategories(), (data) => data.filter((x) => !!x.files.length))
      },
      isActiveItem(item) {
        return item.id === categoryId
      },
      renderItem(category) {
        return {
          id: category.id,
          title: category.localizedTitle,
          active: category.id === categoryId,
          onClick() {
            setState({ categoryId: category.id })
          },
          checked: isSubsetOf(category.files, value),
          setChecked(isChecked) {
            onChange?.((value ?? []).filter((x) => !category.files.includes(x)).concat(isChecked ? category.files : []))
          }
        }
      }
    }),
    [categoryId, onChange, value]
  )
  const hasCategory = !!categoryData.current

  const folderData = useColumnData<RegulationFolderDataMinimal>(
    () => ({
      disableSearch: true,
      useItems() {
        return filterApiHookData(useRegulationFolders({ categoryId: categoryData.current?.id }, hasCategory), (data) =>
          data.filter((x) => !!x.files.length)
        )
      },
      isActiveItem(item) {
        return item.id === folderId
      },
      renderItem(folder) {
        return {
          id: folder.id,
          title: folder.localizedTitle,
          active: folder.id === folderId,
          onClick() {
            setState({ categoryId, folderId: folder.id })
          },
          checked: isSubsetOf(folder.files, value),
          setChecked(isChecked) {
            onChange?.((value ?? []).filter((x) => !folder.files.includes(x)).concat(isChecked ? folder.files : []))
          }
        }
      }
    }),
    [categoryData, hasCategory, folderId, value, categoryId, onChange]
  )
  const hasFolder = !!folderData.current

  useEffect(() => {
    if (!hasCategory && categoryData.items?.length && categoryId !== categoryData.items[0].id) {
      setState({ categoryId: categoryData.items[0].id })
    }
    if (!hasFolder && folderData.items?.length && folderId !== folderData.items[0].id) {
      setState({ categoryId, folderId: folderData.items[0].id })
    }
  }, [categoryData.items, categoryId, folderData.items, folderData.items?.length, folderId, hasCategory, hasFolder])

  const { data: files, loading: filesLoading } = useRegulationFiles({ folderId: folderData.current?.id }, hasFolder)

  return (
    <div className="d-flex flex-row" style={{ height: '550px' }}>
      <DataColumn title={t`Kategorie`} {...categoryData} />
      <DataColumn title={t`Ordner`} {...folderData} />
      <SelectFilesRegulationFilesTable
        data={files}
        loading={filesLoading}
        value={value}
        onChange={onChange}
        supplier={supplier}
      />
    </div>
  )
}

export default SelectFilesInput
