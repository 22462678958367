import React from 'react'
import AccountMenu from './AccountMenu'
import UploadIndicator from './UploadIndicator'

const HeaderToolbar: React.FC = () => {
  return (
    <div className="col d-flex flex-row align-items-center justify-content-end">
      <UploadIndicator />
      <AccountMenu />
    </div>
  )
}

export default HeaderToolbar
