import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { MainContainer } from '../../../components/Layout/MainContainer'
import { MainMenu } from '../../../components/Layout/MainMenu'
import { useCurrentLocale } from '../../../data/remote/Language'
import { useRoutes } from '../../../data/remote/RouteData'
import { useSettings } from '../../../data/remote/Settings'
import { useAuthentication } from '../../../data/remote/User'
import { Error404 } from '../../LoggedIn/Error404'
import { Loading } from '../../LoggedIn/Loading'
import routeComponents from '../../routeComponents'

const MainNavigator: React.FC = () => {
  const { loading: settingsLoading } = useSettings()
  const { loading: routesLoading, routes } = useRoutes()
  const { loggingIn: userLoading } = useAuthentication()

  const menuVisible = !!routes.main.length
  const contentVisible = !(settingsLoading || routesLoading || userLoading) // TODO: || pageLoading && nur wenn aktuell keine Seite des Typs "Submenu" oder "PageContent" aufgerufen wurde, damit z.B. bei den Info-Seiten das Untermenü dauerhaft sichtbar ist, auch wenn eine Seite lädt

  const locale = useCurrentLocale()

  return (
    <MainContainer>
      {menuVisible && <MainMenu />}
      {contentVisible ? (
        <Switch>
          {routes.any.map((route, index) => {
            const Component = routeComponents[route.component]
            if (!Component) {
              return null
            }
            return (
              <Route key={index} path={route.path}>
                <Component navigatorType="main" />
              </Route>
            )
          })}
          {routes.main[0] &&
            [`/${locale}`, '/'].map((path) => (
              <Route key={path} path={path} exact>
                <Redirect to={routes.main[0]?.href} />
              </Route>
            ))}
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      ) : (
        <Loading />
      )}
    </MainContainer>
  )
}

export default MainNavigator
