import { truncate } from 'lodash'
import { ContainerBox } from '../../../components/Layout/ContainerBox'
import { useSupplierComplaints } from '../../../data/remote/Complaint'
import { Loading } from '../Loading'
import { t } from '@lingui/macro'

import ArrowRight from 'jsx:./../../../assets/icons/chevron-right.svg'
import { useLocalizedPath } from '../../../data/remote/Language'
import { Icon } from '../../../components/Icon'
import { Button } from 'react-bootstrap'

type SupplierComplaintsProps = {
  supplierId: number
}

const SupplierComplaints: React.FC<SupplierComplaintsProps> = ({ supplierId }) => {
  const { data: complaints, loading } = useSupplierComplaints({ supplierId })
  const localizedPath = useLocalizedPath()
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        complaints &&
        complaints.length > 0 && (
          <ContainerBox>
            <div className="data-table-wrap">
              <h2>{t`Reklamationen`}</h2>
              <table className="data-table mt-3">
                <thead>
                  <tr>
                    <th className="col-1">
                      <div>Zeile</div>
                    </th>
                    <th className="col-1">
                      <div>Datum</div>
                    </th>
                    <th className="col-2">
                      <div>CAQ</div>
                    </th>
                    <th className="col">
                      <div>Kurzbeschreibung</div>
                    </th>
                    <th className="col-1">
                      <div></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {complaints.map((complaint, index) => (
                    <tr key={complaint.id}>
                      <td className="col-1">{index + 1}</td>
                      <td className="col-1">{new Date(complaint.crdate).toLocaleDateString()}</td>
                      <td className="col-2">{complaint.caq}</td>
                      <td className="col">{truncate(complaint.description, { length: 100 })}</td>
                      <td className="col-1">
                        <Button
                          size="sm"
                          className="ml-auto"
                          onClick={() => {
                            window.location.href =
                              localizedPath(`/reklamationen`) +
                              `/${complaint.orderIdentifier}/${complaint.product}/${complaint.id}`
                          }}
                        >
                          <Icon svg={ArrowRight} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </ContainerBox>
        )
      )}
    </div>
  )
}

export default SupplierComplaints
