import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { Alert, Col, Form, FormControl, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { passwordRepeat, passwordSecurity } from '../../../components/Form/validators'
import LocalizedLink from '../../../components/LocalizedLink'
import { useResetPassword } from '../../../data/remote/User'
import LoginButton from '../LoginButton'

export type ResetPasswordProps = {
  userId: number
  confirmationHash: string
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ userId, confirmationHash }) => {
  const { run: resetPassword, running, error, result } = useResetPassword(userId)
  const { register, handleSubmit, formState, getValues } = useForm<{ password: string; passwordRepeat: string }>()
  const onSubmit = useMemo(
    () => handleSubmit((input) => resetPassword({ ...input, hash: confirmationHash })),
    [confirmationHash, handleSubmit, resetPassword]
  )

  let content = (
    <Form onSubmit={onSubmit} className="mt-3" noValidate>
      <Form.Group controlId="password">
        <Form.Label srOnly={true}>{t`Passwort`}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t`Passwort`}
          size="lg"
          className="form-control-login"
          {...register('password', {
            validate: passwordSecurity
          })}
          isInvalid={!!formState.errors.password}
        />
        {formState.errors.password && (
          <FormControl.Feedback type="invalid">{formState.errors.password.message}</FormControl.Feedback>
        )}
      </Form.Group>
      <Form.Group controlId="passwordRepeat">
        <Form.Label srOnly={true}>{t`Passwort wiederholen`}</Form.Label>
        <Form.Control
          type="password"
          placeholder={t`Passwort wiederholen`}
          size="lg"
          className="form-control-login"
          {...register('passwordRepeat', {
            validate: passwordRepeat(() => getValues().password)
          })}
          isInvalid={!!formState.errors.passwordRepeat}
        />
        {formState.errors.passwordRepeat && (
          <FormControl.Feedback type="invalid">{formState.errors.passwordRepeat.message}</FormControl.Feedback>
        )}
      </Form.Group>
      {error && (
        <Alert variant="danger">
          <Alert.Heading>{t`Fehler beim Zurücksetzen des Passworts`}</Alert.Heading>
          {error?.response?.data?.message}
        </Alert>
      )}
      <Row>
        <Col md={6}>
          <LoginButton className="mt-3" disabled={running}>
            {t`Passwort zurücksetzen`}
          </LoginButton>
        </Col>
      </Row>
    </Form>
  )

  if (!error && result?.data.code === 200) {
    content = (
      <>
        <Alert variant="success">
          <Alert.Heading>{t`Anfrage erfolgreich`}</Alert.Heading>
          <strong>{t`Ihr Passwort wurde erfolgreich geändert.`}</strong>
          <br />
          {t`Sie können sich nun mit Ihrem neuen Passwort anmelden.`}
        </Alert>
        <LocalizedLink componentIsLink component={LoginButton} to={'/'}>
          {t`Zur Anmeldung`}
        </LocalizedLink>
      </>
    )
  }

  return (
    <>
      <h1>{t`Passwort vergessen`}</h1>
      {content}
    </>
  )
}

export default ResetPassword
