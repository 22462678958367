import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { FileData } from '../../types/FileDataTypes'

export type EmailData = {
  id: number
  crdate: Date
  sender: string
  subject: string
  hasAttachment: boolean
  file: FileData
}

export type EmailTarget = {
  type: 'complaints'
  id: number
}

export type UseEmailsParams = {
  id?: number
  for?: EmailTarget
}

export const useEmail = createApiItemHook<EmailData>('/emails/')
export const useCanReadEmails = (): boolean => true

export const useEmails = createApiDataHook<EmailData[], UseEmailsParams>(({ for: forObject, ...params }) => [
  forObject ? `/${forObject.type}/${forObject.id}/emails` : '/emails',
  params
])
