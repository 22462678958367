import axios from 'axios'
import { SWRConfiguration } from 'swr'
import { version as clientVersion } from './../../package.json'

// Properties (default/live)
export const apiParamsPrefix = 'tx_ytsupplierportal_api'
export const __DEV__ = process.env.NODE_ENV === 'development'

export const apiBaseUrl = 'https://' + (process.env.API_HOST ?? 'api.supplier.forks.com')

// Axios
axios.defaults.baseURL = apiBaseUrl
axios.defaults.timeout = 20000
axios.defaults.headers.common = {
  'X-Client-Version': clientVersion,
  'Content-Type': 'application/x-www-form-urlencoded'
}
axios.defaults.withCredentials = true

// SWR
export const swrBaseConfig: SWRConfiguration = {
  // revalidateOnFocus: false,
  revalidateOnReconnect: true,
  shouldRetryOnError: false,
  loadingTimeout: 30000,
  focusThrottleInterval: 10 * 1000
}
// TODO: onLoadingSlow irgendwo Lade-Indikator einblenden und on Success entfernen?

// Language
export const availableLocales = ['de', 'en']
export const defaultLocale = 'de'
