import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import DataTable, { DataTableItem, dynamicRows } from '../../../../components/DataTable/DataTable'
import TableStatusIcon from '../../../../components/DataTable/TableStatusIcon'
import DotsMenu from '../../../../components/DotsMenu'
import { DropdownItem, DropdownSeperator } from '../../../../components/Overlays/Dropdown'
import {
  ConfirmationFileData,
  getConfirmationFileStatusText,
  useUpdateConfirmationFileApproval
} from '../../../../data/remote/ConfirmationFile'

export type ConfirmationFilesTableProps = {
  data?: ConfirmationFileData[]
  loading?: boolean
}

const ItemMoreMenu: React.FC<{ file: ConfirmationFileData }> = ({ file }) => {
  const updateConfirmationFileApproval = useUpdateConfirmationFileApproval(file.id)

  return (
    <DotsMenu>
      {updateConfirmationFileApproval.actionAllowed && (
        <>
          <DropdownItem
            onClick={updateConfirmationFileApproval.approve}
            disabled={file.status.approval === 'approved'}
          >{t`Datei bestätigen`}</DropdownItem>
          <DropdownItem
            onClick={updateConfirmationFileApproval.deny}
            disabled={file.status.approval === 'denied'}
          >{t`Datei ablehnen`}</DropdownItem>

          <DropdownSeperator />
        </>
      )}
      <DropdownItem href={file.file.publicUrl}>{t`Datei öffnen`}</DropdownItem>
    </DotsMenu>
  )
}

const ConfirmationFilesTable: React.FC<ConfirmationFilesTableProps> = ({ data, loading }) => {
  const columns = useMemo(
    () => ({
      name: t`Dateiname`,
      date: t`Änderungsdatum`,
      status: t`Status`,
      menu: ''
    }),
    []
  )

  const items = useMemo<DataTableItem<ConfirmationFileData>[]>(
    () => dynamicRows(data, loading, t`Für diese Datei wurden noch keine Bestätigungen hochgeladen.`),
    [data, loading]
  )

  const columnClasses = useMemo(
    () => ({
      menu: 'text-right'
    }),
    []
  )

  return (
    <DataTable items={items} columns={columns} columnClasses={columnClasses}>
      {useCallback(
        (item: ConfirmationFileData) => ({
          name: (
            <a href={item.file.publicUrl} target="_blank" className="text-break" rel="noreferrer">
              {item.file.properties.title}
            </a>
          ),
          status: (
            <TableStatusIcon status={item.status.approval} tooltip={getConfirmationFileStatusText(item.status)} />
          ),
          date: dayjs(item.tstamp).format('L'),
          menu: <ItemMoreMenu file={item} />
        }),
        []
      )}
    </DataTable>
  )
}

export default ConfirmationFilesTable
