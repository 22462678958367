import { t } from '@lingui/macro'
import React from 'react'
import { ContainerBox } from '../../../components/Layout/ContainerBox'
import { FlexContainer } from '../../../components/ListView/FlexContainer'
import { EditProfileForm } from '../../../components/Profile/EditProfileForm'

export const EditProfile: React.FC = () => {
  return (
    <FlexContainer padding>
      <h1 className="h2">{t`Ihre Stammdaten`}</h1>
      <ContainerBox>
        <EditProfileForm />
      </ContainerBox>
    </FlexContainer>
  )
}
