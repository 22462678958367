import { t } from '@lingui/macro'
import { toNumber } from 'lodash'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { OrderDataMinimal } from '../../../../data/remote/Order'
import { ProductDataMinimal, useProducts } from '../../../../data/remote/Product'
import { SetParamsAction } from '../../../../navigation/createRoute'

export const useProductsData = ({
  setParams,
  generatePath,
  supplierId,
  productId,
  orderData
}: {
  setParams: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => void
  generatePath: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => string
  supplierId: string | number | undefined
  productId: string | undefined
  orderData: ColumnData<OrderDataMinimal>
}): ColumnData<ProductDataMinimal> =>
  useColumnData<ProductDataMinimal>(
    () => ({
      useItems(search, filter) {
        return useProducts(
          orderData.current
            ? {
                search,
                filter,
                orderId: orderData.current?.id
              }
            : undefined,
          !!orderData.current
        )
      },
      isActiveItem(item) {
        return item.id === toNumber(productId)
      },
      shouldHaveActiveItem: !!orderData.current && !!productId,
      onActiveItemMiss() {
        setParams({
          supplierId,
          orderId: orderData.current?.identifier,
          productId: undefined,
          certificateCode: undefined
        })
      },
      filterOptions: {
        ['']: t`Alle Artikel`,
        pending: t`Mit ungeprüften Zeugnissen`,
        denied: t`Mit abgelehnten Zeugnissen`,
        open: t`Mit offenem Status`
      },
      renderItem: (product) => ({
        id: product.id,
        title: product.sku,
        tooltip: product.localizedTitle,
        url: generatePath({ supplierId, orderId: orderData.current?.identifier, productId: product.id }, true),
        gray: product.status?.approval === 'closed',
        denied: product.status?.approval === 'denied',
        pending: product.status?.pending
      })
    }),
    [generatePath, orderData, productId, setParams, supplierId]
  )
