import { useAssignedRegulationFileIds, useDeleteSupplier, useSupplier } from '../../../data/remote/Supplier'
import { Loading } from '../Loading'
import { ContainerBox } from '../../../components/Layout/ContainerBox'
import { EditProfileForm } from '../../../components/Profile/EditProfileForm'
import Toolbar from '../../../components/Layout/Toolbar'
import { FlexContainer } from '../../../components/ListView/FlexContainer'
import { useConfirm } from '../../../navigation/Notifications'
import { useCallback, useMemo } from 'react'
import { DropdownItem } from '../../../components/Overlays/Dropdown'
import DotsMenu from '../../../components/DotsMenu'
import { t } from '@lingui/macro'
import ToggleButtons from '../../../components/ToggleButtons'
import { useProductGroups } from '../../../data/remote/ProductGroup'
import SelectFilesInput from '../Files/SelectFilesInput'
import isSubsetOf from '../../../scripts/isSubsetOf'
import SupplierSpecialFiles from './SupplierSpecialFiles'
import SupplierComplaints from './SupplierComplaints'

const SupplierSingle: React.FC<{
  supplierId: number
  baseUrl: string
}> = function SupplierSingle({ supplierId, baseUrl }) {
  const { data: supplier, loading: supplierLoading } = useSupplier(supplierId)
  const deleteSupplier = useDeleteSupplier()

  const confirm = useConfirm()
  const deleteUser = useCallback(() => {
    if (supplier) {
      confirm({
        title: t`Lieferanten löschen?`,
        text: <>{t`Soll der Lieferant <b>„${supplier.company}“</b> wirklich gelöscht werden?`}</>,
        options: [
          { label: t`Abbrechen` },
          {
            type: 'danger',
            label: t`Löschen`,
            onClick: () => {
              deleteSupplier.run(supplier.id, baseUrl)
            }
          }
        ]
      })
    }
  }, [baseUrl, confirm, deleteSupplier, supplier])

  const productGroups = useProductGroups()
  const productGroupOptions = useMemo(
    () => productGroups.data?.map(({ id, localizedTitle }) => ({ id: id.toString(), label: localizedTitle })) ?? [],
    [productGroups.data]
  )

  const { regulationFileIds, run: setRegulationFileIds } = useAssignedRegulationFileIds(supplierId)
  const productGroupSelection = useMemo(() => {
    return Object.fromEntries(
      productGroups.data?.map(({ id, regulationFiles }) => [id, isSubsetOf(regulationFiles, regulationFileIds)]) ?? []
    )
  }, [productGroups.data, regulationFileIds])
  const setProductGroupChecked = useCallback(
    (id: string, selected: boolean) => {
      const groupFileIds = productGroups.data?.find((x) => x.id.toString() === id)?.regulationFiles ?? []
      const nextFileIds = regulationFileIds
        .filter((x) => !groupFileIds?.includes(x))
        .concat(selected ? groupFileIds : [])
      setRegulationFileIds(nextFileIds)
    },
    [productGroups.data, regulationFileIds, setRegulationFileIds]
  )

  if (!supplierLoading && !supplier) {
    return <></>
  }

  return supplierLoading ? (
    <Loading />
  ) : (
    <>
      <Toolbar
        right={
          <div className="pr-1">
            <DotsMenu>
              <DropdownItem textColor="danger" onClick={deleteUser}>{t`Löschen`}</DropdownItem>
            </DotsMenu>
          </div>
        }
      />
      <FlexContainer padding>
        <h1 className="h2">{supplier!.company}</h1>
        <ContainerBox>
          <EditProfileForm profileId={supplier!.id} />
        </ContainerBox>

        <SupplierComplaints supplierId={supplier!.id} />

        <ContainerBox>
          <h2>{t`Produktgruppen`}</h2>
          <ToggleButtons
            options={productGroupOptions}
            value={productGroupSelection}
            onChange={setProductGroupChecked}
          />
        </ContainerBox>
        <ContainerBox>
          <h2>{t`Dateizuordnung Lieferant`}</h2>
          <div className="container-box-content-p0 border-top">
            <SelectFilesInput value={regulationFileIds} onChange={setRegulationFileIds} supplier={supplier} />
          </div>
        </ContainerBox>
        <ContainerBox>
          <h2>{t`Dateien für ${supplier?.company}`}</h2>
          <div className="container-box-content-p0 border-top">
            {supplier?.id && <SupplierSpecialFiles supplierId={supplier.id} />}
          </div>
        </ContainerBox>
      </FlexContainer>
    </>
  )
}

export default SupplierSingle
