{
  "name": "vetter-suppliers",
  "version": "1.1.47",
  "private": true,
  "description": "Vetter Suppliers",
  "homepage": "https://supplier.forks.com/",
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "scripts": {
    "start:ddev": "cross-env API_HOST=vetter-supplier.ddev.site parcel src/index.html --no-cache --host client.vetter-supplier.ddev.site --https --dist-dir ./build_ddev --public-url /",
    "start:test": "cross-env API_HOST=test-api.supplier.forks.com parcel src/index.html --no-cache --host local.supplier.forks.com --https --dist-dir ./build_test --public-url /",
    "start:live": "cross-env parcel src/index.html --no-cache --host local.supplier.forks.com --https --dist-dir ./build --public-url /",
    "build:ddev": "rm -rf build_ddev && lingui extract && lingui compile && cross-env API_HOST=vetter-supplier.ddev.site parcel build src/index.html --no-cache --dist-dir ./build_ddev --public-url /",
    "build:test": "rm -rf build_test && lingui extract && lingui compile && cross-env API_HOST=test-api.supplier.forks.com parcel build src/index.html --no-cache --dist-dir ./build_test --public-url /",
    "build:live": "rm -rf build && lingui extract && lingui compile && parcel build src/index.html --no-cache --dist-dir ./build --public-url /",
    "lint": "tsc --noEmit --project tsconfig.json && eslint .",
    "lingui:extract": "lingui extract",
    "lingui:compile": "lingui compile"
  },
  "dependencies": {
    "@babel/runtime": "^7.0.0",
    "@lingui/core": "^3.13.0",
    "@lingui/react": "^3.17.1",
    "@parcel/transformer-svg-react": "^2.8.3",
    "@popperjs/core": "^2.11.6",
    "@reduxjs/toolkit": "^1.9.3",
    "@rehooks/document-title": "^1.0.2",
    "@sentry/browser": "^7.81.1",
    "@sentry/cli": "^2.21.5",
    "@sentry/integrations": "^7.81.1",
    "@sentry/react": "^7.81.0",
    "@types/reach__router": "^1.3.11",
    "axios": "^0.21.1",
    "babel-plugin-macros": "3",
    "babel-runtime": "^6.26.0",
    "bootstrap": "https://github.com/slavede/bootstrap.git#v4-dev",
    "browserslist": "^4.21.5",
    "core-js": "^3.28.0",
    "cypress": "^12.12.0",
    "dayjs": "^1.11.7",
    "framer-motion": "^9.0.6",
    "html-react-parser": "^3.0.9",
    "jquery": "^3.6.3",
    "lodash": "^4.17.21",
    "path-type": "^5.0.0",
    "popper.js": "^1.16.1",
    "qs": "^6.11.0",
    "react": "^18.2.0",
    "react-bootstrap": "^1.6.1",
    "react-bootstrap-typeahead": "^5.1.4",
    "react-contexify": "^6.0.0",
    "react-custom-scrollbars-2": "^4.5.0",
    "react-dom": "^18.2.0",
    "react-hook-form": "^7.43.1",
    "react-laag": "^2.0.5",
    "react-popper": "^2.3.0",
    "react-redux": "^8.0.5",
    "react-resize-detector": "^8.0.4",
    "react-router-dom": "^5.2.0",
    "react-toastify": "^9.1.1",
    "react-transition-group": "^4.4.5",
    "redux": "^4.2.1",
    "redux-thunk": "^2.4.2",
    "regenerator-runtime": "^0.13.11",
    "resize-observer-polyfill": "^1.5.1",
    "styled-components": "^5.3.6",
    "swr": "^1.0.0"
  },
  "devDependencies": {
    "@babel/core": "^7.21.0",
    "@babel/preset-env": "^7.15.6",
    "@babel/preset-react": "^7.18.6",
    "@babel/preset-typescript": "^7.21.0",
    "@lingui/babel-preset-react": "^2.9.2",
    "@lingui/cli": "^3.17.1",
    "@lingui/macro": "^3.17.1",
    "@parcel/transformer-sass": "^2.8.3",
    "@parcel/transformer-typescript-tsc": "^2.8.3",
    "@types/lodash": "^4.14.191",
    "@types/node": "^18.14.0",
    "@types/qs": "^6.9.7",
    "@types/react": "^18.0.28",
    "@types/react-bootstrap-typeahead": "^5.1.8",
    "@types/react-dom": "^18.0.11",
    "@types/react-redux": "^7.1.25",
    "@types/react-router-dom": "^5.3.3",
    "@typescript-eslint/eslint-plugin": "^5.53.0",
    "@typescript-eslint/parser": "^5.53.0",
    "assert": "^2.0.0",
    "autoprefixer": "^10.4.13",
    "babel-plugin-lodash": "^3.3.4",
    "babel-plugin-react-html-attrs": "^3.0.5",
    "console-browserify": "^1.2.0",
    "cross-env": "^7.0.3",
    "crypto-browserify": "^3.12.0",
    "escape-string-regexp": "^5.0.0",
    "eslint": "^8.34.0",
    "eslint-config-lingui": "^0.5.0",
    "eslint-config-prettier": "^8.6.0",
    "eslint-plugin-deprecation": "^1.3.3",
    "eslint-plugin-import": "^2.27.5",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-react-hooks": "^4.6.0",
    "events": "^3.3.0",
    "os-browserify": "^0.3.0",
    "parcel": "^2.8.3",
    "parcel-reporter-static-files-copy": "^1.5.0",
    "path-browserify": "^1.0.1",
    "postcss": "^8.4.21",
    "prettier": "^2.8.4",
    "process": "^0.11.10",
    "querystring-es3": "^0.2.1",
    "react-is": "^18.2.0",
    "sass": "^1.58.3",
    "stream-browserify": "^3.0.0",
    "ts-node": "^10.9.1",
    "typescript": "^4.9.5",
    "url": "^0.11.0",
    "util": "^0.12.5",
    "vm-browserify": "^1.1.2",
    "yarn": "^1.22.19"
  },
  "staticFiles": {
    "staticPath": "public",
    "watcherGlob": "**"
  },
  "overrides": {
    "babel-plugin-lodash": {
      "@babel/types": "~7.20.0"
    }
  }
}
