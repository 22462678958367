import { t } from '@lingui/macro'
import React from 'react'
import CloudUpload from 'jsx:../../../assets/icons/cloud-upload.svg'
import { useSelector } from '../../../data/local/hooks'
import { Icon } from '../../Icon'
import { Tooltip } from '../../Overlays/Tooltip'

const UploadIndicator: React.FC = () => {
  const jobs = useSelector((state) => state.uploads.jobs)

  const pct = jobs.length
    ? Math.min(
        100,
        Math.max(0, Math.round((jobs.map((x) => x.progress).reduce((a, b) => a + b, 0) / jobs.length) * 100))
      )
    : 0
  const count = jobs.length

  if (!jobs.length) {
    return <React.Fragment />
  }

  return (
    <Tooltip text={t`${pct}% von ${count} Dateien hochgeladen`} placement="bottom">
      <div className="upload-indicator">
        <Icon svg={CloudUpload} />
        <div className="upload-indicator-progress-wrap">
          <div className="upload-indicator-progress" style={{ marginLeft: -100 + pct + '%' }}></div>
        </div>
      </div>
    </Tooltip>
  )
}

export default UploadIndicator
