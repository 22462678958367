import React, { ReactNode, useCallback } from 'react'
import { Popover } from 'react-bootstrap'
import OverlayTrigger, { OverlayTriggerProps } from './OverlayTrigger'
import { makeResizableOverlay } from './shared'

export type TooltipProps = Omit<OverlayTriggerProps, 'overlay'> & {
  text?:
    | string
    | number
    | React.ReactElement
    | (() => string | number | React.ReactElement)
    | undefined
    | null
    | ReactNode
  content?: string | number | React.ReactElement
}

const UpdatingPopover = makeResizableOverlay(Popover)

export const Tooltip: React.FC<TooltipProps> = ({ children, text, disabled, ...props }) => {
  const renderTooltip = useCallback(
    (props: TooltipProps) => (
      <UpdatingPopover id="" content="true" {...props}>
        {typeof text === 'function' ? text() : text}
      </UpdatingPopover>
    ),
    [text]
  )

  return (
    <OverlayTrigger {...props} disabled={disabled || !text} overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  )
}
