import { t } from '@lingui/macro'
import React from 'react'
import LogoSvg from 'jsx:./../../assets/logo.svg'
import LocalizedLink from '../LocalizedLink'

export const Logo: React.FC = () => {
  return (
    <LocalizedLink to="/" className="header-logo">
      <LogoSvg />
      <span>{t`Lieferantenportal`}</span>
    </LocalizedLink>
  )
}
