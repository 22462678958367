import React from 'react'
import {
  Typo3ContentLayouts,
  Typo3ImageContent,
  Typo3TextContent,
  Typo3TextPicContent
} from '../../data/remote/PageContent'
import parse from 'html-react-parser'
import Gallery from './Gallery'

export type TextPicModuleProps = {
  data: Typo3TextPicContent | Typo3ImageContent | Typo3TextContent
}

const TextPicModule: React.FC<TextPicModuleProps> = ({
  data: {
    content,
    appearance: { layout }
  }
}) => {
  return (
    <>
      {content.header && layout !== Typo3ContentLayouts.Hint && <h2>{content.header}</h2>}
      {'gallery' in content && content.gallery.position.vertical !== 'below' && <Gallery data={content.gallery} />}
      {'bodytext' in content && content.bodytext && (
        <div className="content-container">
          {content.header && layout === Typo3ContentLayouts.Hint && <h3>{content.header}</h3>}
          {parse(content.bodytext)}
        </div>
      )}
      {'gallery' in content && content.gallery.position.vertical === 'below' && <Gallery data={content.gallery} />}
    </>
  )
}

export default TextPicModule
