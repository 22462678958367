import { Entrypoint, entrypointIndexes, entrypointNames, SearchColumnProps, SearchSelection } from '.'
import { UseColumnDataArgs } from '../../../../components/ListView/DataColumn'
import { toNumber } from '../../../../scripts/utils'

const getArrow = (
  entrypoint: Entrypoint | undefined,
  selection: SearchSelection,
  columnName: Entrypoint
): 'left' | 'right' | undefined => {
  if (!entrypoint) {
    return undefined
  } else if (entrypointIndexes[columnName] >= entrypointIndexes[entrypoint] && !!selection[columnName]) {
    return 'right'
  } else if (
    entrypointIndexes[columnName] < entrypointNames.length - 1 &&
    !!selection[entrypointNames[entrypointIndexes[columnName] + 1]]
  ) {
    return 'left'
  }
  return undefined
}

const defaultColumnData = <T extends { id: number }, FilterType extends string>(
  columnName: Entrypoint,
  { entrypoint, setEntrypoint, selection, setSelection }: Omit<SearchColumnProps, 'setColumnSelection'>
): Pick<
  UseColumnDataArgs<T, FilterType>,
  'arrow' | 'onFilterChange' | 'onActiveItemMiss' | 'shouldHaveActiveItem' | 'isActiveItem' | 'activeTitle'
> => ({
  isActiveItem(item) {
    return toNumber(item.id) === toNumber(selection[columnName])
  },
  shouldHaveActiveItem: !!selection[columnName],
  onActiveItemMiss() {
    setSelection((selection) => ({ ...selection, [columnName]: undefined }))
  },
  onFilterChange({ search, filter }) {
    if (!entrypoint && (search || filter)) {
      setEntrypoint(columnName)
    } else if (entrypoint === columnName && !search && !filter) {
      setEntrypoint(undefined)
    }
  },
  arrow: getArrow(entrypoint, selection, columnName),
  activeTitle: entrypoint === columnName
})

export default defaultColumnData
