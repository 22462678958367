import { t } from '@lingui/macro'
import { toNumber } from 'lodash'
import { useEffect, useState } from 'react'
import DataColumn, { useColumnData } from '../../../components/ListView/DataColumn'
import { useModalActions } from '../../../components/Overlays/Modal'
import { SupplierDataMinimal, useSuppliers } from '../../../data/remote/Supplier'
import { createRoute } from '../../../navigation/createRoute'
import { AddSteelCertificateModal } from './AddSteelCertificateModal'
import { AddSteelHeatingModal } from './AddSteelHeatingModal'
import { useSteelCertificatesData } from './Columns/useSteelCertificatesData'
import { useSteelHeatingData } from './Columns/useSteelHeatingData'

import { Loading } from '../Loading'

export const SteelworkProtocols = createRoute(
  'SteelworkProtocols',
  function Steelworks({ params: { supplierId, steelHeatingId, steelCertificateId }, generatePath, setParams }) {
    const { ref: addSteelHeatingModal, open: openAddSteelHeatingModal } = useModalActions()
    const { ref: addSteelCertificateModal, open: openAddSteelCertificateModal } = useModalActions()
    const addSteelHeating = true ? openAddSteelHeatingModal : undefined
    const addSteelCertificate = true ? openAddSteelCertificateModal : undefined
    const [isLoading, setIsLoading] = useState(true)

    const supplierData = useColumnData<SupplierDataMinimal>(
      () => ({
        useItems(search) {
          return useSuppliers({ search, filter: 'steel_all' })
        },
        isActiveItem(supplier) {
          return supplierId !== 'new' && supplier.id === toNumber(supplierId)
        },
        shouldHaveActiveItem: !!supplierId,
        onActiveItemMiss() {
          setParams({
            supplierId: undefined,
            steelHeatingId: undefined,
            steelCertificateId: undefined
          })
        },
        renderItem(supplier) {
          return {
            id: supplier.id,
            title: supplier.company,
            url: generatePath({ supplierId: supplier.id, steelHeatingId: undefined, steelCertificateId: undefined }),
            steelcertificateStatus: supplier.status.steelcertificates
          }
        }
      }),
      [generatePath, setParams, supplierId]
    )

    const steelHeatingData = useSteelHeatingData({
      setParams,
      generatePath,
      supplierId,
      steelHeatingId,
      canManageSteelCertificates: false
    })

    const steelCertificatesData = useSteelCertificatesData({
      setParams,
      generatePath,
      supplierId,
      steelHeatingId,
      steelCertificateId
    })

    const steelCertificate = steelCertificatesData?.items?.find((item) => item.id.toString() === steelCertificateId)
    useEffect(() => {
      if (steelCertificate) {
        setIsLoading(true)
      }
    }, [steelCertificate])

    return (
      <>
        <DataColumn
          title={t`Stahlwerke`}
          {...supplierData}
          type="steelworks"
          arrow={supplierId ? 'right' : undefined}
        />
        {supplierData.current && (
          <>
            <DataColumn
              title={t`Schmelze`}
              {...steelHeatingData}
              newLabel={t`Schmelze anlegen`}
              type="steelworks"
              status={steelHeatingData.current?.status}
              arrow={steelHeatingId ? 'right' : undefined}
              onNew={addSteelHeating}
            />

            <AddSteelHeatingModal
              ref={addSteelHeatingModal}
              supplierId={supplierId}
              steelHeatingData={steelHeatingData.items}
            />
          </>
        )}
        {steelHeatingData.current && supplierId && (
          <>
            <DataColumn
              title={t`Abmessung`}
              {...steelCertificatesData}
              arrow={steelCertificateId ? 'right' : undefined}
              newLabel={t`Abmessung hinzufügen`}
              type="steelworks"
              onNew={addSteelCertificate}
            />
            {supplierData.current && (
              <AddSteelCertificateModal
                ref={addSteelCertificateModal}
                supplier={supplierData.current}
                steelHeatingId={steelHeatingData.current.id}
              />
            )}
          </>
        )}
        {!!steelCertificate && steelCertificatesData.current && steelCertificatesData.current?.protocolUrl && (
          <>
            {isLoading && <Loading />}
            <iframe
              style={{ display: isLoading ? 'none' : 'block' }}
              src={steelCertificatesData.current?.protocolUrl + '#navpanes=0&scrollbar=0'}
              width="100%"
              onLoad={() => setIsLoading(false)}
              height="100%"
            />
          </>
        )}
      </>
    )
  }
)
