import { t } from '@lingui/macro'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR } from '../local/hooks/swrHooks'

import { ApprovalStatus } from './Status'
import { FileData } from '../../types/FileDataTypes'

export type RegulationFileDataMinimal = {
  id: number
  confirmationFiles?: number
  file: FileData
  interval: number
  tstamp: string
  productGroups: number[]
  survey: boolean
  status: {
    pending: boolean
    approval: ApprovalStatus | 'open'
  }
}

export const getStatusText = (status: RegulationFileDataMinimal['status'], isSupplier: boolean): string => {
  if (status.pending) {
    if (isSupplier) {
      return status.pending ? t`Bestätigung durch Vetter ausstehend` : t`Keine Bestätigung hochgeladen`
    } else {
      return status.pending ? t`Bestätigung durch Vetter ausstehend` : t`Keine Bestätigung hochgeladen`
    }
  } else {
    switch (status.approval) {
      case 'approved':
        return t`Bestätigt`
      case 'denied':
        return t`Abgelehnt`
      default:
        return t`Keine Bestätigung hochgeladen`
    }
  }
}

export type RegulationFileData = RegulationFileDataMinimal

export const useRegulationFile = createApiItemHook<RegulationFileData>('/regulationfiles')

export type UseRegulationFilesParams = {
  search?: string | null
  filter?: string | null
  folderId?: number
  supplierId?: number
}

export const useRegulationFiles = createApiDataHook<RegulationFileData[], UseRegulationFilesParams>(
  ({ folderId, supplierId, ...params }) => [
    folderId
      ? `/regulationfolders/${folderId}/regulationfiles`
      : supplierId
      ? `/suppliers/${supplierId}/regulationfiles`
      : null,
    params
  ]
)

export const useSpecificFiles = createApiDataHook<RegulationFileData[], { supplierId?: number }>(({ supplierId }) => [
  supplierId ? `/suppliers/${supplierId}/specificfiles` : null,
  {}
])

export type UpdateRegulationFileInput = {
  readonly fileId: number
  readonly interval?: number
  readonly productGroups?: number[]
}
export const useUpdateRegulationFile = (): {
  run: (data: UpdateRegulationFileInput, onSuccess?: (data: RegulationFileData) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: UpdateRegulationFileInput, onSuccess?: (data: RegulationFileData) => unknown],
    RegulationFileData
  >(
    () => ({
      createRequestData: ({ fileId, ...data }) => {
        return [
          `/regulationfiles/${fileId}`,
          prefixApiParams<Omit<UpdateRegulationFileInput, 'fileId'>>(data),
          { method: 'PATCH' }
        ]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/regulationfiles')
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}
