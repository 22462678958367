import { useCallback } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
const useCombinedRef = <RefType extends any>(...refs: React.ForwardedRef<RefType>[]): React.RefCallback<RefType> => {
  return useCallback(
    (el: RefType | null) => {
      for (const ref of refs) {
        if (typeof ref === 'function') {
          ref(el)
        } else if (ref) {
          ref.current = el
        }
      }
    },
    [refs]
  )
}

export default useCombinedRef
