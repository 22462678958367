import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { CertificateCodeDataMinimal, useCanCreateCertificateCodes } from '../../../../data/remote/CertificateCode'
import { OrderDataMinimal } from '../../../../data/remote/Order'
import { ProductDataMinimal } from '../../../../data/remote/Product'
import AddCertificateCodeModal from '../AddCertificateCodeModal'
import _ from 'lodash'
import DataColumn, { ColumnData } from '../../../../components/ListView/DataColumn'
import { useModalActions } from '../../../../components/Overlays/Modal'

export type CertificateCodeColumnProps = ColumnData<CertificateCodeDataMinimal> & {
  generatePath: (certificateCodeId: string) => string
  order: OrderDataMinimal
  product: ProductDataMinimal
}

const CertificateCodeColumn: React.FC<CertificateCodeColumnProps> = ({ generatePath, order, product, ...props }) => {
  const existingCodes = useMemo(() => {
    if (Array.isArray(props.items)) {
      return _.uniq(props.items.map((item) => item.code)).sort()
    }
    return []
  }, [props.items])

  const canCreateCertificateCodes = useCanCreateCertificateCodes()
  const { ref: modal, open } = useModalActions()
  const addCertificateCode = canCreateCertificateCodes ? open : undefined

  return (
    <>
      <DataColumn {...props} title={t`Zeugniscode`} newLabel={t`Code hinzufügen`} onNew={addCertificateCode} />
      {!!addCertificateCode && (
        <AddCertificateCodeModal
          ref={modal}
          order={order}
          product={product}
          existingCodes={existingCodes}
          generatePath={generatePath}
        />
      )}
    </>
  )
}

export default CertificateCodeColumn
