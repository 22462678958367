import React, { useCallback, useMemo } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { generatePath, match } from 'react-router-dom'
import routeParams, { BasicRouteInfo, RouteName, RouteParams } from '../views/routeParams'

type Params<CurrentRouteName extends RouteName> = (typeof routeParams)[CurrentRouteName] extends RouteParams<
  infer Fixed,
  infer Optional
>
  ? { [key in Fixed]: string } & { [key in Optional]?: string }
  : never

type SloppyParams<CurrentRouteName extends RouteName> = {
  [key in keyof Params<CurrentRouteName>]?: string | number
}

export type SetParamsAction<CurrentRouteName extends RouteName> =
  | SloppyParams<CurrentRouteName>
  | ((params: Params<CurrentRouteName>) => SloppyParams<CurrentRouteName>)

export const createRoute = <CurrentRouteName extends RouteName>(
  routeName: CurrentRouteName,
  Component: React.FC<
    BasicRouteInfo &
      match<Params<CurrentRouteName>> & {
        generatePath: (action: SetParamsAction<CurrentRouteName>, clearOptionals?: boolean) => string
        setParams: (action: SetParamsAction<CurrentRouteName>, clearOptionals?: boolean) => void
        baseUrl: string
      }
  >
): React.FC<BasicRouteInfo> => {
  const Result: React.FC<BasicRouteInfo> = (baseInfo) => {
    const history = useHistory()
    const match = useRouteMatch<Params<CurrentRouteName>>()
    const { optional: optionalParameters } = routeParams[routeName] ?? {}
    const generateLocalPath = useCallback(
      (args: SetParamsAction<CurrentRouteName>, clearOptionals = false): string => {
        const nextParams = typeof args === 'function' ? args(match.params) : args ?? {}
        if (clearOptionals) {
          for (const name of optionalParameters ?? []) {
            if (!(name in nextParams)) {
              nextParams[name as keyof Params<CurrentRouteName>] = undefined
            }
          }
        }
        return generatePath(match.path, { ...match.params, ...nextParams })
      },
      [match.params, match.path, optionalParameters]
    )
    const setParams = useCallback(
      (action: SetParamsAction<CurrentRouteName>, clearOptionals = false) => {
        history.replace(generateLocalPath(action, clearOptionals))
      },
      [generateLocalPath, history]
    )
    const baseUrl = useMemo(() => generateLocalPath({}, true), [generateLocalPath])

    return (
      <Component {...match} setParams={setParams} generatePath={generateLocalPath} baseUrl={baseUrl} {...baseInfo} />
    )
  }
  return Result
}
