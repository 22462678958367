import { t } from '@lingui/macro'
import React, { useMemo, useEffect } from 'react'

import { Button, Col, Form, FormLabel, Modal, Row } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { Input } from '../../../../components/Form'
import { ModalProps, ModalRef, useModalActions, useModalProps } from '../../../../components/Overlays/Modal'
import ToggleButtons from '../../../../components/ToggleButtons'
import { useProductGroups } from '../../../../data/remote/ProductGroup'
import { RegulationFileDataMinimal, useUpdateRegulationFile } from '../../../../data/remote/RegulationFile'
import useCombinedRef from '../../../../scripts/useCombinedRef'
import { HTMLCheckbox } from '../../../../components/Checkbox'

export type ChangeFileModalRef = ModalRef<RegulationFileDataMinimal>
export type ChangeFileModalProps = ModalProps

const ChangeFileModal = React.forwardRef<ChangeFileModalRef, ChangeFileModalProps>(function SetIntervalModal(
  props,
  ref
) {
  const modalActions = useModalActions()
  const modalRef = useCombinedRef(modalActions.ref, ref)

  const [modalProps, file] = useModalProps(props, modalRef)
  const { register, control, handleSubmit, setValue, watch } = useForm<{
    interval: number
    productGroups: number[]
    survey?: boolean
  }>({
    defaultValues: {
      interval: file?.interval ?? 0,
      productGroups: file?.productGroups ?? [],
      survey: file?.survey ?? false
    }
  })

  useEffect(() => {
    setValue('interval', file?.interval ?? 0)
    setValue('productGroups', file?.productGroups ?? [])
    setValue('survey', file?.survey ?? false)
  }, [setValue, file])

  const productGroups = useProductGroups()
  const productGroupOptions = useMemo(
    () => productGroups.data?.map(({ id, localizedTitle }) => ({ id: id.toString(), label: localizedTitle })) ?? [],
    [productGroups.data]
  )

  const updateRegulationFile = useUpdateRegulationFile()

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        if (!updateRegulationFile.running && file?.id) {
          updateRegulationFile.run({ ...data, fileId: file.id }, () => {
            // history.push(generatePath(data.code))
            modalActions.close()
          })
        }
      }),
    [file?.id, handleSubmit, modalActions, updateRegulationFile]
  )

  return (
    <Modal {...modalProps}>
      <Modal.Header closeButton>
        <Modal.Title>{t`Datei bearbeiten`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12}>
              {/* @ts-ignore */}
              <Input name="filename" label={t`Dateiname`} value={file?.file.properties.title} readOnly />
            </Col>

            <Col xs={12} md={6}>
              <Input
                label={t`Bestätigungsintervall`}
                {...register('interval', { valueAsNumber: true })}
                type="number"
                min="0"
                append={t`Monate`}
              />
            </Col>
            {file?.file.properties.extension === 'pdf' && (
              <Col xs={12} md={6}>
                <Form.Group>
                  <FormLabel htmlFor="survey-pdf-checkbox">{t`PDF-Einstellungen`}</FormLabel>
                  <HTMLCheckbox
                    value={watch('survey')}
                    id="survey-pdf-checkbox"
                    className="custom-control-input"
                    onChange={(checked) => setValue('survey', checked)}
                  />
                  <Button
                    className="w-100 mb-1 btn-checkbox-toggle"
                    as="label"
                    variant="outline-light text-primary"
                    size="lg"
                    title={t`Fragebogen auswerten`}
                    htmlFor="survey-pdf-checkbox"
                  >
                    {t`Fragebogen auswerten`}
                  </Button>
                </Form.Group>
              </Col>
            )}

            <Col xs={12}>
              <Form.Group>
                <Form.Label>{t`Produktgruppen`}</Form.Label>
                <Controller
                  name="productGroups"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <ToggleButtons
                      value={Object.fromEntries(value.map((x) => [x, true]))}
                      onChange={(id, selected) => {
                        onChange(
                          value
                            .filter((x) => x.toString() !== id)
                            .concat(selected ? [Number(id)] : [])
                            .sort()
                        )
                      }}
                      options={productGroupOptions}
                      columns={{ xs: 6, md: 4 }}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col xs={6} className="ml-auto">
              <Button variant="primary" type="submit" disabled={false} block={true} size="lg">
                {t`Änderungen speichern`}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
})

export default ChangeFileModal
