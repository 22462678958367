import { createApiDataHook, createApiItemHook } from '../../scripts/api'

export type ProductGroupDataMinimal = {
  id: number
  localizedTitle: string
  title: LocalizedTitles
  regulationFiles: number[]
}

export type ProductGroupData = ProductGroupDataMinimal

export const useProductGroup = createApiItemHook<ProductGroupData>('/productgroups')

export type UseProductGroupsParams = {
  search?: string | null
}

export const useProductGroups = createApiDataHook<ProductGroupDataMinimal[], UseProductGroupsParams>('/productgroups')
