import React from 'react'
import axios from 'axios'
import { SWRConfig, SWRResponse } from 'swr'
import { swrBaseConfig } from './config'
import { prefixApiParams } from './utils'
import { useSWR } from '../data/local/hooks/swrHooks'

type SWRProviderProps = {
  children: React.ReactNode
}

function localStorageProvider() {
  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'))
  window.addEventListener('beforeunload', () => {
    localStorage.removeItem('app-cache')
  })
  return map
}

const swrConfig: ExtractProps<typeof SWRConfig>['value'] = {
  ...swrBaseConfig,
  provider: localStorageProvider,
  fetcher: async (url: string, params?: string) => {
    try {
      const response = await axios.get(url, {
        params: params && prefixApiParams(JSON.parse(params))
      })
      return response.data?.data ?? response.data
    } catch (error) {
      console.error('Error while fetching', error)
      throw error
    }
  }
}

export const SWRProvider: React.FC<SWRProviderProps> = ({ children }) => (
  <SWRConfig value={swrConfig}>{children}</SWRConfig>
)

export type ApiHookData<ItemType> = {
  loading: boolean
  data: ItemType | undefined
  reload: SWRResponse<ItemType, Error>['mutate']
}

export const useApiItem = <ItemType, IdType = number>(basePath: string, id?: IdType): ApiHookData<ItemType> => {
  const { data, error, mutate: reload } = useSWR<ItemType, Error>(id ? basePath + '/' + id : null)

  const loading = !data && !error

  return {
    loading,
    data,
    reload
  }
}
export const createApiItemHook =
  <ItemType, IdType = number>(basePath: string) =>
  (id?: IdType): ApiHookData<ItemType> => {
    return useApiItem(basePath, id)
  }

export const useApiData = <ResultType, ParamType>(
  basePath: string | ((params: Partial<ParamType>) => [path: string | null, params: Partial<ParamType>]),
  params?: ParamType | undefined,
  enabled = true
): ApiHookData<ResultType> => {
  const [path, _params] = typeof basePath === 'function' ? basePath(params ?? {}) : [basePath, params]

  const { data, error, mutate: reload } = useSWR<ResultType, undefined>(enabled ? path : null, JSON.stringify(_params))

  const loading = !data && !error && enabled

  return {
    loading,
    data,
    reload
  }
}
export const createApiDataHook =
  <ResultType, ParamType>(
    path: string | ((params: Partial<ParamType>) => [path: string | null, params: Partial<ParamType>])
  ) =>
  (params?: ParamType, enabled = true): ApiHookData<ResultType> => {
    return useApiData(path, params, enabled)
  }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterApiHookData = <ResultType,>(
  data: ApiHookData<ResultType>,
  filter: (data: ResultType) => ResultType
): ApiHookData<ResultType> => ({
  ...data,
  data: data.data ? filter(data.data) : undefined
})
