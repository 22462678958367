import React from 'react'
import { Icon } from '../Icon'
import Plus from 'jsx:./../../assets/icons/plus.svg'
import { NavLink } from 'react-router-dom'

export type NewButtonProps = {
  onClick?: () => void
  url?: string
  children?: React.ReactNode
}

export const NewButton: React.FC<NewButtonProps> = ({ onClick, url, children }) => {
  if (url) {
    return (
      <NavLink to={url} className="listview-col-new" onClick={onClick}>
        <Icon svg={Plus} />
        <span>{children}</span>
      </NavLink>
    )
  }
  return (
    <button className="listview-col-new" onClick={onClick}>
      <Icon svg={Plus} />
      <span>{children}</span>
    </button>
  )
}
