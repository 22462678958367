import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { Transition, TransitionGroup } from 'react-transition-group'
import { TransitionProps } from 'react-transition-group/Transition'

Modal.TRANSITION_DURATION = 1000
Modal.BACKDROP_TRANSITION_DURATION = 1000

type NotificationProviderProps = {
  children: React.ReactNode
}

export type ConfirmationProps = {
  title: string
  text?: string | JSX.Element
  dismissable?: boolean
  onDismiss?: (e: Event) => unknown
  options?: {
    type?: 'danger' | 'primary' | 'secondary'
    label: string
    onClick?: (e: Event) => unknown
  }[]
}

type TransformedConfirmationProps = Omit<ConfirmationProps, 'onDismiss' | 'options'> & {
  id: string
  onDismiss: () => void
  dismiss: () => void
  options: {
    type: 'danger' | 'primary' | 'secondary'
    label: string
    onClick: () => void
  }[]
}

export type NotificationContextData = {
  confirm: (confirmation: ConfirmationProps) => { dismiss: () => void }
}
export const NotificationContext = React.createContext<NotificationContextData>({
  confirm: () => ({ dismiss: () => undefined })
})

const generateConfirmationId = (() => {
  let lastId = 1
  return () => 'confirmation-' + lastId++
})()

const ModalTransition: React.FC<
  Pick<ModalProps, 'centered' | 'size' | 'onHide'> & Omit<TransitionProps, 'timeout' | 'addEndListener'>
> = ({ centered, size, children, onHide, ...props }) => {
  const nodeRef = useRef<HTMLDivElement>(null)
  const handleExited = useCallback(() => {
    nodeRef.current?.dispatchEvent(new Event('hidden.bs.modal'))
  }, [])

  return (
    <Transition
      nodeRef={nodeRef}
      key={props.key}
      // timeout={6000}
      addEndListener={(done: () => void) => {
        nodeRef.current?.addEventListener('hidden.bs.modal', () => setTimeout(done, 1000), { once: true })
      }}
      {...props}
    >
      {(state) => (
        <div ref={nodeRef}>
          {state}
          <Modal
            {...{ centered, size, onHide }}
            show={state === 'entered' || state === 'entering'}
            animation
            onExited={handleExited}
          >
            {children}
          </Modal>
        </div>
      )}
    </Transition>
  )
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [confirmations, setConfirmations] = useState<TransformedConfirmationProps[]>([])
  const confirmation = confirmations[0]

  const transformConfirmation = useCallback((confirmation: ConfirmationProps): TransformedConfirmationProps => {
    const id = generateConfirmationId()
    const withDismiss = (fn?: (e: Event) => unknown) => () => {
      const e = new Event('confirm-dismissed')
      fn?.(e)
      if (!e.defaultPrevented) {
        setConfirmations((confirmations) => confirmations.filter((x) => x.id !== id))
      }
    }
    return {
      ...confirmation,
      id,
      dismissable: confirmation.dismissable ?? true,
      onDismiss: confirmation.dismissable !== false ? withDismiss(confirmation.onDismiss) : () => undefined,
      dismiss: withDismiss(),
      options:
        confirmation.options?.map((option) => ({
          ...option,
          type: option.type ?? 'secondary',
          onClick: withDismiss(option.onClick)
        })) ?? []
    }
  }, [])

  const confirm = useCallback(
    (conf: ConfirmationProps) => {
      const confirmation = transformConfirmation(conf)
      setConfirmations((confirmations) => confirmations.concat([confirmation]))
      return { dismiss: confirmation.dismiss }
    },
    [transformConfirmation]
  )

  return (
    <NotificationContext.Provider value={useMemo(() => ({ confirm }), [confirm])}>
      {children}
      {confirmation?.id}
      <TransitionGroup>
        {confirmation && (
          <ModalTransition key={confirmation.id} centered size="lg" onHide={confirmation.onDismiss}>
            <Modal.Header closeButton={confirmation.dismissable}>
              <Modal.Title>{confirmation.title}</Modal.Title>
            </Modal.Header>
            {!!confirmation.text && (
              <Modal.Body>
                <p className="text-lg">{confirmation.text}</p>
              </Modal.Body>
            )}
            {!!confirmation.options && confirmation.options.length > 0 && (
              <Modal.Footer>
                {confirmation.options.map((option, i) => (
                  <Button key={i} variant={option.type ?? 'secondary'} size="lg" onClick={option.onClick}>
                    {option.label}
                  </Button>
                ))}
              </Modal.Footer>
            )}
          </ModalTransition>
        )}
      </TransitionGroup>
    </NotificationContext.Provider>
  )
}

export const useConfirm = (): NotificationContextData['confirm'] => useContext(NotificationContext).confirm
