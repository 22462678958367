import React, { useCallback } from 'react'
import { Header } from '../../../components/ListView/Header'
import { OrderDataMinimal } from '../../../data/remote/Order'
import mailSvg from 'jsx:./../../../assets/icons/envelope.svg'
import { t } from '@lingui/macro'

import { ProductDataMinimal } from '../../../data/remote/Product'
import { useSettings } from '../../../data/remote/Settings'
import { useCanManageComplaints } from '../../../data/remote/Complaint'
import DotsMenu from '../../../components/DotsMenu'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import EditComplaintDescriptionModal from './EditComplaintDescriptionModal'
import { useModalActions } from '../../../components/Overlays/Modal'
import { ComplaintData } from '../../../types/ComplaintDataTypes'
import DeleteComplaintModal from './DeleteComplaintModal'

type FilesHeaderComplaintsProps = {
  order: OrderDataMinimal | undefined
  product: ProductDataMinimal | undefined
  complaint: ComplaintData | undefined
  children?: React.ReactNode
}

const FilesHeaderComplaints: React.FC<FilesHeaderComplaintsProps> = ({ children, order, product, complaint }) => {
  const { settings } = useSettings()
  const { ref: editModalRef, open: openEditModal } = useModalActions()
  const { ref: deleteModalRef, open: openDeleteModal } = useModalActions()
  const sendEmail = useCallback(() => {
    if (!order || !settings) {
      return
    }
    let subject = complaint ? t`CAQ: ${complaint.caq}` : ''
    if (order) {
      subject += t`, Bestellnummer: ${order.identifier}`
    }
    if (product) {
      subject += t`, Artikel: ${product.sku}`
    }
    const body = t`Sehr geehrte Damen und Herren,`
    const link = `mailto:${encodeURIComponent(
      order.supplierAltEmail ? order.supplierAltEmail : order.supplierEmail
    )}?subject=${encodeURIComponent(subject)}&cc=${encodeURIComponent(
      settings.complaintEmail.cc
    )}&bcc=${encodeURIComponent(settings.complaintEmail.bccSupplier)}&body=${encodeURIComponent(body)}`
    location.href = link
  }, [complaint, order, product, settings])

  const sendInternEmail = useCallback(() => {
    if (!order || !settings) {
      return
    }
    let subject = complaint ? t`CAQ: ${complaint.caq}` : ''
    if (order) {
      subject += t`, Bestellnummer: ${order.identifier}`
    }
    if (product) {
      subject += t`, Artikel: ${product.sku}`
    }
    const body = t`Sehr geehrte Damen und Herren,`
    const link = `mailto: ?subject=${encodeURIComponent(subject)}&cc=${encodeURIComponent(
      settings.complaintEmail.cc
    )}&bcc=${encodeURIComponent(settings.complaintEmail.bccInternal)}&body=${encodeURIComponent(body)}`
    location.href = link
  }, [complaint, order, product, settings])

  const canManageComplaints = useCanManageComplaints()
  const editDescription = canManageComplaints ? openEditModal : undefined

  const handleDropdownClick = useCallback(
    (key: string) => {
      if (key === 'edit') {
        openEditModal()
      } else if (key === 'delete') {
        openDeleteModal()
      }
    },
    [openEditModal, openDeleteModal]
  )

  return (
    <div className="d-flex flex-column flex-grow-1 bg-white">
      {complaint && (
        <>
          <Header
            title={complaint ? t`Reklamation` : undefined}
            toolbar={
              canManageComplaints ? (
                <>
                  {!!order && (
                    <DotsMenu icon={mailSvg}>
                      <DropdownItem key="mail" onClick={sendEmail}>
                        E-Mail an Lieferant senden
                      </DropdownItem>
                      <DropdownItem key="mail" onClick={sendInternEmail}>
                        Interne E-Mail senden
                      </DropdownItem>
                    </DotsMenu>
                  )}
                  {complaint && (
                    <DotsMenu>
                      <DropdownItem
                        onClick={() => handleDropdownClick('edit')}
                      >{t`Beschreibung bearbeiten`}</DropdownItem>
                      {complaint.status > 1 && (
                        <DropdownItem key="close" onClick={editDescription}>
                          {t`Reklamation abschließen`}
                        </DropdownItem>
                      )}
                      <DropdownItem key="delete" onClick={() => handleDropdownClick('delete')}>
                        <span className="text-danger"> {t`Reklamation löschen`}</span>
                      </DropdownItem>
                    </DotsMenu>
                  )}
                </>
              ) : undefined
            }
          />
          {complaint?.description && (
            <div className="bg-white listview-header">
              <div className="listview-title font-weight-normal">{complaint.description}</div>
            </div>
          )}
          {children}
          <EditComplaintDescriptionModal
            ref={editModalRef}
            description={complaint?.description}
            complaintId={complaint.id}
          />
          <DeleteComplaintModal ref={deleteModalRef} complaintId={complaint.id} />
        </>
      )}
    </div>
  )
}

export default FilesHeaderComplaints
