import { toast as _toast, ToastContent, ToastOptions } from 'react-toastify'
import parse from 'html-react-parser'

export type ToastProps = ToastOptions & {
  title?: string
  text?: string
  content?: ToastContent
}

export const showToast = ({ title, text, content, ...props }: ToastProps): void => {
  const toastContent = (
    <>
      {title && <div className="Toastify__toast-title">{title}</div>}
      {text && <div className="Toastify__toast-text">{parse(text)}</div>}
      {content}
    </>
  )
  const autoClose = (text && text?.toString().length * 100 + 100) || 7000
  _toast(toastContent, { ...props, autoClose, hideProgressBar: false })
}
