import { t } from '@lingui/macro'
import React from 'react'
import { Spinner } from 'react-bootstrap'

const ServiceLoading: React.FC = () => {
  return (
    <div className="text-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">{t`Lädt...`}</span>
      </Spinner>
    </div>
  )
}

export default ServiceLoading
