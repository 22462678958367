import React from 'react'

export type IconProps = {
  svg: React.ComponentType<React.SVGProps<SVGSVGElement>>
  className?: string
  ml?: number
  mr?: number
}

export const Icon: React.FC<IconProps> = ({ svg, className = '', ml, mr }) => {
  const Svg = svg

  if (ml) {
    className += ' ml-' + ml
  }
  if (mr) {
    className += ' mr-' + mr
  }

  className += ' icon'
  className = className.trim()

  return <Svg className={className} />
}
