import { t } from '@lingui/macro'
import { RegulationCategoryDataMinimal, useRegulationCategories } from '../../../data/remote/RegulationCategory'
import { RegulationFolderDataMinimal, useRegulationFolders } from '../../../data/remote/RegulationFolder'
import DataColumn, { useColumnData } from '../../../components/ListView/DataColumn'
import { createRoute } from '../../../navigation/createRoute'
import { toNumber } from '../../../scripts/utils'
import { useRegulationFiles, useSpecificFiles } from '../../../data/remote/RegulationFile'
import RegulationFilesTableSupplier from './Columns/RegulationFilesTableSupplier'
import { useMemo } from 'react'
import { ApiHookData } from '../../../scripts/api'
import { useAuthentication } from '../../../data/remote/User'

const Files = createRoute('Files', function Files({ params: { categoryId, folderId }, setParams, generatePath }) {
  const { user: currentUser } = useAuthentication()
  const specificFiles = useSpecificFiles({ supplierId: currentUser?.id })
  const hasSpecificFiles = !!specificFiles.data?.length

  const categoryData = useColumnData<RegulationCategoryDataMinimal | 'specific-files'>(
    () => ({
      disableSearch: true,
      useItems() {
        const result = useRegulationCategories()
        const dataWithSpecificFiles = useMemo<(RegulationCategoryDataMinimal | 'specific-files')[]>(
          () => [...(result.data ?? []), 'specific-files'],
          [result.data]
        )
        return {
          ...result,
          loading: result.loading || specificFiles.loading,
          data: hasSpecificFiles ? dataWithSpecificFiles : result.data
        } as ApiHookData<(RegulationCategoryDataMinimal | 'specific-files')[]>
      },
      isActiveItem(item) {
        return (
          (typeof item === 'string' && item === categoryId) ||
          (typeof item !== 'string' && item.id === toNumber(categoryId))
        )
      },
      shouldHaveActiveItem: !!categoryId,
      onActiveItemMiss() {
        setParams({}, true)
      },
      renderItem(category) {
        if (typeof category === 'string') {
          return {
            id: 'specific-files',
            title: t`Spezielle Dateien`,
            url: generatePath({ categoryId: 'specific-files' }, true)
          }
        }
        return {
          id: category.id,
          title: category.localizedTitle,
          url: generatePath({ categoryId: category.id }, true)
        }
      }
    }),
    [categoryId, generatePath, setParams, hasSpecificFiles, specificFiles.loading]
  )

  const folderData = useColumnData<RegulationFolderDataMinimal>(
    () => ({
      disableSearch: true,
      useItems() {
        return useRegulationFolders(
          {
            categoryId: categoryData.current && typeof categoryData.current !== 'string' ? categoryData.current.id : 0
          },
          !!categoryData.current
        )
      },
      isActiveItem(item) {
        return item.id === toNumber(folderId)
      },
      shouldHaveActiveItem: !!categoryData.current && !!folderId,
      onActiveItemMiss() {
        setParams({ categoryId }, true)
      },
      renderItem(folder) {
        return {
          id: folder.id,
          title: folder.localizedTitle,
          url: generatePath({ folderId: folder.id }, false)
        }
      }
    }),
    [categoryData, categoryId, folderId, generatePath, setParams]
  )

  const { data: files, loading: filesLoading } = useRegulationFiles(
    { folderId: folderData.current?.id },
    !!folderData.current
  )

  return (
    <>
      <DataColumn title={t`Kategorie`} {...categoryData} arrow={categoryId ? 'right' : undefined} />
      {!!categoryData.current && typeof categoryData.current !== 'string' && (
        <DataColumn title={t`Ordner`} {...folderData} arrow={folderId ? 'right' : undefined} />
      )}
      {!!folderData.current && <RegulationFilesTableSupplier data={files} loading={filesLoading} />}
      {categoryData.current === 'specific-files' && (
        <RegulationFilesTableSupplier data={specificFiles.data} loading={specificFiles.loading} isSpecificFiles />
      )}
    </>
  )
})

export default Files
