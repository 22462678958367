import { t } from '@lingui/macro'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { usePermission } from './User'

export type RegulationFolderDataMinimal = {
  id: number
  localizedTitle: string
  title: LocalizedTitles
  category: number
  files: number[]
}

export type RegulationFolderData = RegulationFolderDataMinimal

export const useRegulationFolder = createApiItemHook<RegulationFolderData>('/regulationfolders')

export type UseRegulationFoldersParams = {
  categoryId?: number
}

export const useRegulationFolders = createApiDataHook<RegulationFolderDataMinimal[], UseRegulationFoldersParams>(
  ({ categoryId }) => [
    categoryId ? `/regulationcategories/${encodeURIComponent(categoryId)}/regulationfolders` : null,
    {}
  ]
)

export type CreateRegulationFolderInput = {
  categoryId: number
  readonly title: {
    readonly de: string
    readonly en?: string
  }
}
export const useCanCreateRegulationFolders = (): boolean => usePermission('Alle_Dateien_verwalten')
export const useCreateRegulationFolder = (): {
  run: (data: CreateRegulationFolderInput, onSuccess?: (data: RegulationFolderDataMinimal) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: CreateRegulationFolderInput, onSuccess?: (data: RegulationFolderDataMinimal) => unknown],
    RegulationFolderDataMinimal
  >(
    () => ({
      createRequestData: ({ categoryId, ...data }) => {
        return [
          `/regulationcategories/${categoryId}/regulationfolders`,
          prefixApiParams<Omit<CreateRegulationFolderInput, 'categoryId'>>(data),
          { method: 'POST' }
        ]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/regulationfolders')
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}
