import { t } from '@lingui/macro'
import { SearchColumnProps } from '.'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { CertificateCodeDataMinimal, useCertificateCodes } from '../../../../data/remote/CertificateCode'
import defaultColumnData from './defaultColumnData'
import { getParentSelection } from './utils'

export const useCertificateCodesData = ({
  setSelection,
  selection,
  entrypoint,
  setEntrypoint,
  setColumnSelection
}: SearchColumnProps): ColumnData<CertificateCodeDataMinimal> =>
  useColumnData<CertificateCodeDataMinimal>(
    ({ setFilter }) => {
      return {
        useItems(search, filter) {
          const { products: productId } = getParentSelection(entrypoint, selection, 'certificatecodes')
          return useCertificateCodes(
            {
              search,
              filter,
              productId
            },
            !!productId || entrypoint === 'certificatecodes'
          )
        },
        filterOptions: {
          ['']: t`Alle Zeugniscodes`,
          pending: t`Mit ungeprüften Zeugnissen`,
          denied: t`Mit abgelehnten Zeugnissen`,
          open: t`Mit offenem Status`
        },
        renderItem: (certificateCode) => ({
          id: certificateCode.id,
          title: certificateCode.code,
          tooltip: (
            <>
              <b>Bestellung: </b>
              {certificateCode.order}
              <br />
              <b>Artikel: </b>
              {certificateCode.product}
            </>
          ),
          onClick: () => {
            setColumnSelection(certificateCode.id, false)
          },
          onDoubleClick: () => {
            setColumnSelection(certificateCode.id, true, setFilter, certificateCode.code)
          },
          gray: certificateCode.status?.approval === 'closed',
          denied: certificateCode.status?.approval === 'denied',
          pending: certificateCode.status?.pending
        }),
        ...defaultColumnData('certificatecodes', {
          entrypoint,
          setEntrypoint,
          selection,
          setSelection
        })
      }
    },
    [entrypoint, selection, setColumnSelection, setEntrypoint, setSelection]
  )
