import React, { useCallback, useMemo } from 'react'
import { Header, HeaderToolbarButton } from '../../../components/ListView/Header'
import {
  useCanApproveCertificates,
  useCertificates,
  useUpdateCertificateApproval
} from '../../../data/remote/Certificate'
import { OrderDataMinimal } from '../../../data/remote/Order'
import mailSvg from 'jsx:./../../../assets/icons/envelope.svg'
import checkSvg from 'jsx:./../../../assets/icons/check.svg'
import { t } from '@lingui/macro'
import { CertificateCodeDataMinimal } from '../../../data/remote/CertificateCode'
import { ProductDataMinimal } from '../../../data/remote/Product'
import { useConfirm } from '../../../navigation/Notifications'
import { useSettings } from '../../../data/remote/Settings'

export type FilesHeaderProps = {
  order: OrderDataMinimal | undefined
  product: ProductDataMinimal | undefined
  certificateCode: CertificateCodeDataMinimal | undefined
  children?: React.ReactNode
}

const FilesHeader: React.FC<FilesHeaderProps> = ({ children, order, product, certificateCode }) => {
  const canApprove = useCanApproveCertificates()
  const { settings } = useSettings()

  const sendEmail = useCallback(() => {
    if (!order || !settings) {
      return
    }
    let subject = t`Bestellnummer: ${order.identifier}`
    if (product) {
      subject += t`, Artikel: ${product.sku}`
    }
    const body = t`Sehr geehrte Damen und Herren,`
    const link = `mailto:${encodeURIComponent(order.supplierEmail)}?subject=${encodeURIComponent(
      subject
    )}&bcc=${encodeURIComponent(settings.certificateReminder.bcc)}&body=${encodeURIComponent(body)}`
    location.href = link
  }, [order, product, settings])

  const { data: certificates } = useCertificates({ codeId: certificateCode?.id }, !!certificateCode)
  const pendingCertificates = useMemo(
    () => certificates?.filter((x) => x.status.approval === 'pending').map((x) => x.id) ?? [],
    [certificates]
  )
  const updateCertificateApproval = useUpdateCertificateApproval(pendingCertificates)
  const confirm = useConfirm()
  const acceptAllCertificates = useCallback(() => {
    confirm({
      title: t`Alle offenen Zeugnisse freigeben`,
      text: t`Sollen alle offenen Zeugnisse freigegeben werden?`,
      options: [
        {
          label: t`Abbrechen`,
          type: 'secondary'
        },
        {
          label: t`Freigeben`,
          type: 'primary',
          onClick: updateCertificateApproval.approve
        }
      ]
    })
  }, [confirm, updateCertificateApproval.approve])

  return (
    <div className="d-flex flex-column flex-grow-1">
      <Header
        title={certificateCode ? t`Zeugnisse` : undefined}
        toolbar={
          canApprove ? (
            <>
              {!!order && (
                <HeaderToolbarButton icon={mailSvg} title={t`E-Mail an den Lieferanten`} onClick={sendEmail} />
              )}
              {!!certificateCode && (
                <HeaderToolbarButton
                  icon={checkSvg}
                  title={t`Alle offenen Zeugnisse freigeben`}
                  disabled={!pendingCertificates.length}
                  onClick={acceptAllCertificates}
                />
              )}
            </>
          ) : undefined
        }
      />
      {children}
    </div>
  )
}

export default FilesHeader
