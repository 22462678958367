import React from 'react'
import { Scrollview } from '../Layout/Scrollview'
import { NewButton } from '../ListView/NewButton'
import { Loader } from '../Loader'
import ComplaintEmailsTable from '../../views/LoggedIn/Complaints/Columns/ComplaintEmailsTable'

import { Header } from '../ListView/Header'
import { Icon } from '../Icon'
import ChevronDown from 'jsx:./../../assets/icons/chevron-down.svg'

import { t } from '@lingui/macro'
import { ComplaintData } from '../../types/ComplaintDataTypes'
import { FileData } from '../../types/FileDataTypes'

export type DataTableItem<ItemType> =
  | ItemType
  | (() => JSX.Element | undefined | false | null | void)
  | undefined
  | false
  | null
  | void

const isFC = (item: DataTableItem<unknown>): item is () => JSX.Element | undefined | false | null | void => {
  return typeof item === 'function'
}

export type DataTableProps<ItemType, ColumnName extends string> = {
  items?: DataTableItem<ItemType>[]
  columns: Record<ColumnName, React.ReactNode>
  columnClasses?: Record<string, string>
  hiddenColumns?: ColumnName[]
  greyHeader?: boolean
  children?: (
    item: ItemType,
    index: number,
    items: DataTableItem<ItemType>[]
  ) =>
    | ({
        [key in ColumnName]?: React.ReactNode
      } & { fileAnswer?: FileData | undefined })
    | JSX.Element
    | false
    | undefined
    | null
  emails?: {
    complaint: ComplaintData
    count: number
  }
}

export const isReactChild = (el: unknown): el is JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.isValidElement(el as any) || typeof el === 'string' || typeof el === 'number'
}

export const loaderRow = (): JSX.Element => <Loader width={300} dynamicWidth={0.5} />

export const dynamicRows = <ItemType,>(
  items: ItemType[] | undefined,
  loading: boolean | undefined,
  emptyMessage: string
): DataTableItem<ItemType>[] => {
  if (loading) {
    return [loaderRow, loaderRow, loaderRow]
  }
  if (!items || !items.length) {
    return [() => <td className="text-secondary text-center">{emptyMessage}</td>]
  }
  return items
}

export const dynamicEmailRows = <ItemType,>(
  items: ItemType[] | undefined,
  loading: boolean | undefined,
  emptyMessage: string
): DataTableItem<ItemType>[] => {
  if (loading) {
    return [loaderRow, loaderRow, loaderRow]
  }
  if (!items || !items.length) {
    return [() => <td className="text-secondary text-center">{emptyMessage}</td>]
  }
  return items
}

export const sectionHeader = (args: {
  title?: string
  newLabel?: string
  onNew?: () => unknown
}): ((() => JSX.Element) | false)[] => [
  !!args.title && (() => <td className="section-header">{args.title}</td>),
  !!args.newLabel &&
    (() => (
      <td className="btn-wrap">
        <NewButton onClick={args.onNew}>{args.newLabel}</NewButton>
      </td>
    ))
]

const DataTable = <ItemType, ColumnName extends string>({
  columns,
  hiddenColumns = [],
  columnClasses,
  items,
  children: renderColumns,
  greyHeader,
  emails
}: DataTableProps<ItemType, ColumnName>): React.ReactElement => {
  const colCount = Object.keys(columns).length

  const wrapEl = (el: JSX.Element, index: number) => {
    if (el.type === 'tr') {
      return React.cloneElement(el, { key: index })
    }
    if (el.type === 'td') {
      return <tr key={index}>{React.cloneElement(el, { colSpan: colCount })}</tr>
    }
    return (
      <tr key={index}>
        <td colSpan={colCount}>{el}</td>
      </tr>
    )
  }

  const columnNames = Object.keys(columns).filter((x) => !hiddenColumns.includes(x as ColumnName))

  const renderItem = (item: DataTableItem<ItemType>, index: number) => {
    if (!item) {
      return
    }
    const columnData = isFC(item) ? item() : renderColumns?.(item, index, items ?? [])
    if (isReactChild(columnData)) {
      return wrapEl(columnData, index)
    }
    if (columnData) {
      return (
        <React.Fragment key={index}>
          <tr>
            {columnNames.map((key) => (
              <td key={key} className={columnClasses?.[key as ColumnName]}>
                {columnData[key as ColumnName]}
              </td>
            ))}
          </tr>
          {columnData.fileAnswer && (
            <tr className="complaint_file__answer">
              <td colSpan={colCount}>
                <div>
                  {t`Antwort:`}
                  <span className="ml-1">
                    <a href={columnData.fileAnswer.publicUrl} target="_blank" rel="noreferrer">
                      <u>{columnData.fileAnswer.properties.title}</u>
                    </a>
                  </span>
                </div>
              </td>
            </tr>
          )}
        </React.Fragment>
      )
    }
  }

  const [show, setShow] = React.useState(false)

  const handleLoad = () => {
    setShow(!show)
  }

  return (
    <div className="data-table-wrap">
      <Scrollview wideScrollbarY>
        <table className={'data-table' + (greyHeader ? ' grey-header' : '')}>
          <thead>
            <tr>
              {Object.entries<React.ReactNode>(columns).map(([key, value]) =>
                hiddenColumns.includes(key as ColumnName) ? undefined : (
                  <th key={key} className={columnClasses?.[key as ColumnName]}>
                    <div>{value}</div>
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>{items?.map(renderItem)}</tbody>
        </table>
        {emails && emails?.complaint?.emails > 0 ? (
          <div className="mt-5">
            <div className="cursor-pointer" onClick={handleLoad}>
              <Header
                title={t`E-Mails` + (emails?.complaint.emails ? ` (${emails.complaint.emails})` : '')}
                toolbar={
                  <div>
                    <Icon svg={ChevronDown} className={`ml-auto mailrow ${show ? 'icon-rotate-180' : ''}`} />
                  </div>
                }
              />
            </div>
            {show && <ComplaintEmailsTable complaint={emails.complaint} />}
          </div>
        ) : null}
      </Scrollview>
    </div>
  )
}

export default DataTable
