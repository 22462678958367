import React from 'react'
import { Logo } from './Logo'
import { Col, Container, Row } from 'react-bootstrap'
import HeaderToolbar from './HeaderToolbar'
import { useSettings } from '../../data/remote/Settings'

export const Header: React.FC = () => {
  const { serviceAvailable } = useSettings()

  return (
    <header>
      <Container>
        <Row>
          <Col>
            <Logo />
          </Col>
          {serviceAvailable && <HeaderToolbar />}
        </Row>
      </Container>
    </header>
  )
}
