import React, { useCallback } from 'react'
import { ListItem, ListItemProps as ListItemPropsOriginal } from './ListItem'
import { Scrollview } from '../Layout/Scrollview'
import { Header } from './Header'
import { NewButton } from './NewButton'
import { FilterItemProps } from './FilterDropdown'
import { Loader } from '../Loader'
import { ListItemSteelworks } from './ListItemSteelworks'
import { ListItemComplaints } from './ListItemComplaints'

export type ColumnProps = {
  title?: string
  /**
   * Suchfeld wird ausgeblendet wenn undefined
   */
  search?: string
  searchTitle?: string | undefined
  onSearch?: (input: string) => void
  filter?: FilterItemProps[]
  newLabel?: string
  type?: 'default' | 'complaint' | 'steelworks' | 'certificate'
  onNew?: () => void
  newUrl?: string
  loading?: boolean
  items?: ListItemProps[]
  onSelect?: (item: ListItemProps | null) => void
  itemsFirst?: boolean
  autoHideScrollbar?: boolean
  arrow?: 'left' | 'right'
  activeTitle?: boolean
  children?: React.ReactNode
}

export type ListItemProps = ListItemPropsOriginal & {
  id: string | number
  title: string
  additionalLabel?: string
  contextMenu?: React.ReactElement[]
}

export const Column: React.FC<ColumnProps> = ({
  title,
  search,
  searchTitle,
  onSearch,
  type,
  filter,
  newLabel,
  onNew,
  newUrl,
  loading = false,
  items,
  onSelect,
  itemsFirst,
  autoHideScrollbar,
  arrow,
  children,

  activeTitle
}) => {
  const selectItem = useCallback(
    (item: ListItemProps | null) => {
      if (item && !item.active) {
        onSelect?.(item)
      } else {
        onSelect?.(null)
      }
    },
    [onSelect]
  )

  return (
    <div className="listview-col">
      <Header
        title={title}
        onSearch={onSearch}
        searchTitle={searchTitle ? searchTitle : undefined}
        search={search}
        filter={filter}
        bg="primary-light"
        arrow={arrow}
        active={activeTitle}
      />

      {(onNew || newUrl) && newLabel && (
        <NewButton onClick={onNew} url={newUrl}>
          {newLabel}
        </NewButton>
      )}
      <Scrollview autoHideScrollbar={autoHideScrollbar} wideScrollbarY>
        {!!loading ? (
          [...Array(5)].map((e, i) => (
            <ListItem key={i}>
              <Loader width={187} dynamicWidth={0.5} />
            </ListItem>
          ))
        ) : (
          <>
            {!itemsFirst && children}
            {items?.map(({ url, onClick, ...item }: ListItemProps, id: number) => {
              return type === 'complaint' ? (
                <ListItemComplaints key={id} onClick={onClick} url={url} {...item}>
                  {item.title}
                  <br />
                  {item.additionalLabel && (
                    <span className="listview-item-additional-label">{item.additionalLabel}</span>
                  )}
                </ListItemComplaints>
              ) : type === 'steelworks' ? (
                <ListItemSteelworks key={id} onClick={onClick} url={url} {...item}>
                  {item.title}
                  <br />
                  {item.additionalLabel && (
                    <span className="listview-item-additional-label">{item.additionalLabel}</span>
                  )}
                </ListItemSteelworks>
              ) : (
                <ListItem
                  type={type}
                  key={id}
                  onClick={
                    url
                      ? undefined
                      : (e) => {
                          onClick?.(e)
                          selectItem(item)
                        }
                  }
                  url={url}
                  {...item}
                >
                  {item.title}
                </ListItem>
              )
            })}
            {!!itemsFirst && children}
          </>
        )}
      </Scrollview>
    </div>
  )
}
