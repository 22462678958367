import { useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

type DebounceSettings = Exclude<Parameters<typeof debounce>[2], undefined>

export const useDebounce = <T>(value: T, wait?: number, options?: DebounceSettings): T => {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const mounted = useRef(true)

  const debouncedSetValue = useMemo(
    () =>
      debounce(
        (nextValue: T) => {
          if (mounted.current) {
            setDebouncedValue(nextValue)
          }
        },
        wait,
        options
      ),
    [wait, options]
  )
  useEffect(() => {
    debouncedSetValue(value)
  }, [debouncedSetValue, value])
  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  return debouncedValue
}
