import React from 'react'
import _ from 'lodash'
import { Dropdown, DropdownItem } from '../Overlays/Dropdown'
import { Icon } from '../Icon'
import FilterSvg from 'jsx:./../../assets/icons/filter.svg'

export type FilterDropdownProps = {
  items: FilterItemProps[]
}

export type FilterItemProps = {
  title: string
  onSelect: () => void
  active?: boolean
  disabled?: boolean
}

export const FilterDropdown: React.FC<FilterDropdownProps> = ({ items }) => {
  return (
    <div className="listview-filter">
      <Dropdown
        items={_.map(items, (item: FilterItemProps, index: number) => {
          return (
            <DropdownItem
              key={index}
              className={item.active ? 'active' : ''}
              onClick={item.onSelect}
              disabled={item.disabled}
            >
              {item.title}
            </DropdownItem>
          )
        })}
      >
        <button className="btn p-0 listview-col-toggle">
          <Icon svg={FilterSvg} />
        </button>
      </Dropdown>
    </div>
  )
}
