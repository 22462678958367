import { t } from '@lingui/macro'
import React, { useCallback, useRef } from 'react'
import { Form } from 'react-bootstrap'
import { Typeahead, TypeaheadModel } from 'react-bootstrap-typeahead'
import { CustomElement, FieldValues, UseFormRegisterReturn } from 'react-hook-form'

export type TypeaheadInputProps = {
  register?: (ref: (HTMLInputElement & CustomElement<FieldValues>) | null) => void
  controlId: string
  name: string
  label: string
  labelSrOnly?: boolean
  type?: string
  size?: 'sm' | 'lg' | undefined
  placeholder?: string
  groupClass?: string
  labelClass?: string
  inputClass?: string
  error?: string
  readOnly?: boolean
  required?: boolean
  onChange?: (nextValue: string) => void
  onBlur?: UseFormRegisterReturn['onBlur']
  options?: string[]
}

const emptyOptions = [] as string[]

export const TypeaheadInput = React.forwardRef<HTMLInputElement, TypeaheadInputProps>(function TypeaheadInput(
  {
    controlId,
    name,
    label,
    labelSrOnly,
    type = 'text',
    size = 'lg',
    placeholder,
    groupClass,
    labelClass,
    inputClass,
    error,
    readOnly,
    required,
    onChange,
    onBlur,
    options
  },
  ref
) {
  const inputRef = useRef<HTMLInputElement>()

  const typeaheadRef = useCallback(
    (x: Typeahead<TypeaheadModel> | null) => {
      const input = x?.getInput()
      if (input) {
        inputRef.current = input
        if (typeof ref === 'function') {
          ref(input)
        } else if (ref) {
          ref.current = input
        }
      }
    },
    [ref]
  )

  const handleChange = useCallback(() => {
    onChange?.(inputRef.current?.value ?? '')
  }, [onChange])

  return (
    <Form.Group controlId={controlId} className={groupClass}>
      <Form.Label srOnly={labelSrOnly} className={labelClass}>
        {label}
      </Form.Label>
      <Typeahead
        options={options ?? emptyOptions}
        inputProps={{
          name,
          type,
          placeholder,
          className: inputClass,
          readOnly,
          required
        }}
        id={name}
        size={size}
        isInvalid={!!error}
        ref={typeaheadRef}
        allowNew
        // @ts-ignore
        newSelectionPrefix={t`Neuer Zeugniscode: `}
        onInputChange={onChange}
        onChange={handleChange}
        className={error ? 'is-invalid' : ''}
        onBlur={onBlur}
      />
      {!!error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  )
})
