import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { GroupApprovalStatus } from './Status'

export type ProductDataMinimal = {
  id: number
  sku: string
  localizedTitle: string
  title: LocalizedTitles
  status: GroupApprovalStatus
}

export type ProductData = ProductDataMinimal

export const useProduct = createApiItemHook<ProductData>('/products')

export type UseProductsParams = {
  search?: string | null
  filter?: string | null
  orderId?: string | number
  certificateCodeId?: number
}

export const useProducts = createApiDataHook<ProductDataMinimal[], UseProductsParams>(
  ({ orderId, certificateCodeId, ...params }) => [
    orderId
      ? `/orders/${orderId}/products`
      : certificateCodeId
      ? `/certificatecodes/${certificateCodeId}/products`
      : '/products',
    params
  ]
)
