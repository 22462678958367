import { t } from '@lingui/macro'
import React, { useEffect, useMemo } from 'react'
import { Alert, Button, Col, Form } from 'react-bootstrap'
import { Validate, useForm } from 'react-hook-form'
import { LanguageData, useLanguages } from '../../data/remote/Language'
import { useSupplier } from '../../data/remote/Supplier'
import { Permission, UserDataInput, usePermissions, useUser } from '../../data/remote/User'
import { Input, Select } from '../Form'
import { optionalPasswordSecurity, passwordRepeat } from '../Form/validators'

export type EditProfileFormProps = {
  profileId?: number
}

export const EditProfileForm: React.FC<EditProfileFormProps> = ({ profileId }) => {
  const { data: languages } = useLanguages()
  const { user: userData, updateData: updateUserData } = useUser()
  const { actionAllowed } = usePermissions()

  const allowEditSupplierData = actionAllowed(Permission.Alle_Lieferanten_verwalten)
  const { data: supplierData, updateData: updateSupplierData } = useSupplier(
    allowEditSupplierData ? profileId : undefined
  )

  const data = supplierData ?? userData
  const updateData = supplierData ? updateSupplierData : updateUserData
  const isSupplierProfile = data?.isSupplier

  const {
    reset,
    register,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm<UserDataInput>({
    defaultValues: data
  })

  useEffect(() => {
    reset(data)
  }, [data, reset])

  const onSubmit = useMemo(() => handleSubmit(updateData), [handleSubmit, updateData])

  return (
    <Form onSubmit={onSubmit} noValidate>
      {isSupplierProfile && (
        <div className="form-row">
          <Col xs={12}>
            <h2>{t`Lieferanteninformationen`}</h2>
          </Col>
          <Col md={3}>
            <Input
              {...register(
                'company',
                allowEditSupplierData ? { required: t`Bitte den Lieferantenname eingeben.` } : {}
              )}
              groupClass="form-group"
              controlId="profile-company"
              label={t`Lieferantenname`}
              readOnly={!allowEditSupplierData}
              required={allowEditSupplierData}
              error={errors.company?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register(
                'supplierCode',
                allowEditSupplierData
                  ? {
                      pattern: {
                        value: /^[a-z0-9\.]+$/i,
                        message: t`Hier sind nur Zahlen, Buchstaben und Punkte erlaubt.`
                      }
                    }
                  : {}
              )}
              groupClass="form-group"
              controlId="profile-supplier-code"
              label={t`Lieferantenkennziffer`}
              readOnly={!allowEditSupplierData}
              placeholder={t`Automatisch generieren`}
              error={errors.supplierCode?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register(
                'supplierNumber',
                allowEditSupplierData ? { required: t`Bitte die Lieferantennummer eingeben.` } : {}
              )}
              groupClass="form-group"
              controlId="profile-supplier-number"
              label={t`Lieferantennummer`}
              readOnly={!allowEditSupplierData}
              required={allowEditSupplierData}
              error={errors.supplierNumber?.message}
            />
          </Col>
        </div>
      )}

      <div className={isSupplierProfile ? 'mt-3 form-row' : 'form-row'}>
        <Col xs={12}>
          <h2>{t`Anmeldedaten`}</h2>
        </Col>
        <Col md={3}>
          <Input
            {...register('username', { required: t`Bitte einen Benutzernamen eingeben.` })}
            controlId="profile-username"
            groupClass="form-group"
            label={t`Benutzername`}
            required
            error={errors.username?.message}
          />
        </Col>
        <Col md={3}>
          <Input
            {...register('password', {
              validate: {
                optionalPasswordSecurity
              } as unknown as Validate<string | undefined, UserDataInput>
            })}
            controlId="profile-password"
            groupClass="form-group"
            type="password"
            label={t`Neues Passwort`}
            error={errors.password?.message}
          />
        </Col>
        <Col md={3}>
          <Input
            {...register('passwordRepeat', {
              validate: passwordRepeat(() => getValues().password) as Validate<string | undefined, UserDataInput>
            })}
            controlId="profile-password-repeat"
            groupClass="form-group"
            type="password"
            label={t`Neues Passwort wiederholen`}
            error={errors.passwordRepeat?.message}
          />
        </Col>
      </div>
      {isSupplierProfile && (
        <div className="mt-3 form-row">
          <Col xs={12}>
            <h2>{t`Firmenanschrift`}</h2>
          </Col>
          <Col md={3}>
            <Input
              {...register(
                'address',
                isSupplierProfile ? {} : { required: t`Bitte die Straße und Hausnummer eingeben.` }
              )}
              controlId="profile-address"
              groupClass="form-group"
              label={t`Straße / Hausnummer`}
              required={!isSupplierProfile}
              error={errors.address?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register('zip', isSupplierProfile ? {} : { required: t`Bitte die Postleitzahl eingeben.` })}
              controlId="profile-zip"
              groupClass="form-group"
              label={t`Postleitzahl`}
              required={!isSupplierProfile}
              error={errors.zip?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register('city', isSupplierProfile ? {} : { required: t`Bitte den Ort eingeben.` })}
              controlId="profile-city"
              groupClass="form-group"
              label={t`Ort`}
              required={!isSupplierProfile}
              error={errors.city?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register('country', isSupplierProfile ? {} : { required: t`Bitte das Land eingeben.` })}
              controlId="profile-country"
              groupClass="form-group"
              label={t`Land`}
              required={!isSupplierProfile}
              error={errors.country?.message}
            />
          </Col>
        </div>
      )}
      <div className="mt-3 form-row">
        <Col xs={12}>
          <h2>{isSupplierProfile ? t`Ansprechpartner Zeugnisse` : t`Persönliche Daten`}</h2>
        </Col>
        <Col md={3}>
          <Input
            {...register('lastName', isSupplierProfile ? {} : { required: t`Bitte den Namen eingeben.` })}
            controlId="profile-lastName"
            groupClass="form-group"
            label={t`Name`}
            required={!isSupplierProfile}
            error={errors.lastName?.message}
          />
        </Col>
        <Col md={3}>
          <Input
            {...register('firstName', isSupplierProfile ? {} : { required: t`Bitte den Vornamen eingeben.` })}
            controlId="profile-firstName"
            groupClass="form-group"
            label={t`Vorname`}
            required={!isSupplierProfile}
            error={errors.firstName?.message}
          />
        </Col>
        <Col md={3}>
          <Input
            {...register('email', isSupplierProfile ? {} : { required: t`Bitte eine E-Mail-Adresse eingeben.` })}
            controlId="profile-email"
            groupClass="form-group"
            type="email"
            label={t`E-Mail-Adresse`}
            required={!isSupplierProfile}
            error={errors.email?.message}
          />
        </Col>
        <Col md={3}>
          <Input
            {...register('telephone', isSupplierProfile ? {} : { required: t`Bitte eine Telefonnummer eingeben.` })}
            controlId="profile-telephone"
            groupClass="form-group"
            type="tel"
            label={t`Telefonnummer`}
            required={!isSupplierProfile}
            error={errors.telephone?.message}
          />
        </Col>
      </div>
      {isSupplierProfile && (
        <div className="mt-3 form-row">
          <Col xs={12}>
            <h2>{t`Ansprechpartner Reklamationen`}</h2>
          </Col>
          <Col md={3}>
            <Input
              {...register('altLastName', isSupplierProfile ? {} : { required: t`Bitte den Namen eingeben.` })}
              controlId="profile-alt-lastName"
              groupClass="form-group"
              label={t`Name`}
              required={!isSupplierProfile}
              error={errors.lastName?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register('altFirstName', isSupplierProfile ? {} : { required: t`Bitte den Vornamen eingeben.` })}
              controlId="profile-alt-firstName"
              groupClass="form-group"
              label={t`Vorname`}
              required={!isSupplierProfile}
              error={errors.firstName?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register('altEmail', isSupplierProfile ? {} : { required: t`Bitte eine E-Mail-Adresse eingeben.` })}
              controlId="profile-alt-email"
              groupClass="form-group"
              type="email"
              label={t`E-Mail-Adresse`}
              required={!isSupplierProfile}
              error={errors.email?.message}
            />
          </Col>
          <Col md={3}>
            <Input
              {...register(
                'altTelephone',
                isSupplierProfile ? {} : { required: t`Bitte eine Telefonnummer eingeben.` }
              )}
              controlId="profile-alt-telephone"
              groupClass="form-group"
              type="tel"
              label={t`Telefonnummer`}
              required={!isSupplierProfile}
              error={errors.telephone?.message}
            />
          </Col>
        </div>
      )}
      {!isSupplierProfile && userData?.tempEmail && (
        <div className="form-row">
          <Col>
            <Alert variant="warning">
              <div className="d-flex flex-row align-items-center">
                <div className="flex-grow-1 flex-shrink-1">
                  <Alert.Heading>{t`Email bestätigen`}</Alert.Heading>
                  {t`Die neue Email-Adresse "${userData.tempEmail}" wurde noch nicht bestätigt.`}
                </div>
                <Button variant="outline-warning">{t`Bestätigungs-Email erneut senden`}</Button>
              </div>
            </Alert>
          </Col>
        </div>
      )}
      {languages && (
        <div className="mt-3 form-row">
          <Col xs={12}>
            <h2>{t`System`}</h2>
          </Col>
          <Col md={3}>
            <Select
              {...register('language', { required: t`Bitte eine Sprache auswählen.` })}
              controlId="profile-language"
              label={t`Sprache`}
              required
              error={errors.language?.message}
            >
              {languages.map((language: LanguageData) => {
                return (
                  <option key={language.id} value={language.id}>
                    {language.navigationTitle}
                  </option>
                )
              })}
            </Select>
          </Col>
        </div>
      )}
      <div className="form-row mt-1">
        <Col md={6} className="ml-auto">
          <div className="d-grid gap-2">
            <Button className="w-100" variant="primary" type="submit" size="lg" disabled={false}>
              {t`Speichern`}
            </Button>
          </div>
        </Col>
      </div>
    </Form>
  )
}
