import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { MainContainer } from '../../../components/Layout/MainContainer'

export type LoginLayoutProps = {
  boxContent: React.ReactElement
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ boxContent }) => {
  return (
    <MainContainer className="main-login">
      <Row id="login-container" className="h-100 overflow-auto no-gutters">
        <Col md={6} className="d-none d-md-block"></Col>
        <Col md={6} className="d-flex h-100 overflow-auto align-items-start">
          <div className="login-box">{boxContent}</div>
        </Col>
      </Row>
    </MainContainer>
  )
}

export default LoginLayout
