import { t } from '@lingui/macro'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { TrackedAxiosRequest, useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { usePermission } from './User'

export type CommentDataMinimal = {
  id: number
  author: string
  content: string
  /** UTC-Datestring */
  crdate: string
}

export type CommentData = CommentDataMinimal

export const useComment = createApiItemHook<CommentData>('/comments')

export type CommentTarget = {
  type: 'internalfiles' | 'certificates' | 'complaintfiles'
  id: number
}

export type UseCommentsParams = {
  search?: string | null
  for?: CommentTarget
}

export const useCanReadComments = (): boolean => usePermission('Kommentare_schreiben')
export const useCanWriteComments = (): boolean => usePermission('Kommentare_schreiben')

export const useComments = createApiDataHook<CommentDataMinimal[], UseCommentsParams>(
  ({ for: forObject, ...params }) => [forObject ? `/${forObject.type}/${forObject.id}/comments` : '/comments', params]
)

export type CreateCommentInput = { for: CommentTarget; content: string }
export const useCreateComment = (): TrackedAxiosRequest<
  [data: CreateCommentInput, onSuccess?: (data: CommentDataMinimal) => unknown]
> => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: CreateCommentInput, onSuccess?: (data: CommentDataMinimal) => unknown],
    CommentDataMinimal
  >(
    () => ({
      createRequestData: ({ for: target, ...data }) => {
        return [
          `/${target.type}/${target.id}/comments`,
          prefixApiParams<Omit<CreateCommentInput, 'for'>>(data),
          { method: 'POST' }
        ]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/comments') || path.includes(`/${inputData.for.type}`)
        })
        onSuccess?.(response.data.data)
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}
