import React, { useMemo } from 'react'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import parse from 'html-react-parser'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { Tooltip } from '../Overlays/Tooltip'
import { Submenu } from '../../views/LoggedIn/Submenu/Submenu'
import { RouteData, useRoutes } from '../../data/remote/RouteData'

type MenuItemProps = {
  item: RouteData
  active?: boolean
}

const MenuItem: React.FC<MenuItemProps> = ({ item, active = false }) => (
  <Tooltip text={item.title} placement="right">
    <li>
      <NavLink
        to={item.href}
        className={active ? 'active' : ''}
        {...(item.target ? { rel: 'noopener', target: item.target } : {})}
      >
        {item.icon && parse(item.icon)}
        <span className="visually-hidden">{item.title}</span>
      </NavLink>
    </li>
  </Tooltip>
)

export const MainMenu: React.FC = () => {
  const { routes } = useRoutes()

  const primaryRoutes = useMemo(() => {
    return _.filter(routes.main, (item) => {
      return item.component !== 'Information' && !item.hidden
    })
  }, [routes.main])

  const secondaryRoutes = useMemo(() => {
    return _.filter(routes.main, (item) => {
      return item.component === 'Information' && !item.hidden
    })
  }, [routes.main])

  if (!primaryRoutes && !secondaryRoutes) {
    return null
  }

  return (
    <>
      <div id="main-menu">
        <Scrollbars
          renderTrackHorizontal={() => <div style={{ display: 'none' }}></div>}
          renderTrackVertical={() => <div style={{ display: 'none' }}></div>}
          renderView={(props) => <div {...props} className="scroll-content" />}
        >
          {primaryRoutes && (
            <ul>
              {primaryRoutes.map((item, index) => {
                return <MenuItem key={index} item={item} />
              })}
            </ul>
          )}
          {secondaryRoutes && (
            <ul className="main-menu-secondary">
              {secondaryRoutes.map((item, index) => {
                return <MenuItem key={index} item={item} />
              })}
            </ul>
          )}
        </Scrollbars>
      </div>
      <Submenu />
    </>
  )
}
