import _ from 'lodash'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { useApiData } from '../../scripts/api'
import routeParams, { RouteName } from '../../views/routeParams'

export type RouteType = 'main' | 'account' | 'any'

export type RouteData = {
  id: number
  title: string
  icon?: string
  target: string
  component: RouteName
  href: string
  hreflang: {
    [lang: string]: string
  }
  path: string
  pathlang: {
    [lang: string]: string
  }
  children?: RouteData[]
  hidden: boolean
}

type ApiRouteData = Omit<RouteData, 'path' | 'pathlang' | 'children'> & {
  children?: ApiRouteData[]
}

const addPath = (route: ApiRouteData): RouteData => {
  const navigationParameterString = routeParams[route.component as RouteName]?.navigationParameterString ?? ''
  return {
    ...route,
    path: route.href + navigationParameterString,
    pathlang: _.mapValues(route.hreflang, (x) => x + navigationParameterString),
    children: route.children?.map(addPath)
  }
}

export const usePathlangs = (): Pick<RouteData, 'hreflang' | 'pathlang' | 'id'>[] | undefined => {
  const { data } = useApiData<
    {
      [key in RouteType]: ApiRouteData[]
    },
    never
  >('/de/routes')

  return useMemo(
    () =>
      data?.main
        ?.map(addPath)
        .concat(data?.account?.map(addPath))
        ?.map(
          (x): Pick<RouteData, 'hreflang' | 'pathlang' | 'id'> => ({
            id: x.id,
            hreflang: x.hreflang,
            pathlang: x.pathlang
          })
        ),
    [data?.account, data?.main]
  )
}

export const useRoutes = (): {
  loading: boolean
  routes: {
    [key in RouteType]: RouteData[]
  }
} => {
  const { data, loading } = useApiData<
    {
      [key in RouteType]: ApiRouteData[]
    },
    never
  >('/routes')

  return {
    loading,
    routes: useMemo(() => {
      const main = data?.main?.map(addPath) ?? []
      const account = data?.account?.map(addPath) ?? []
      return {
        main,
        account,
        any: main.concat(account)
      }
    }, [data?.account, data?.main])
  }
}

export const useRouteById = (id: number | string | undefined): RouteData | undefined => {
  const { routes } = useRoutes()
  if (typeof id === 'undefined') {
    return undefined
  }

  return routes.any.find((x) => x.id === Number(id))
}

export const useRouteByPath = (path: string): RouteData | undefined => {
  return useRoutes().routes.any.find((route) => {
    return !!matchPath(path, {
      path: route.path
    })
  })
}
export const useCurrentRoute = (): RouteData | undefined => useRouteByPath(useLocation().pathname)
