import { t } from '@lingui/macro'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'

import { useComplaints } from '../../../../data/remote/Complaint'
import { OrderDataMinimal } from '../../../../data/remote/Order'
import { ProductDataMinimal } from '../../../../data/remote/Product'
import { SetParamsAction } from '../../../../navigation/createRoute'
import { ComplaintData } from '../../../../types/ComplaintDataTypes'

export const useComplaintData = ({
  setParams,
  generatePath,
  orderData,
  productsData,
  complaintId
}: {
  setParams: (action: SetParamsAction<'Complaints'>, clearOptionals?: boolean | undefined) => void
  generatePath: (action: SetParamsAction<'Complaints'>, clearOptionals?: boolean | undefined) => string
  orderData: ColumnData<OrderDataMinimal>
  productsData: ColumnData<ProductDataMinimal>
  complaintId: string | undefined
}): ColumnData<ComplaintData> =>
  useColumnData<ComplaintData>(
    () => ({
      useItems(search, filter) {
        return useComplaints(
          orderData.current && productsData.current
            ? {
                search,
                filter,
                orderId: orderData.current.id,
                productId: productsData.current.id
              }
            : undefined,
          !!orderData.current && !!productsData.current
        )
      },
      isActiveItem(item) {
        return item.id.toString() === complaintId
      },
      shouldHaveActiveItem: !!orderData.current && !!productsData.current && !!complaintId,
      onActiveItemMiss() {
        setParams({
          orderId: orderData.current?.identifier,
          productId: productsData.current?.id,
          complaintId: undefined
        })
      },
      filterOptions: {
        ['']: t`Alle Reklamationen`,
        complaintStatus3: t`Offene Reklamationen`,
        complaintStatus2: t`Mit beantworteten Reklamationen`,
        complaintStatus1: t`Freigegebene Reklamationen`
      },
      renderItem: (complaint) => ({
        id: complaint.id,
        title: complaint.caq,
        url: generatePath({
          orderId: orderData.current?.identifier,
          productId: productsData.current?.id,
          complaintId: complaint.id
        }),
        status: complaint.status
      })
    }),
    [orderData, productsData, complaintId, setParams, generatePath]
  )
