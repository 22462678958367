import { t } from '@lingui/macro'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DotsMenu from '../../../../components/DotsMenu'
import { useUpdateComplaintFileStatus, useUpdateComplaintFileVisibility } from '../../../../data/remote/Complaint'
import { useDeleteComplaintFile, useUploadComplaintFile } from '../../../../data/remote/FileData'
import { ComplaintFileData } from '../../../../types/ComplaintDataTypes'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'

const ComplaintFilesMenu: React.FC<{
  data?: ComplaintFileData
  complaintFileType: string
  complaintId: number
}> = ({ data, complaintId, complaintFileType }) => {
  const { run: updateComplaintFileVisibility, running: updatingVisibility } = useUpdateComplaintFileVisibility()
  const { run: updateComplaintFileStatus, running: updatingStatus } = useUpdateComplaintFileStatus()

  const [show, setShow] = useState(false)

  const handleVisibilityChange = (visible: boolean) => {
    if (!data || updatingVisibility || !complaintId || !data?.id) return
    updateComplaintFileVisibility({
      complaintId,
      complaintFileId: data?.id,
      visibility: visible ? 1 : 0
    })
  }

  const [uploadComplaintFile, canUploadComplaintFiles] = useUploadComplaintFile({
    type: 'complaintfile',
    complaintId,
    complaintFileType
  })

  const [deleteComplaintFile, canDeleteComplaintFiles] = useDeleteComplaintFile(complaintId, data?.id)

  const [uploadComplaintAnswerFile, canUploadComplaintAnswerFiles] = useUploadComplaintFile({
    type: 'complaintfile_answer',
    complaintId,
    complaintFileId: data?.id,
    complaintFileType
  })

  const handleOpenFile = () => {
    if (!data || !data.file) return
    data.file.publicUrl && window.open(data.file.publicUrl, '_blank')
  }

  const handleUploadFile = () => {
    // open file upload window
    uploadComplaintFile()
  }

  const handleDeleteFile = () => {
    // delete file
    deleteComplaintFile()
  }

  const handleUploadAnswerFile = (willReplace: boolean) => {
    // open file upload window
    if (willReplace) {
      setShow(true)
    } else {
      uploadComplaintAnswerFile()
    }
  }

  const handleApproval = (status: number) => {
    // set current file status to status (1 = accepted, 2 = inProgress, 3 = declined)
    if (!data || updatingStatus || !complaintId || !data?.id) return
    updateComplaintFileStatus({
      complaintId,
      complaintFileId: data?.id,
      status
    })
  }

  return (
    <>
      <DotsMenu>
        {canUploadComplaintFiles ? (
          data?.id ? (
            <>
              <DropdownItem
                key="accept"
                onClick={() => {
                  handleApproval(1)
                }}
                disabled={data?.status === 1 ? true : false}
              >
                {t`Datei freigeben`}
              </DropdownItem>
              <DropdownItem
                key="decline"
                onClick={() => handleApproval(3)}
                disabled={data?.status === 3 ? true : false}
              >{t`Datei ablehnen`}</DropdownItem>
              {canDeleteComplaintFiles && (
                <DropdownItem key="upload" onClick={handleDeleteFile}>{t`Datei löschen`}</DropdownItem>
              )}
              <DropdownItem onClick={handleOpenFile} key="download">
                {t`Datei herunterladen`} ({data?.file?.properties.size})
              </DropdownItem>
              <DropdownItem key="answer" onClick={() => handleUploadAnswerFile(!!data?.fileAnswer)}>
                {data?.fileAnswer ? t`Antwort aktualisieren` : t`Antwort hochladen`}
              </DropdownItem>
              <DropdownItem href={''} key="visibility" onClick={() => handleVisibilityChange(!data?.visibility)}>
                {data?.visibility ? t`Sichtbarkeit auf 'intern' ändern` : t`Sichtbarkeit auf extern ändern`}
              </DropdownItem>
            </>
          ) : (
            <DropdownItem key="upload" onClick={handleUploadFile}>{t`Datei hochladen`}</DropdownItem>
          )
        ) : canUploadComplaintAnswerFiles ? (
          <>
            <DropdownItem key="download" onClick={handleOpenFile}>
              {t`Datei herunterladen`} ({data?.file?.properties.size})
            </DropdownItem>

            <DropdownItem key="answer" onClick={() => handleUploadAnswerFile(!!data?.fileAnswer)}>
              {data?.fileAnswer ? t`Antwort aktualisieren` : t`Antwort hochladen`}
            </DropdownItem>
          </>
        ) : (
          <DropdownItem key="download" onClick={handleOpenFile}>
            {t`Datei herunterladen`} ({data?.file?.properties.size})
          </DropdownItem>
        )}
      </DotsMenu>
      <Modal show={!!show}>
        <Modal.Header closeButton onClick={() => setShow(false)}>
          <Modal.Title>{t`Antwort aktualisieren`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t`Möchten Sie die Datei`}
            <strong> {data?.fileAnswer?.properties.title} </strong>
            {t`wirklich ersetzen?`}
          </p>
          <p>
            {t`Die alte Datei wird gelöscht und kann nicht wiederhergestellt werden. Sie können die Datei jedoch erneut hochladen.`}
          </p>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary"
              onClick={() => {
                setShow(false)
                uploadComplaintAnswerFile()
              }}
            >
              {t`Datei ersetzen`}
            </button>
            <button className="btn btn-outline-primary ml-2" onClick={() => setShow(false)}>
              {t`Abbrechen`}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ComplaintFilesMenu
