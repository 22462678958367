import React from 'react'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { configureStore } from '@reduxjs/toolkit'
import swrRequests from './modules/swrRequests'
import { AppState } from './types'
import uploads, { initUploads } from './modules/uploads'

type ReduxProviderProps = {
  children: React.ReactNode
}

const store = configureStore<AppState>({
  reducer: {
    swrRequests: swrRequests.reducer,
    uploads: uploads.reducer
  },
  middleware: [thunkMiddleware]
})

store.dispatch(initUploads())

export type AppDispatch = typeof store.dispatch

export const ReduxProvider: React.FC<ReduxProviderProps> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>
}
