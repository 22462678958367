import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SWRRequestsState } from '../types/swrTypes'

const initialState: SWRRequestsState = []

const openKey = (
  state: SWRRequestsState,
  { payload }: PayloadAction<{ path: string | null; requestData?: string }>
) => {
  if (!payload?.path) {
    return
  }
  const key = state.find((x) => x.path === payload.path && x.requestData === payload.requestData)
  if (!key) {
    state.push({
      path: payload.path,
      requestData: payload.requestData,
      count: 1
    })
  } else {
    key.count++
  }
}

const closeKey = (
  state: SWRRequestsState,
  { payload }: PayloadAction<{ path: string | null; requestData?: string }>
) => {
  if (!payload || typeof payload === 'function') {
    return
  }
  const index = state.findIndex((x) => x.path === payload.path && x.requestData === payload.requestData)
  if (index >= 0) {
    state[index].count--
    if (state[index].count < 1) {
      state.splice(index, 1)
    }
  }
}

const swrRequests = createSlice({
  name: 'swrRequests',
  initialState,
  reducers: {
    openKey,
    closeKey
  }
})

export default swrRequests
