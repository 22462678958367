import React, { useEffect, useRef, useState } from 'react'
import { Header } from '../../../components/ListView/Header'
import { Icon } from '../../../components/Icon'
import Pencil from 'jsx:./../../../assets/icons/pencil.svg'
import MenuClose from 'jsx:./../../../assets/icons/menu-close.svg'
import Check from 'jsx:./../../../assets/icons/check.svg'
import { Scrollview } from '../../../components/Layout/Scrollview'
import { t } from '@lingui/macro'
import { Button, Modal, Spinner } from 'react-bootstrap'
import {
  SteelCertificatesData,
  useUpdateSteelCertificateInput,
  useUpdateSteelCertificateStatus
} from '../../../data/remote/SteelCertificates'
import { Tooltip } from '../../../components/Overlays/Tooltip'

type SteelworksTableProps = {
  steelCertificate: SteelCertificatesData
}

const SteelworksTable: React.FC<SteelworksTableProps> = ({ steelCertificate }) => {
  type EditValues = {
    [key: string]: string
  }

  const dataChemical = steelCertificate.properties.chemical
  const dataMechanical = steelCertificate.properties.mechanical

  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editValues, setEditValues] = useState<EditValues>({})
  const [showModal, setShowModal] = useState(false)

  const updateSteelCertificateInput = useUpdateSteelCertificateInput()
  const updateSteelCertificateStatus = useUpdateSteelCertificateStatus()

  const hasIssues = () => {
    const anyIssues =
      Object.values(dataChemical).some((row) => row.issue) || Object.values(dataMechanical).some((row) => row.issue)

    return anyIssues
  }

  // Fügen Sie diesen Seiteneffekt hinzu, um den Editiermodus zu beenden, wenn die Komponente neu gerendert wird.
  useEffect(() => {
    return () => setEditMode(false)
  }, [steelCertificate])

  const handleEdit = () => {
    const istValues: EditValues = {}

    Object.keys(dataChemical).forEach((key) => {
      istValues[key] = dataChemical[key].value !== null ? String(dataChemical[key].value) : ''
    })
    Object.keys(dataMechanical).forEach((key) => {
      istValues[key] = dataMechanical[key].value !== null ? String(dataMechanical[key].value) : ''
    })
    setEditValues(istValues)
    setEditMode(true)
  }

  const handleCancel = () => {
    setEditMode(false)
  }

  const handleCertificateApproval = () => {
    updateSteelCertificateStatus.run({
      steelCertificateId: steelCertificate.id,
      approval: 'approved'
    })
    setShowModal(false)
  }

  const handleCertificatePending = () => {
    updateSteelCertificateStatus.run({
      steelCertificateId: steelCertificate.id,
      approval: 'pending'
    })
  }

  const isValidNumberInput = (input: string) => {
    // This regular expression allows negative numbers, positive numbers, and numbers with a single decimal point.
    const validNumberRegex = /^-?\d*\.?\d*$/
    return validNumberRegex.test(input)
  }

  const handleConfirm = () => {
    const updatedDataChemical: SteelCertificatesData['properties']['chemical'] = { ...dataChemical }
    const updatedDataMechanical: SteelCertificatesData['properties']['mechanical'] = { ...dataMechanical }
    const sendingDataChemical: { [key: string]: number | string } = {}
    const sendingDataMechanical: { [key: string]: number | string } = {}

    Object.keys(dataChemical).forEach((key) => {
      const editValue = editValues[key] !== '' ? parseFloat(formatValueForInput(editValues[key])) : ''
      updatedDataChemical[key] = {
        ...dataChemical[key],
        value: editValue !== '' && isNaN(editValue) ? dataChemical[key].value : formatValueForInput(editValue)
      }
      sendingDataChemical[key] =
        editValue !== '' && isNaN(editValue) ? dataChemical[key].value || '' : formatValueForInput(editValue)
    })

    Object.keys(dataMechanical).forEach((key) => {
      const editValue = editValues[key] !== '' ? parseFloat(formatValueForInput(editValues[key])) : ''

      updatedDataMechanical[key] = {
        ...dataMechanical[key],
        value: editValue !== '' && isNaN(editValue) ? dataMechanical[key].value : editValue
      }
      sendingDataMechanical[key] = editValue !== '' && isNaN(editValue) ? dataMechanical[key].value || '' : editValue
    })

    setLoading(true)

    updateSteelCertificateInput.run({
      certificateId: steelCertificate.id,
      chemicalProperties: sendingDataChemical,
      mechanicalProperties: sendingDataMechanical
    })
  }

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const formatValue = (value: string | number | null | undefined) => {
    // replace decimal point with comma
    if (value !== null && value !== undefined) {
      return value.toString().replace(/\./g, ',')
    }
    return ''
  }

  const formatValueForInput = (value: string | number | null) => {
    // replace comma with decimal point
    if (value !== null) {
      return value.toString().replace(/,/g, '.')
    }
    return ''
  }

  const ModalRef = useRef(null)

  const ConfirmationModal = () => (
    <Modal ref={ModalRef} show={showModal} onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Achtung</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {t`Mindestens ein Wert weicht vom Soll-Wert ab.`}
        <br />
        {t`Möchten Sie das Zeugnis wirklich freigeben?`}
      </Modal.Body>
      <div className="pb-3 d-flex">
        <Button variant="secondary" onClick={() => setShowModal(false)} className="mr-auto" size="lg">
          {t`Abbrechen`}
        </Button>
        <Button variant="primary" onClick={handleCertificateApproval} className="ml-auto" size="lg">
          {t`Zeugnis freigeben`}
        </Button>
      </div>
    </Modal>
  )

  return (
    <>
      <div className="data-table-wrap steelworks-data-table-wrap">
        <Scrollview wideScrollbarY>
          <Header
            title="Chemische Zusammensetzung"
            toolbar={
              <>
                {loading && <Spinner size="sm" animation="border" />}
                {editMode && !loading ? (
                  <>
                    <button onClick={handleCancel} className="btn btn-link p-0">
                      <Icon svg={MenuClose} className="editIcon" />
                    </button>

                    <button onClick={handleConfirm} className="btn btn-link p-0">
                      <Icon svg={Check} className="editIcon" />
                    </button>
                  </>
                ) : !loading ? (
                  <Tooltip
                    text={
                      steelCertificate.status.approval !== 'pending'
                        ? t`Die Werte können nicht angepasst werden, da
                    das Zeugnis bereits freigegeben wurde.`
                        : t`Werte bearbeiten`
                    }
                  >
                    <div className="d-flex align-items-center justify-content-center h-100">
                      <button
                        onClick={steelCertificate.status.approval === 'pending' ? handleEdit : () => null}
                        className="btn btn-link p-0"
                        disabled={steelCertificate.status.approval !== 'pending'}
                      >
                        <Icon svg={Pencil} className="editIcon" />
                      </button>
                    </div>
                  </Tooltip>
                ) : null}
              </>
            }
          />
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={{ borderBottom: '1px solid #dadada' }}>
                <th style={{ width: '40%', padding: '1rem 1.5rem' }}>Wert</th>
                <th style={{ width: '30%' }}>Ist</th>
                <th style={{ width: '30%' }}>Soll</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Object.values(dataChemical).map((row) => (
                <tr
                  key={row.key}
                  style={{ borderBottom: '1px solid #dadada' }}
                  className={row.issue ? 'text-danger' : ''}
                >
                  <td style={{ padding: '1.5rem' }}>{row.label}</td>
                  <td>
                    {editMode ? (
                      <>
                        <input
                          value={formatValue(editValues[row.key])}
                          onChange={(e) => {
                            const newValue = e.target.value
                            if (/^-?\d*[.,]?\d*$/.test(newValue)) {
                              setEditValues({ ...editValues, [row.key]: newValue })
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              handleConfirm()
                            }
                          }}
                          pattern="[0-9.,]*"
                          className={
                            editValues[row.key] && !isValidNumberInput(editValues[row.key])
                              ? 'input-danger input-edit'
                              : 'input-edit'
                          }
                        />
                      </>
                    ) : (
                      formatValue(row.value)
                    )}
                  </td>
                  <td>
                    {row.min !== null && row.max !== null
                      ? formatValue(row.min) + ' - ' + formatValue(row.max)
                      : row.min !== null && row.max === null
                      ? '≥ ' + formatValue(row.min)
                      : row.min === null && row.max !== null
                      ? '≤ ' + formatValue(row.max)
                      : '*'}
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-5 pb-3">
            <Header title="Mechanische Kennwerte" />
            <table style={{ width: '100%' }}>
              <thead>
                <tr style={{ borderBottom: '1px solid #dadada' }}>
                  <th style={{ width: '40%', padding: '1rem 1.5rem' }}>Wert</th>
                  <th style={{ width: '30%' }}>Ist</th>
                  <th style={{ width: '30%' }}>Soll</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {Object.values(dataMechanical).map((row) => (
                  <tr
                    key={row.key}
                    style={{ borderBottom: '1px solid #dadada' }}
                    className={row.issue ? 'text-danger' : ''}
                  >
                    <td style={{ padding: '1.5rem' }}>{row.label}</td>
                    <td>
                      {editMode ? (
                        <>
                          <input
                            value={formatValue(editValues[row.key])}
                            onChange={(e) => {
                              const newValue = e.target.value
                              if (/^-?\d*[,.]?\d*$/.test(newValue)) {
                                setEditValues({ ...editValues, [row.key]: newValue })
                              }
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleConfirm()
                              }
                            }}
                            className={
                              editValues[row.key] && !isValidNumberInput(editValues[row.key])
                                ? 'input-danger input-edit'
                                : 'input-edit'
                            }
                          />
                        </>
                      ) : (
                        formatValue(row.value)
                      )}
                    </td>
                    <td>
                      {row.min !== null && row.max !== null
                        ? formatValue(row.min) + ' - ' + formatValue(row.max)
                        : row.min !== null && row.max === null
                        ? '≥ ' + formatValue(row.min)
                        : row.min === null && row.max !== null
                        ? '≤ ' + formatValue(row.max)
                        : '*'}
                    </td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Scrollview>
      </div>
      {steelCertificate.status.approval === 'pending' ? (
        <div className="p-1 bg-white d-flex flex-column align-items-center complaint__start">
          <Button
            size="lg"
            className="px-5"
            onClick={hasIssues() ? handleOpenModal : handleCertificateApproval}
            disabled={editMode}
          >
            {t`Zeugnis freigeben`}
          </Button>
        </div>
      ) : (
        <div className="p-1 bg-white d-flex flex-column align-items-center complaint__start">
          <Button size="lg" className="px-5" onClick={handleCertificatePending} disabled={editMode}>
            {t`Zeugnis bearbeiten`}
          </Button>
        </div>
      )}
      <ConfirmationModal />
    </>
  )
}

export default SteelworksTable
