import React, { useMemo } from 'react'

export type LoaderProps = {
  width?: number
  height?: number
  dynamicWidth?: number
  dynamicHeight?: number
}

export const Loader: React.FC<LoaderProps> = ({ width, height, dynamicWidth, dynamicHeight }) => {
  const loaderWidth = useMemo(() => {
    if (!dynamicWidth || !width) {
      return width
    }

    const min = Math.ceil(width * dynamicWidth)
    const max = Math.floor(width)

    return Math.floor(Math.random() * (max - min)) + min
  }, [width, dynamicWidth])

  const loaderHeight = useMemo(() => {
    if (!dynamicHeight || !height) {
      return height
    }

    const min = Math.ceil(height * dynamicHeight)
    const max = Math.floor(height)

    return Math.floor(Math.random() * (max - min)) + min
  }, [height, dynamicHeight])

  return (
    <div
      className="loader"
      style={{ width: loaderWidth && `${loaderWidth}px`, height: loaderHeight && `${loaderHeight}px` }}
    ></div>
  )
}
