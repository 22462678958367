import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LocalizedLink from '../../components/LocalizedLink'
import { t } from '@lingui/macro'

// TODO: Formular zum Freischalten eines Zugangs (Username/Passwort setzen)

// type ActivateInput = {
//   email: string
// }

export const Activate: React.FC = () => {
  return (
    <>
      <h1>{t`Account einrichten`}</h1>
      <p>
        {t`Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt. Legen Sie nun einen Benutzernamen und das Passwort fest.`}
      </p>
      <Row className="mt-3">
        <Col>
          <LocalizedLink to="/" className="btn btn-white btn-lg">
            {t`Zum Login`}
          </LocalizedLink>
        </Col>
      </Row>
    </>
  )
}
