import { t } from '@lingui/macro'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { FileData } from '../../types/FileDataTypes'
import { useRefreshSWR } from '../local/hooks/swrHooks'

export type InternalFileDataMinimal = {
  id: number
  file: FileData
  tstamp: string
  public: boolean
  /** Number of comments attached to this file */
  comments: number
}
export type InternalFileData = InternalFileDataMinimal

export const useInternalFile = createApiItemHook<InternalFileData>('/internalfiles')

export type UseInternalFilesParams = {
  search?: string | null
  codeId?: number
}

export const useInternalFiles = createApiDataHook<InternalFileDataMinimal[], UseInternalFilesParams>(
  ({ codeId, ...params }) => [codeId ? `/certificatecodes/${codeId}/internalfiles` : '/internalfiles', params]
)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useUpdateInternalFileVisibility = () => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [
      {
        id: number
        public: boolean
      }
    ],
    unknown
  >(
    () => ({
      createRequestData: ({ id, ...args }) => {
        return [`/internalfiles/${id}`, prefixApiParams<{ public: boolean }>(args), { method: 'PATCH' }]
      },
      thenFn: () => {
        refresh()
      },
      messages: {
        error: {
          title: t`Die Datei konnte nicht angepasst werden.`
        }
      }
    }),
    [refresh]
  )
}
