import { t } from '@lingui/macro'
import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap'
import { ChangeHandler, useForm } from 'react-hook-form'
import { generatePath, useHistory } from 'react-router'
import { Select } from '../../../components/Form'
import Modal, { ModalRef, useModalActions } from '../../../components/Overlays/Modal'
import { useLocalizedPath } from '../../../data/remote/Language'
import { CreateSteelCertificateInput, useCreateSteelCertificate } from '../../../data/remote/SteelCertificates'
import { useSteelProducts } from '../../../data/remote/SteelProducts'
import { SupplierDataMinimal } from '../../../data/remote/Supplier'
import useCombinedRef from '../../../scripts/useCombinedRef'
import { Permission, usePermissions } from '../../../data/remote/User'

export type AddSteelCertificateModalProps = {
  supplier: SupplierDataMinimal
  steelHeatingId: number
}

export const AddSteelCertificateModal = React.forwardRef<ModalRef, AddSteelCertificateModalProps>(
  function CreateSteelCertificateModal({ supplier, steelHeatingId }, ref) {
    const modalActions = useModalActions()
    const modalRef = useCombinedRef(modalActions.ref, ref)
    const createSteelCertificate = useCreateSteelCertificate()
    const { data: steelProducts } = useSteelProducts()

    const localizedPath = useLocalizedPath()
    const { reset, register, handleSubmit, setValue } = useForm<CreateSteelCertificateInput>()
    const navigation = useHistory()
    const { actionAllowed } = usePermissions()
    const wekGroup = actionAllowed(Permission.WEK_Protokolle_abfragen)

    const handleResetForm = useCallback(() => {
      modalActions.close()
      reset()
      setInitiated(false)
      setSelectedLSW('')
      setSelectedWidth('')
      setSelectedHeight('')
    }, [modalActions, reset])

    const onClose = () => {
      handleResetForm()
      modalActions.close()
    }

    const onSubmit = useMemo(
      () =>
        handleSubmit((data) => {
          if (!createSteelCertificate.running) {
            createSteelCertificate.run(
              {
                ...data,
                supplier: supplier.id.toString(),
                steelHeatingId
              },
              (data) => {
                const localizedPathString = localizedPath(wekGroup ? '/de/wek-protokolle' : '/de/stahlwerke')
                const supplierId = supplier.id.toString()
                const path = generatePath(localizedPathString + '/:supplierId/:steelHeatingId/:id', {
                  supplierId,
                  steelHeatingId,
                  id: data.id
                })
                modalActions.close()
                navigation.push(path)
                setTimeout(() => {
                  handleResetForm()
                }, 500)
              }
            )
          }
        }),
      [
        createSteelCertificate,
        handleResetForm,
        handleSubmit,
        localizedPath,
        modalActions,
        navigation,
        steelHeatingId,
        supplier.id,
        wekGroup
      ] // Don't forget to add resetForm to dependency array
    )

    const [selectedLSW, setSelectedLSW] = useState('')
    const [selectedWidth, setSelectedWidth] = useState('')
    const [selectedHeight, setSelectedHeight] = useState('')
    const [initiated, setInitiated] = useState(false)

    const filteredLSW = steelProducts?.filter((article) =>
      supplier.assignedLsw ? supplier.assignedLsw.includes(article.lsw.split('-')[0]) : steelProducts
    )

    const uniqueLSW = [...new Set(filteredLSW?.map((article) => article.lsw.split('-')[0]))]

    const handleLSWChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setSelectedLSW(e.target.value)
      setValue('lsw', e.target.value)
      setValue('width', '')
      setValue('height', '')
      setSelectedWidth('')
      setSelectedHeight('')
    }

    const handleWidthChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setSelectedWidth(e.target.value)
      setValue('width', e.target.value)
      setValue('height', '')
      setSelectedHeight('')
    }

    const handleHeightChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setSelectedHeight(e.target.value)
      setValue('height', e.target.value)
    }

    const filteredWidths = [
      ...new Set(
        steelProducts
          ?.filter((product) => product.lsw.startsWith(selectedLSW))
          .map((product) => product.width)
          .filter((width) =>
            steelProducts?.some(
              (article) => article.lsw.startsWith(selectedLSW) && article.width === width && article.height
            )
          )
      )
    ]

    const filteredHeights = [
      ...new Set(
        steelProducts
          ?.filter((product) => product.lsw.startsWith(selectedLSW) && product.width === parseInt(selectedWidth))
          .map((article) => article.height)
      )
    ]

    filteredWidths.sort((a, b) => a - b) // Sort widths in ascending order
    filteredHeights.sort((a, b) => a - b) // Sort heights in ascending order

    return (
      <Modal ref={modalRef} centered onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t`Abmessung hinzufügen`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="w-75 mx-auto">
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                if (!createSteelCertificate.running && selectedLSW && selectedWidth && selectedHeight && !initiated) {
                  setInitiated(true)
                  onSubmit()
                }
              }}
            >
              <Form.Group>
                <Row>
                  <Col xs={4}>
                    <Select
                      groupClass="form-group"
                      controlId="steelcertificate-lsw"
                      {...register('lsw')}
                      label={t`Artikel`}
                      required
                      onChange={handleLSWChange as ChangeHandler}
                      onBlur={handleLSWChange as ChangeHandler}
                    >
                      <option key={''} value={''}>
                        {' '}
                      </option>
                      {uniqueLSW.map((lsw) => (
                        <option key={lsw} value={lsw}>
                          {lsw}
                        </option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={4}>
                    <Select
                      groupClass="form-group"
                      controlId="width"
                      {...register('width')}
                      label={t`Breite (mm)`}
                      required
                      disabled={!selectedLSW}
                      onChange={handleWidthChange as ChangeHandler}
                      onBlur={handleWidthChange as ChangeHandler}
                    >
                      <option key={''} value={''}>
                        {' '}
                      </option>
                      {filteredWidths.map((width) => (
                        <option key={width} value={width}>
                          {width}
                        </option>
                      ))}
                    </Select>
                  </Col>
                  <Col xs={4}>
                    <Select
                      groupClass="form-group"
                      {...register('height', { required: t`Bitte eine Höhe angeben.` })}
                      controlId="height"
                      label={t`Höhe (mm)`}
                      required
                      disabled={!selectedWidth}
                      onChange={handleHeightChange as ChangeHandler}
                      onBlur={handleHeightChange as ChangeHandler}
                    >
                      <option key={''} value={''}>
                        {' '}
                      </option>
                      {filteredHeights.map((height) => (
                        <option key={height} value={height}>
                          {height}
                        </option>
                      ))}
                    </Select>
                  </Col>
                </Row>
              </Form.Group>
              <Button
                variant="primary"
                type="submit"
                size="lg"
                disabled={
                  createSteelCertificate.running || !selectedLSW || !selectedWidth || !selectedHeight || initiated
                }
                className="w-100 d-flex align-items-center justify-content-center"
              >
                {initiated && <Spinner className="mr-2" animation="border" size="sm" />}
                {initiated ? t`Abmessung wird hinzugefügt...` : t`Abmessung hinzufügen`}
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
)
