export type Typo3ContentLayout = 'default' | 'layout-1' | 'layout-2'
export const Typo3ContentLayouts = {
  Default: 'default',
  Hint: 'layout-1',
  Card: 'layout-2'
} as const
export const Typo3ContentLayoutClasses = Object.fromEntries(
  Object.entries(Typo3ContentLayouts).map(([a, b]) => [b, a.toLowerCase()])
) as { [Key in Typo3ContentLayout]?: string }
export type Typo3ContentSpace = '' | 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'
export const Typo3ContentSpaceClasses: { [key in Typo3ContentSpace]: string } = {
  '': '1',
  'extra-small': '2',
  small: '3',
  medium: '4',
  large: '5',
  'extra-large': '6'
}

type Typo3ContentBase<Type extends string, ContentType> = {
  type: Type
  id: number
  colPos: number
  content: ContentType
  appearance: {
    layout: Typo3ContentLayout
    spaceBefore: Typo3ContentSpace
    spaceAfter: Typo3ContentSpace
  }
}

export type Typo3TextContent = Typo3ContentBase<
  'text',
  {
    bodytext: string
    header: string
  }
>

export type Typo3ImageData = {
  publicUrl: string
  properties: {
    alternative?: string
    description?: string
    dimensions: { width: number; height: number }
    extension: string
    fileReferenceUid: number
    filename: string
    link?: string
    mimeType: string
    originalUrl: string
    size: string
    title: string
    type: 'image' | 'video'
    uidLocal: number
    previewImage?: string
  }
}

export type Typo3GalleryData = {
  border: {
    enabled: boolean
    width: number
    padding: number
  }
  columnSpacing: number
  count: {
    files: number
    columns: number
    rows: number
  }
  position: {
    horizontal: 'center' | 'right' | 'left'
    vertical: 'above' | 'below' | 'intext'
    noWrap: boolean
  }
  rows: Record<
    number,
    {
      columns: Record<number, Typo3ImageData>
    }
  >
  width: number
}

export type Typo3TextPicContent = Typo3ContentBase<
  'textpic' | 'textmedia',
  {
    bodytext: string
    header: string
    gallery: Typo3GalleryData
  }
>

export type Typo3ImageContent = Typo3ContentBase<
  'image',
  {
    header: string
    gallery: Typo3GalleryData
  }
>

export type PageContent = Typo3TextContent | Typo3TextPicContent | Typo3ImageContent
