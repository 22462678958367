import { t } from '@lingui/macro'
import { useMemo } from 'react'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { TrackedAxiosRequest, useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { ComplaintData, ComplaintFileData } from '../../types/ComplaintDataTypes'
import { Status } from '../../types/StatusTypes'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { usePermission } from './User'

export const useComplaint = createApiItemHook<ComplaintData>('/complaints')

export type UseComplaintsParams = {
  search?: string | null
  filter?: string | null
  orderId?: string | number
  productId?: string | number
}

export const useComplaints = createApiDataHook<ComplaintData[], UseComplaintsParams>(
  ({ orderId, productId, ...params }) => [
    orderId && productId
      ? `/orders/${orderId}/products/${productId}/complaints`
      : productId
      ? `/products/${productId}/complaints`
      : '/complaints',
    params
  ]
)

export type CreateComplaintInput = {
  readonly order: string
  readonly product: string
  readonly caq: string
  readonly description?: string
  readonly status: Status
}
export const useCanManageComplaints = (): boolean => usePermission('Alle_Reklamationen_verwalten')

export const useCreateComplaint = (): {
  run: (data: CreateComplaintInput, onSuccess?: (data: ComplaintData) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: CreateComplaintInput, onSuccess?: (data: ComplaintData) => unknown],
    ComplaintData
  >(
    () => ({
      createRequestData: ({ ...data }) => {
        return [`/complaints`, prefixApiParams<CreateComplaintInput>(data), { method: 'POST' }]
      },
      thenFn: () => {
        refresh(({ path }) => {
          return path.includes('/orders')
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}

export const useSetComplaintStatus = (
  caqId: number
): TrackedAxiosRequest<[{ id: number; status: Status; manualClosed?: boolean }]> & {
  setStatus: (status: number) => void
} => {
  const canManageComplaints = useCanManageComplaints()
  const refresh = useRefreshSWR()
  const { run, running, result, error, cancel } = useTrackedAxiosRequest<
    [data: { id: number; status: Status; manualClosed?: boolean }, onSuccess?: (data: ComplaintData) => unknown],
    unknown
  >(
    () => ({
      createRequestData(data) {
        return [
          `/complaints`,
          prefixApiParams<{ id: number; status: number; manualClosed?: boolean }>(data),
          { method: 'PATCH' }
        ]
      },
      thenFn: () => {
        refresh()
      },
      messages: {
        error: {
          title: t`Fehler beim Aktualisieren`
        },
        success: {
          title: t`Erfolgreich aktualisiert`
        }
      },
      actionAllowed: canManageComplaints
    }),
    [canManageComplaints, refresh]
  )

  return useMemo(
    () => ({
      run,
      running,
      setStatus: (status: number) => {
        run({ id: caqId, status, manualClosed: status === 1 ? true : undefined })
      },
      actionAllowed: canManageComplaints,
      result,
      error,
      cancel
    }),
    [canManageComplaints, cancel, caqId, error, result, run, running]
  )
}

export const useUpdateComplaintDescription = () => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: { id: number; description: string }, onSuccess?: (response?: ComplaintData) => void],
    void
  >(
    () => ({
      createRequestData: ({ id, description }) => {
        return [
          `/complaints`,
          prefixApiParams<{ id: number; description: string }>({
            id,
            description
          }),
          { method: 'PATCH' }
        ]
      },
      thenFn: () => {
        refresh()
      },
      messages: {
        success: {
          title: t`Erfolgreich aktualisiert`
        },
        error: {
          title: t`Fehler beim Aktualisieren`
        }
      }
    }),
    [refresh]
  )
}

export const useUpdateComplaintFileVisibility = () => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: { complaintId: number; complaintFileId: number; visibility: number }, onSuccess?: () => unknown],
    void
  >(
    () => ({
      createRequestData: ({ complaintId, complaintFileId, visibility }) => {
        return [
          `/complaints/${complaintId}/complaintfiles/${complaintFileId}`,

          prefixApiParams<{ complaintId: number; complaintFileId: number; visibility: number }>({
            complaintId,
            complaintFileId,
            visibility
          }),
          { method: 'PATCH' }
        ]
      },
      thenFn: () => {
        refresh()
      },
      messages: {
        success: {
          title: t`Sichtbarkeit erfolgreich aktualisiert`
        },
        error: {
          title: t`Sichtbarkeit konnte nicht aktualisiert werden`
        }
      }
    }),
    [refresh]
  )
}

export const useUpdateComplaintFileStatus = () => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: { complaintId: number; complaintFileId: number; status: number }, onSuccess?: () => unknown],
    void
  >(
    () => ({
      createRequestData: ({ complaintId, complaintFileId, status }) => {
        return [
          `/complaints/${complaintId}/complaintfiles/${complaintFileId}`,
          prefixApiParams<{ complaintId: number; complaintFileId: number; status: number }>({
            complaintId,
            complaintFileId,
            status
          }),
          { method: 'PATCH' }
        ]
      },
      thenFn: () => {
        refresh()
      },
      messages: {
        success: {
          title: t`Status erfolgreich aktualisiert`
        },
        error: {
          title: t`Status konnte nicht aktualisiert werden`
        }
      }
    }),
    [refresh]
  )
}

export const useDeleteComplaint = () => {
  const refresh = useRefreshSWR()

  return useTrackedAxiosRequest<[data: { id: number }, onSuccess?: () => unknown], void>(
    () => ({
      createRequestData: ({ id }) => {
        return [`/complaints/${id}`, {}, { method: 'DELETE' }]
      },
      thenFn: (_, __, onSuccess) => {
        // after refreshing we need to go back to the list because the complaint is gone
        refresh().then(() => {
          onSuccess?.()
          // get current url and remove last part
          const url = window.location.href
          const urlParts = url.split('/')
          urlParts.pop()
          const newUrl = urlParts.join('/')
          // redirect to new url
          window.location.href = newUrl
        })
      },
      messages: {
        success: {
          title: t`Reklamation erfolgreich gelöscht`
        },
        error: {
          title: t`Reklamation konnte nicht gelöscht werden`
        }
      }
    }),
    [refresh]
  )
}

export const useSupplierComplaints = createApiDataHook<ComplaintData[], { supplierId: number }>(({ supplierId }) => [
  supplierId ? `/suppliers/${supplierId}/complaints` : null,
  {}
])

export const useComplaintFiles = createApiDataHook<ComplaintFileData[], { complaintId: number }>(({ complaintId }) => [
  `/complaints/${complaintId}/complaintfiles`,
  {}
])
