import React, { useLayoutEffect, useRef, useMemo, useCallback } from 'react'

export type CheckboxProps = {
  value?: boolean
  allowIndeterminateState?: boolean
  children?: React.ReactNode
  onChange?: (nextValue: boolean) => void
  className?: string
}

let i = 0
const useId = () => useMemo(() => `checkbox-${++i}`, [])

export const HTMLCheckbox: React.FC<
  CheckboxProps & Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'onChange'>
> = ({ value, allowIndeterminateState, onChange, ...props }) => {
  const checkbox = useRef<HTMLInputElement>(null)
  const updateValue = useCallback(() => {
    if (!checkbox.current) {
      return
    }
    if (value === true) {
      checkbox.current.indeterminate = false
      checkbox.current.checked = true
    } else if (value === undefined && allowIndeterminateState) {
      checkbox.current.indeterminate = true
    } else {
      checkbox.current.indeterminate = false
      checkbox.current.checked = false
    }
  }, [allowIndeterminateState, value])

  useLayoutEffect(() => {
    updateValue()
  }, [updateValue])

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(() => {
    onChange?.(!value)
    updateValue()
  }, [onChange, updateValue, value])

  return <input type="checkbox" ref={checkbox} onChange={handleChange} {...props} />
}

const Checkbox: React.FC<CheckboxProps> = ({ value, allowIndeterminateState, children, onChange, className }) => {
  const id = useId()

  return (
    <div
      className={['custom-control custom-checkbox', children ? '' : 'no-label', className].filter((x) => !!x).join(' ')}
    >
      <HTMLCheckbox
        allowIndeterminateState={allowIndeterminateState}
        className="custom-control-input"
        id={id}
        value={value}
        onChange={onChange}
      />
      <label className="custom-control-label" htmlFor={id}>
        {children}
      </label>
    </div>
  )
}

export default Checkbox
