import React from 'react'
import { Button } from 'react-bootstrap'
import { Icon } from '../../Icon'
import ChevronDown from 'jsx:./../../../assets/icons/chevron-down.svg'
import { Dropdown, DropdownItem } from '../../Overlays/Dropdown'
import { usePermissions } from '../../../data/remote/User'
import { useRoutes } from '../../../data/remote/RouteData'

const AccountMenu: React.FC = () => {
  const { user } = usePermissions()
  const { routes } = useRoutes()

  if (!user || !routes.account.length) {
    return null
  }

  const fullName = [user.firstName, user.lastName].filter((x) => !!x).join(' ')

  return (
    <div>
      <Dropdown
        items={routes.account.map((item, index) => {
          return (
            <DropdownItem key={index} link={item.href}>
              {item.title}
            </DropdownItem>
          )
        })}
      >
        <Button variant="link" className="account-dropdown-toggle d-flex flex-row align-items-center">
          <div className="d-flex flex-column align-items-end">
            {!!fullName ? (
              <>
                <b>{fullName}</b>
                <small>{user.username}</small>
              </>
            ) : (
              user.username
            )}
          </div>
          <Icon svg={ChevronDown} />
        </Button>
      </Dropdown>
    </div>
  )
}

export default AccountMenu
