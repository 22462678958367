import React, { useMemo } from 'react'
import pendingIcon from 'jsx:./../../assets/icons/clock.svg'
import deniedIcon from 'jsx:./../../assets/icons/menu-close.svg'
import approvedIcon from 'jsx:./../../assets/icons/check.svg'
import visibleIcon from 'jsx:./../../assets/icons/eye.svg'
import hiddenIcon from 'jsx:./../../assets/icons/eye-off.svg'
import { ApprovalStatus } from '../../data/remote/Status'
import { Tooltip, TooltipProps } from '../Overlays/Tooltip'

export type TableStatusIconProps = {
  status?: ApprovalStatus | 'visible' | 'hidden' | 'open'
  size?: number
  tooltip?: TooltipProps['text']
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const data: {
  readonly [key in Exclude<TableStatusIconProps['status'], undefined>]: {
    readonly className: string
    readonly icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  }
} = {
  pending: {
    className: 'bg-dark text-white',
    icon: pendingIcon
  },
  denied: {
    className: 'bg-danger text-white',
    icon: deniedIcon
  },
  approved: {
    className: 'bg-success text-white',
    icon: approvedIcon
  },
  visible: {
    className: 'text-primary',
    icon: visibleIcon
  },
  hidden: {
    className: 'text-danger',
    icon: hiddenIcon
  },
  open: {
    className: 'bg-danger text-white',
    icon: pendingIcon
  }
}

const TableStatusIcon = React.forwardRef<HTMLDivElement, TableStatusIconProps>(function TableStatusIcon(
  { status = 'pending', size = 22, tooltip, ...props },
  ref
) {
  const style = useMemo<React.CSSProperties>(
    () => ({
      ...props.style,
      width: size + 'px',
      height: size + 'px',
      borderRadius: size / 10 + 'px',
      display: 'inline-block'
    }),
    [props.style, size]
  )
  const svgStyle = useMemo<React.CSSProperties>(
    () => ({
      width: 0.8 * size + 'px',
      height: 0.8 * size + 'px',
      margin: 0.1 * size + 'px'
    }),
    [size]
  )

  const { className, icon: Icon } = data[status] ?? {}
  return (
    <Tooltip text={tooltip}>
      <div
        ref={ref}
        {...props}
        className={['approval-icon', className, props.className].filter((x) => !!x).join(' ')}
        style={style}
      >
        {!!Icon && <Icon style={svgStyle} />}
      </div>
    </Tooltip>
  )
})

export default TableStatusIcon
