import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import DataTable, { DataTableItem, dynamicRows, sectionHeader } from '../../../components/DataTable/DataTable'
import DotsMenu from '../../../components/DotsMenu'
import { DropdownItem } from '../../../components/Overlays/Dropdown'
import { useDeleteFile, useUploadFiles } from '../../../data/remote/FileData'
import { RegulationFileDataMinimal, useSpecificFiles } from '../../../data/remote/RegulationFile'

export type SupplierSpecialFilesProps = {
  supplierId: number
}

const SpecificFileMenu: React.FC<{
  file: RegulationFileDataMinimal
}> = ({ file }) => {
  // const [replaceFile, canReplaceFile] = useReplaceFile({
  //   type: 'specificfile',
  //   fileId: file.id
  // })
  const [deleteFile, canDeleteFile] = useDeleteFile({
    type: 'specificfile',
    fileId: file.id
  })

  return (
    <DotsMenu>
      {/* {canReplaceFile && <DropdownItem onClick={replaceFile}>{t`Datei ersetzen`}</DropdownItem>} */}
      {canDeleteFile ? (
        <DropdownItem textColor="danger" onClick={deleteFile}>{t`Datei löschen`}</DropdownItem>
      ) : undefined}
    </DotsMenu>
  )
}

const SupplierSpecialFiles: React.FC<SupplierSpecialFilesProps> = ({ supplierId }) => {
  const { data: files, loading } = useSpecificFiles({ supplierId })
  const [uploadFiles] = useUploadFiles({ type: 'specificfile', supplierId })

  const columns = useMemo(
    () => ({
      name: t`Dateiname`,
      date: t`Änderungsdatum`,
      menu: ''
    }),
    []
  )

  const items = useMemo<DataTableItem<RegulationFileDataMinimal>[]>(
    () => [
      ...sectionHeader({ newLabel: t`Dateien hinzufügen`, onNew: uploadFiles }),
      ...dynamicRows(files, loading, t`Für diesen Lieferanten wurden noch keine Dateien hochgeladen.`)
    ],
    [files, loading, uploadFiles]
  )

  const columnClasses = useMemo(
    () => ({
      menu: 'text-right'
    }),
    []
  )

  return (
    <div className="d-flex" style={{ height: '350px' }}>
      <DataTable items={items} columns={columns} columnClasses={columnClasses} greyHeader>
        {useCallback(
          (item: RegulationFileDataMinimal) => ({
            name: (
              <a href={item.file.publicUrl} target="_blank" className="text-break" rel="noreferrer">
                {item.file.properties.title}
              </a>
            ),
            date: dayjs(item.tstamp).format('L'),
            menu: <SpecificFileMenu file={item} />
          }),
          []
        )}
      </DataTable>
    </div>
  )
}

export default SupplierSpecialFiles
