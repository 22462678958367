import { t } from '@lingui/macro'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { OrderDataMinimal, useOrders } from '../../../../data/remote/Order'
import { SetParamsAction } from '../../../../navigation/createRoute'

const filterSupplier = {
  ['']: t`Alle Bestellungen`,
  complaintPending: t`Alle Reklamationen`,
  complaintStatus3: t`Aktive Reklamationen`,
  complaintStatus2: t`Beantwortete Reklamationen`,
  complaintStatus1: t`Freigegebene Reklamationen`
}

const filterEmployee = {
  ['']: t`Alle Bestellungen`,
  complaintPending: t`Alle Reklamationen`,
  complaintStatus3: t`Aktive Reklamationen`,
  complaintStatus2: t`Beantwortete Reklamationen`,
  complaintStatus1: t`Freigegebene Reklamationen`,
  complaintStatus0: t`Reklamationen im Entwurf Modus`
}

export const useOrderDataForComplaints = ({
  setParams,
  generatePath,
  supplierId,
  orderId,
  enabled = true
}: {
  setParams: (action: SetParamsAction<'Complaints'>, clearOptionals?: boolean | undefined) => void
  generatePath: (action: SetParamsAction<'Complaints'>, clearOptionals?: boolean | undefined) => string
  supplierId: string | number | undefined
  orderId: string | undefined
  enabled?: boolean
}): ColumnData<OrderDataMinimal> =>
  useColumnData<OrderDataMinimal>(
    () => ({
      useItems(search, filter) {
        return useOrders(
          {
            search,
            filter,
            supplierId,
            searchIn: 'complaints'
          },

          enabled
        )
      },
      isActiveItem(item) {
        return item.identifier === orderId
      },
      shouldHaveActiveItem: enabled && !!orderId,
      onActiveItemMiss() {
        setParams({ orderId: undefined, productId: undefined })
      },
      filterOptions: supplierId ? filterSupplier : filterEmployee,
      renderItem: (order) => ({
        id: order.id,
        hasComplaint: order.status.complaintPending,
        title: order.identifier,
        url: generatePath({ orderId: order.identifier }, true),
        status: order.status.complaintStatus
      })
    }),
    [enabled, generatePath, orderId, setParams, supplierId]
  )
