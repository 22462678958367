import React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'

export type LoginButtonProps = ButtonProps

const LoginButton: React.FC<LoginButtonProps> = (props) => {
  return <Button variant="primary2" type="submit" size="lg" {...props} className={'w-100 ' + (props.className ?? '')} />
}

export default LoginButton
