import React from 'react'
import { Header } from './Header'

export type MainContainerProps = {
  children: React.ReactNode
  className?: string
}

export const MainContainer: React.FC<MainContainerProps> = ({ children, className = '' }) => {
  return (
    <React.Fragment>
      <div className={`h-100 d-flex flex-column ${className}`}>
        <Header />
        <main>{children}</main>
      </div>
    </React.Fragment>
  )
}
