import React, { Fragment } from 'react'
import { SWRProvider } from '../scripts/api'
import { ReduxProvider } from './local/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { LanguageProvider } from '../components/System/LanguageProvider'
import { NotificationProvider } from '../navigation/Notifications'
import { ToastContainer } from '../components/Layout/ToastContainer'

type ProviderProps = {
  children: React.ReactNode
}

const Provider: React.FC<ProviderProps> = ({ children }) => {
  return (
    <>
      <ReduxProvider>
        <SWRProvider>
          <Router>
            <LanguageProvider>
              <NotificationProvider>{children}</NotificationProvider>
            </LanguageProvider>
          </Router>
        </SWRProvider>
      </ReduxProvider>
      <ToastContainer />
    </>
  )
}

export const withProvider = (App: React.ComponentType): React.FC =>
  function AppWithProvider() {
    return (
      <Provider>
        <App />
      </Provider>
    )
  }
