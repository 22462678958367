import { t } from '@lingui/macro'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { SteelHeatingsData, useSteelHeatings } from '../../../../data/remote/SteelHeatings'
import { SetParamsAction } from '../../../../navigation/createRoute'

export const useSteelHeatingData = ({
  setParams,
  generatePath,
  supplierId,
  steelHeatingId,
  canManageSteelCertificates
}: {
  setParams: (
    action: SetParamsAction<'SteelworkManagement' | 'SteelworkProtocols'>,
    clearOptionals?: boolean | undefined
  ) => void
  generatePath: (
    action: SetParamsAction<'SteelworkManagement' | 'SteelworkProtocols'>,
    clearOptionals?: boolean | undefined
  ) => string
  supplierId: string | number | undefined
  steelHeatingId?: string | undefined
  canManageSteelCertificates: boolean
}): ColumnData<SteelHeatingsData> =>
  useColumnData<SteelHeatingsData>(
    () => ({
      useItems(search, filter) {
        return useSteelHeatings(
          supplierId
            ? {
                search,
                filter,
                supplierId
              }
            : undefined,
          !!supplierId
        )
      },
      isActiveItem(item) {
        return item.id.toString() === steelHeatingId
      },
      shouldHaveActiveItem: !!supplierId && !!steelHeatingId,
      onActiveItemMiss() {
        setParams({
          supplierId: supplierId?.toString(),
          steelHeatingId: undefined
        })
      },
      // @ts-ignore
      filterOptions: !canManageSteelCertificates
        ? {}
        : {
            ['']: t`Alle Schmelzen`,
            open: t`Mit offenem Status`
          },
      renderItem: (steelHeating) => ({
        id: steelHeating.id,
        title: steelHeating.identifier,
        additionalLabel: steelHeating.increment,
        url: generatePath({
          supplierId,
          steelHeatingId: steelHeating.id,
          steelCertificateId: undefined
        }),
        heatingStatus: steelHeating.status
      })
    }),
    [canManageSteelCertificates, generatePath, setParams, steelHeatingId, supplierId]
  )
