import { t } from '@lingui/macro'
import React from 'react'

import { OrderDataMinimal } from '../../../../data/remote/Order'
import { ProductDataMinimal } from '../../../../data/remote/Product'

import DataColumn, { ColumnData } from '../../../../components/ListView/DataColumn'
import { useModalActions } from '../../../../components/Overlays/Modal'

import AddComplaintModal from '../AddComplaintModal'
import { ComplaintData } from '../../../../types/ComplaintDataTypes'
import { useCanManageComplaints } from '../../../../data/remote/Complaint'

export type ComplaintColumnProps = ColumnData<ComplaintData> & {
  generatePath: (complaintId: string) => string
  order: OrderDataMinimal
  product: ProductDataMinimal
}

const ComplaintColumn: React.FC<ComplaintColumnProps> = ({ generatePath, order, product, ...props }) => {
  const canCreateComplaints = useCanManageComplaints()
  const { ref: modal, open } = useModalActions()
  const addComplaintColumn = canCreateComplaints ? open : undefined

  return (
    <>
      <DataColumn
        title={t`CAQ-Nummer`}
        {...props}
        newLabel={t`Reklamation hinzufügen`}
        onNew={addComplaintColumn}
        arrow={'right'}
      />
      {!!addComplaintColumn && (
        <AddComplaintModal ref={modal} order={order} product={product} generatePath={generatePath} />
      )}
    </>
  )
}

export default ComplaintColumn
