import { t } from '@lingui/macro'
import classNames from 'classnames'
import React from 'react'
import { Variant } from 'react-bootstrap/esm/types'
import { Icon } from '../Icon'
import { FilterDropdown, FilterItemProps } from './FilterDropdown'
import { Search } from './Search'
import ArrowRight from 'jsx:./../../assets/icons/chevron-right.svg'
import ArrowLeft from 'jsx:./../../assets/icons/chevron-left.svg'
import { Tooltip } from '../Overlays/Tooltip'

export type HeaderProps = {
  title?: string
  search?: string
  searchTitle?: string
  onSearch?: (input: string) => void
  filter?: FilterItemProps[]
  bg?: Variant
  arrow?: 'left' | 'right'
  active?: boolean
  toolbar?: React.ReactElement
  children?: React.ReactElement
}

export const HeaderToolbarButton: React.FC<{
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  title: string
  onClick?: () => unknown
  disabled?: boolean
}> = ({ icon, title, onClick, disabled }) => (
  <Tooltip text={title}>
    <button
      style={{ fontSize: '1.8rem' }}
      disabled={!!disabled}
      className={'btn p-0' + (disabled ? ' btn-disabled' : '')}
      onClick={disabled ? undefined : onClick}
    >
      <Icon svg={icon} />
      <div className="visually-hidden">{title}</div>
    </button>
  </Tooltip>
)

export const Header: React.FC<HeaderProps> = ({
  title,
  onSearch,
  search,
  searchTitle,
  filter,
  bg,
  children,
  arrow,
  active,
  toolbar
}) => {
  if (!title && !filter && !children && !toolbar) {
    return null
  }

  return (
    <>
      <div className={classNames('listview-header', !active && bg && `bg-white`, active && 'bg-primary text-white')}>
        <div className={classNames('listview-title', active && 'text-white')}>{title}</div>
        {toolbar && <div className="listview-toolbar">{toolbar}</div>}
        {arrow && (
          <div className="listview-filter">
            <div className="listview-col-toggle">
              <Icon svg={arrow === 'left' ? ArrowLeft : ArrowRight} />
            </div>
          </div>
        )}
      </div>
      {onSearch && (
        <div className={classNames('listview-header', bg && `bg-${bg}`)}>
          <Search title={searchTitle ? t`${searchTitle}` : t`Suchbegriff...`} onSearch={onSearch} search={search} />
          {filter && <FilterDropdown items={filter} />}
          {children}
        </div>
      )}
    </>
  )
}
