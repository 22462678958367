import React, { useCallback, useImperativeHandle, useRef, useState } from 'react'
import { Modal as ModalOriginal, ModalProps as ModalPropsOriginal } from 'react-bootstrap'

export type ModalProps = ModalPropsOriginal
export type ModalRef<T = boolean> = {
  open: T extends boolean ? () => void : (target: T) => void
  close: () => void
}

export const useModalActions = (): ModalRef<boolean> & {
  ref: React.MutableRefObject<ModalRef | null>
} => {
  const ref = useRef<ModalRef>(null)
  return {
    open: useCallback(() => {
      ref.current?.open()
    }, []),
    close: useCallback(() => {
      ref.current?.close()
    }, []),
    ref
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint, @typescript-eslint/no-explicit-any
export const useModalProps = <T extends any = boolean>(
  { onHide }: ModalProps,
  ref?: React.ForwardedRef<ModalRef<T>>
): [props: ModalProps, value: T | undefined] => {
  const [show, setShow] = useState(false)
  const [value, setValue] = useState<T | undefined>()

  useImperativeHandle(
    ref,
    () => {
      return {
        open: (target) => {
          setValue(target as unknown as T)
          setShow(true)
        },
        close: () => {
          setShow(false)
        }
      } as ModalRef<T>
    },
    []
  )

  const handleHide = useCallback(() => {
    onHide?.()
    setShow(false)
  }, [onHide])

  return [
    {
      centered: true,
      size: 'lg',
      onHide: handleHide,
      show
    },
    value
  ]
}

const Modal = Object.assign(
  React.forwardRef<ModalRef, ModalPropsOriginal>(function Modal(props, ref) {
    const [modalProps] = useModalProps(props, ref)

    return <ModalOriginal {...props} {...modalProps} />
  }),
  {
    Header: ModalOriginal.Header,
    Title: ModalOriginal.Title,
    Body: ModalOriginal.Body
  }
)

export default Modal
