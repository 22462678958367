import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import Scrollbars, { positionValues } from 'react-custom-scrollbars-2'
// @ts-ignore
import getScrollBarWidth from 'react-custom-scrollbars-2/lib/utils/getScrollbarWidth'

export type ScrollviewProps = {
  padding?: boolean
  autoHideScrollbar?: boolean
  wideScrollbarY?: boolean
  children?: React.ReactNode
}

export const Scrollview: React.FC<ScrollviewProps> = ({ padding, autoHideScrollbar, wideScrollbarY, children }) => {
  const [visibleBars, setVisibleBars] = useState({
    x: false,
    y: false
  })

  const macOSScrollbars = !getScrollBarWidth()

  const onUpdate = useCallback(
    (values: positionValues) => {
      setVisibleBars({
        ...visibleBars,
        x: !macOSScrollbars && (!autoHideScrollbar || values.scrollWidth > values.clientWidth),
        y: !macOSScrollbars && (!autoHideScrollbar || values.scrollHeight > values.clientHeight)
      })
    },
    [visibleBars, macOSScrollbars, autoHideScrollbar]
  )

  return (
    <Scrollbars
      className={[
        'scroll-container',
        wideScrollbarY && 'wide-scrollbar-y',
        visibleBars.x && 'scroll-x',
        visibleBars.y && 'scroll-y'
      ]
        .filter((x) => !!x)
        .join(' ')}
      renderTrackHorizontal={
        visibleBars.x
          ? (props) => <div {...props} className="scroll-track-x" />
          : () => <div style={{ display: 'none' }}></div>
      }
      renderThumbHorizontal={(props) => <div {...props} className="scroll-handle-x" />}
      renderTrackVertical={
        visibleBars.y
          ? (props) => <div {...props} className="scroll-track-y" />
          : () => <div style={{ display: 'none' }}></div>
      }
      renderThumbVertical={(props) => <div {...props} className="scroll-handle-y" />}
      renderView={(props) => <div {...props} className={classNames('scroll-content', padding && 'p-2')} />}
      onUpdate={onUpdate}
    >
      {children}
    </Scrollbars>
  )
}
