import classNames from 'classnames'
import { forEach } from 'lodash'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Status } from '../../types/StatusTypes'
import Checkbox from '../Checkbox'
import { Tooltip } from '../Overlays/Tooltip'
import { StatusIcon } from '../StatusIcon'

export type ListItemProps = {
  tooltip?: string | ReactNode | undefined | null
  active?: boolean
  onClick?: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>['onClick']
  onDoubleClick?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >['onDoubleClick']
  gray?: boolean
  denied?: boolean
  pending?: boolean
  uploadMissing?: boolean
  url?: string
  // Setting this to true, false OR undefined will show a checkbox in checked, unchecked or indeterminate state
  checked?: boolean
  setChecked?: (nextValue: boolean) => unknown
  children?: React.ReactNode
  productArticle?: boolean
  // Complaint status
  complaintStatus?: Status
  status?: Status
  hasComplaint?: boolean
  type?: 'default' | 'complaint' | 'certificate'
}

export const ListItemComplaints: React.FC<ListItemProps> = ({
  tooltip,
  active = false,
  onClick,
  onDoubleClick,
  gray = false,
  status,
  children,
  hasComplaint,
  url,
  ...props
}) => {
  useEffect(() => {
    if (active) {
      const activeElement = document.querySelectorAll('.listview-item.active')[0]
      activeElement?.scrollIntoView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const statusIcons = useMemo(() => {
    return (
      <div className="listview-item-status">
        {hasComplaint === undefined || hasComplaint ? (
          status === Status.draft ? (
            <StatusIcon variant="secondary" size="sm" />
          ) : status === Status.open ? (
            <StatusIcon variant="danger" size="sm" />
          ) : status === Status.inProgress ? (
            <StatusIcon variant="warning" size="sm" />
          ) : status === Status.approved ? (
            <StatusIcon variant="success" size="sm" />
          ) : null
        ) : null}
      </div>
    )
  }, [hasComplaint, status])

  const content =
    'checked' in props ? (
      <Checkbox allowIndeterminateState value={props.checked} onChange={props.setChecked} className="label-disabled">
        {children}
      </Checkbox>
    ) : (
      children
    )

  const listItem = url ? (
    <NavLink to={url} activeClassName="active" className={classNames('listview-item', gray && 'gray')}>
      <div className="listview-item-label">{content}</div>
      {statusIcons}
    </NavLink>
  ) : (
    <button
      className={classNames('listview-item', active && 'active', gray && 'gray')}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      disabled={!onClick}
    >
      <div className="listview-item-label">{content}</div>
      {statusIcons}
    </button>
  )

  return (
    <Tooltip text={tooltip} placement="bottom-start" delay={{ show: 1000, hide: 0 }}>
      {listItem}
    </Tooltip>
  )
}
