import React from 'react'
import { Form } from 'react-bootstrap'
import { CustomElement, FieldValues } from 'react-hook-form'

export type TextAreaProps = {
  register?: (ref: (HTMLTextAreaElement & CustomElement<FieldValues>) | null) => void
  controlId: string
  label: string
  labelSrOnly?: boolean
  name: string
  size?: 'sm' | 'lg' | undefined
  placeholder?: string
  rows?: number
  groupClass?: string
  labelClass?: string
  inputClass?: string
  error?: string
  readOnly?: boolean
  required?: boolean
  defaultValue?: string
  children?: React.ReactNode
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  {
    controlId,
    label,
    labelSrOnly,
    name,
    size = 'lg',
    placeholder,
    rows = 3,
    groupClass,
    labelClass,
    inputClass,
    error,
    readOnly,
    required,
    defaultValue,
    children,
    ...props
  },
  ref
) {
  return (
    <Form.Group controlId={controlId} className={groupClass}>
      <Form.Label srOnly={labelSrOnly} className={labelClass}>
        {label}
      </Form.Label>
      <Form.Control
        as="textarea"
        name={name}
        size={size}
        placeholder={placeholder}
        rows={rows}
        className={inputClass}
        isInvalid={!!error}
        readOnly={readOnly}
        required={required}
        defaultValue={defaultValue}
        {...props}
        ref={ref}
      >
        {children}
      </Form.Control>
      {!!error && <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>}
    </Form.Group>
  )
})
