import classNames from 'classnames'
import React from 'react'
import { Variant } from 'react-bootstrap/esm/types'
import { Icon } from './Icon'

export type StatusIconProps = {
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>
  title?: string
  variant?: Variant
  size?: 'sm' | 'lg'
  className?: string
  disabled?: boolean
}

export const StatusIcon: React.FC<StatusIconProps> = ({
  icon,
  title,
  variant = 'primary',
  size,
  className,
  disabled
}) => {
  return (
    <div
      className={classNames(
        'status-icon',
        variant && `bg-${variant}`,
        size && `status-${size}`,
        disabled && 'status-disabled',
        className
      )}
      title={title}
    >
      {icon && <Icon svg={icon} />}
    </div>
  )
}
