import { t } from '@lingui/macro'
import { toNumber } from 'lodash'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { OrderDataMinimal } from '../../../../data/remote/Order'
import { ProductDataMinimal, useProducts } from '../../../../data/remote/Product'
import { SetParamsAction } from '../../../../navigation/createRoute'

export const useProductsDataForComplaints = ({
  setParams,
  generatePath,
  productId,
  orderData
}: {
  setParams: (action: SetParamsAction<'Complaints'>, clearOptionals?: boolean | undefined) => void
  generatePath: (action: SetParamsAction<'Complaints'>, clearOptionals?: boolean | undefined) => string
  productId: string | undefined
  orderData: ColumnData<OrderDataMinimal>
}): ColumnData<ProductDataMinimal> =>
  useColumnData<ProductDataMinimal>(
    () => ({
      useItems(search, filter) {
        return useProducts(
          orderData.current
            ? {
                search,
                filter,
                orderId: orderData.current?.id
              }
            : undefined,
          !!orderData.current
        )
      },
      isActiveItem(item) {
        return item.id === toNumber(productId)
      },
      shouldHaveActiveItem: !!orderData.current && !!productId,
      onActiveItemMiss() {
        setParams({
          orderId: orderData.current?.identifier,
          productId: undefined
        })
      },
      filterOptions: {
        ['']: t`Alle Artikel`,
        complaintPending: t`mit Reklamationen`
      },
      renderItem: (product) => ({
        status: product.status.complaintStatus,
        hasComplaint: product.status.complaintPending,
        id: product.id,
        title: product.sku,
        tooltip: product.localizedTitle,
        url: generatePath({ orderId: orderData.current?.identifier, productId: product.id }, true),
        denied: product.status?.approval === 'denied'
      })
    }),
    [generatePath, orderData, productId, setParams]
  )
