import { t } from '@lingui/macro'
import React, { useEffect, useMemo } from 'react'
import { Col, Form, Button, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { TextArea } from '../../../components/Form'
import Modal, { ModalRef, useModalActions } from '../../../components/Overlays/Modal'
import { useUpdateComplaintDescription } from '../../../data/remote/Complaint'
import useCombinedRef from '../../../scripts/useCombinedRef'
import { ComplaintData } from '../../../types/ComplaintDataTypes'

export type EditComplaintDescriptionModalProps = {
  onSuccess?: (data: ComplaintData) => void
  complaintId: number
  description?: string
}

const EditComplaintDescriptionModal = React.forwardRef<ModalRef, EditComplaintDescriptionModalProps>(
  function EditComplaintDescriptionModal({ onSuccess, complaintId, description }, ref) {
    const modalActions = useModalActions()
    const modalRef = useCombinedRef(modalActions.ref, ref)

    const { register, handleSubmit, setValue } = useForm<{
      complaintId: number
      description: string
    }>({
      defaultValues: {
        description: description ? description : ''
      }
    })

    useEffect(() => {
      setValue('description', description ? description : '')
    }, [description, setValue])

    const updateComplaintDescription = useUpdateComplaintDescription()

    const onSubmit = useMemo(
      () =>
        handleSubmit((data) => {
          if (!updateComplaintDescription.running) {
            updateComplaintDescription.run(
              {
                id: complaintId,
                description: data.description
              },
              (response?: ComplaintData) => {
                modalActions.close()
                onSuccess?.(response ? response : ({} as ComplaintData))
              }
            )
          }
        }),
      [handleSubmit, updateComplaintDescription, complaintId, modalActions, onSuccess]
    )

    return (
      <Modal ref={modalRef}>
        <Modal.Header closeButton>
          <Modal.Title>{t`Beschreibung bearbeiten`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Row className="form-row">
              <Col xs={12} className="mt-3">
                <TextArea
                  label={t`Kurzbeschreibung`}
                  defaultValue={description}
                  {...register('description', { required: true })}
                  controlId={'description'}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={4}>
                <div className="d-grid">
                  <Button variant="secondary" type="button" size="lg" className="w-100" onClick={modalActions.close}>
                    {t`Abbrechen`}
                  </Button>
                </div>
              </Col>
              <Col xs={4} className="ml-auto">
                <div className="d-grid">
                  <Button variant="primary" type="submit" disabled={false} size="lg" className="w-100">
                    {t`Speichern`}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default EditComplaintDescriptionModal
