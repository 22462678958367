import { Entrypoint, entrypointIndexes, entrypointNames, SearchSelection } from '.'

export const getEnabledColumns = (entrypoint: Entrypoint | undefined, selection: SearchSelection): Entrypoint[] => {
  if (!entrypoint) {
    return []
  }
  const result = [entrypoint]
  if (selection[entrypoint]) {
    for (let i = entrypointIndexes[entrypoint] - 1; i >= 0; i--) {
      result.unshift(entrypointNames[i] as Entrypoint)
      if (!selection[entrypointNames[i]]) {
        break
      }
    }
    for (let i = entrypointIndexes[entrypoint] + 1; i < entrypointNames.length; i++) {
      result.push(entrypointNames[i] as Entrypoint)
      if (!selection[entrypointNames[i]]) {
        break
      }
    }
  }
  return result
}

export const getParentSelection = (
  entrypoint: Entrypoint | undefined,
  selection: SearchSelection,
  columnName: Entrypoint
): Partial<SearchSelection> => {
  if (!entrypoint || entrypoint === columnName) {
    return {}
  }
  const result: Partial<SearchSelection> = {}
  if (entrypointIndexes[columnName] < entrypointIndexes[entrypoint]) {
    for (let i = entrypointIndexes[columnName] + 1; i <= entrypointIndexes[entrypoint]; i++) {
      result[entrypointNames[i]] = selection[entrypointNames[i]]
    }
  } else {
    for (let i = entrypointIndexes[columnName] - 1; i >= entrypointIndexes[entrypoint]; i--) {
      result[entrypointNames[i]] = selection[entrypointNames[i]]
    }
  }
  return result
}
