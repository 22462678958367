import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { Col, Form, Button, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Flag from '../../../../components/Flag'
import { Input } from '../../../../components/Form'
import Modal, { ModalRef, useModalActions } from '../../../../components/Overlays/Modal'
import { RegulationCategoryDataMinimal, useCreateRegulationCategory } from '../../../../data/remote/RegulationCategory'
import useCombinedRef from '../../../../scripts/useCombinedRef'

export type CreateCategoryModalProps = {
  onSuccess?: (category: RegulationCategoryDataMinimal) => unknown
}

const CreateCategoryModal = React.forwardRef<ModalRef, CreateCategoryModalProps>(function CreateCategoryModal(
  { onSuccess },
  ref
) {
  const modalActions = useModalActions()
  const modalRef = useCombinedRef(modalActions.ref, ref)

  const { register, watch, handleSubmit } = useForm<{
    title: {
      de: string
      en: string
    }
  }>({
    defaultValues: {
      title: {
        de: '',
        en: ''
      }
    }
  })
  const createCategory = useCreateRegulationCategory()

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        if (!createCategory.running) {
          createCategory.run(data, (response) => {
            // history.push(generatePath(data.code))
            modalActions.close()
            onSuccess?.(response)
          })
        }
      }),
    [createCategory, handleSubmit, modalActions, onSuccess]
  )

  const translationPlaceholder = watch('title.de') || '(optional)'

  return (
    <Modal ref={modalRef}>
      <Modal.Header closeButton>
        <Modal.Title>{t`Kategorie hinzufügen`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col xs={12}>
              <Input
                label={t`Name der Kategorie`}
                {...register('title.de', { required: true })}
                prepend={<Flag language="de" />}
              />
              <Input
                label={t`Name of the category`}
                labelSrOnly
                {...register('title.en')}
                placeholder={translationPlaceholder}
                prepend={<Flag language="en" />}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="ml-auto">
              <div className="d-grid mt-3">
                <Button variant="primary" type="submit" disabled={false} size="lg" className="w-100">
                  {t`Kategorie hinzufügen`}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
})

export default CreateCategoryModal
