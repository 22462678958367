import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import React from 'react'
import { ListGroup } from 'react-bootstrap'
import { CommentDataMinimal } from '../data/remote/Comment'
import { Loader } from './Loader'

export type CommentsListProps = {
  items?: CommentDataMinimal[]
  loading?: boolean
}

const CommentsList: React.FC<CommentsListProps> = ({ items, loading }) => {
  return (
    <ListGroup className="comments-list" variant="flush">
      {items?.map((item) => (
        <ListGroup.Item key={item.id}>
          <div className="comment-header">
            <div className="comment-author">{item.author}</div>
            <div>{dayjs(item.crdate).format('L')}</div>
          </div>
          <div>{item.content}</div>
        </ListGroup.Item>
      ))}
      {!items?.length && loading && (
        <>
          <ListGroup.Item>
            <Loader width={200} dynamicWidth={0.75} />
          </ListGroup.Item>
          <ListGroup.Item>
            <Loader width={200} dynamicWidth={0.75} />
          </ListGroup.Item>
          <ListGroup.Item>
            <Loader width={200} dynamicWidth={0.75} />
          </ListGroup.Item>
        </>
      )}
      {!items?.length && !loading && (
        <ListGroup.Item className="text-secondary text-center">{t`Keine Kommentare vorhanden.`}</ListGroup.Item>
      )}
    </ListGroup>
  )
}

export default CommentsList
