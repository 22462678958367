import React from 'react'
import { withProvider } from './data/Provider'
import { useSettings } from './data/remote/Settings'
import { useAuthentication } from './data/remote/User'
import { useTitle } from './navigation/useTitle'
import LoginNavigator from './views/Navigators/LoginNavigator'
import MainNavigator from './views/Navigators/MainNavigator'
import { useSWRConfig } from 'swr'

const App: React.FC = withProvider(() => {
  const { serviceAvailable, appVersion } = useSettings()
  const { mutate } = useSWRConfig()

  if (appVersion) {
    if (localStorage.getItem('app-version') !== appVersion) {
      localStorage.setItem('app-version', appVersion)
      localStorage.removeItem('app-cache')
      mutate(() => true, undefined, false)
      console.warn('Version changed, clearing cache', appVersion)
      window.location.reload()
    }
  }

  const { loggedIn } = useAuthentication()

  useTitle()
  return serviceAvailable && loggedIn ? <MainNavigator /> : <LoginNavigator />
})

export default App
