import { t } from '@lingui/macro'
import { RegulationCategoryDataMinimal, useRegulationCategories } from '../../../data/remote/RegulationCategory'
import { RegulationFolderDataMinimal, useRegulationFolders } from '../../../data/remote/RegulationFolder'
import { createRoute } from '../../../navigation/createRoute'
import { useRegulationFiles } from '../../../data/remote/RegulationFile'
import RegulationFilesTable from './Columns/RegulationFilesTable'
import DataColumn, { useColumnData } from '../../../components/ListView/DataColumn'
import { toNumber } from '../../../scripts/utils'
import CreateCategoryModal from './Modals/CreateCategoryModal'
import { useModalActions } from '../../../components/Overlays/Modal'
import CreateFolderModal from './Modals/CreateFolderModal'
import { useCallback } from 'react'

const FileManagement = createRoute(
  'FileManagement',
  function FileManagement({ params: { categoryId, folderId }, generatePath, setParams }) {
    const categoryData = useColumnData<RegulationCategoryDataMinimal>(
      () => ({
        disableSearch: true,
        useItems() {
          return useRegulationCategories()
        },
        isActiveItem(item) {
          return item.id === toNumber(categoryId)
        },
        shouldHaveActiveItem: !!categoryId,
        onActiveItemMiss() {
          setParams({}, true)
        },
        renderItem(category) {
          return {
            id: category.id,
            title: category.localizedTitle,
            url: generatePath({ categoryId: category.id }, true)
          }
        }
      }),
      [categoryId, generatePath, setParams]
    )

    const folderData = useColumnData<RegulationFolderDataMinimal>(
      () => ({
        disableSearch: true,
        useItems() {
          return useRegulationFolders({ categoryId: categoryData.current?.id ?? -1 }, !!categoryData.current?.id)
        },
        isActiveItem(item) {
          return item.id === toNumber(folderId)
        },
        shouldHaveActiveItem: !!categoryData.current && !!folderId,
        onActiveItemMiss() {
          setParams({ categoryId }, true)
        },
        renderItem(folder) {
          return {
            id: folder.id,
            title: folder.localizedTitle,
            url: generatePath({ folderId: folder.id }, false)
          }
        }
      }),
      [categoryData, categoryId, folderId, generatePath, setParams]
    )

    const { data: files, loading: filesLoading } = useRegulationFiles({ folderId: folderData.current?.id })

    const categoryModal = useModalActions()
    const folderModal = useModalActions()
    const onCategoryCreated = useCallback(
      (category: RegulationCategoryDataMinimal) => {
        setParams(
          {
            categoryId: category.id
          },
          true
        )
      },
      [setParams]
    )
    const onFolderCreated = useCallback(
      (folder: RegulationFolderDataMinimal) => {
        setParams(
          {
            categoryId: categoryData.current?.id,
            folderId: folder.id
          },
          true
        )
      },
      [categoryData, setParams]
    )

    return (
      <>
        <DataColumn
          title={t`Kategorie`}
          {...categoryData}
          newLabel={t`Kategorie hinzufügen`}
          onNew={categoryModal.open}
          arrow={categoryId ? 'right' : undefined}
        />
        <CreateCategoryModal ref={categoryModal.ref} onSuccess={onCategoryCreated} />
        {!!categoryData.current && (
          <>
            <DataColumn
              title={t`Ordner`}
              {...folderData}
              newLabel={t`Ordner hinzufügen`}
              onNew={folderModal.open}
              arrow={folderId ? 'right' : undefined}
            />
            <CreateFolderModal category={categoryData.current} ref={folderModal.ref} onSuccess={onFolderCreated} />
          </>
        )}
        {!!folderData.current && (
          <RegulationFilesTable data={files} loading={filesLoading} folderId={folderData.current.id} />
        )}
      </>
    )
  }
)

export default FileManagement
