import { t } from '@lingui/macro'
import { createApiDataHook } from '../../scripts/api'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { FileData } from '../../types/FileDataTypes'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { HeatingStatus } from '../../types/StatusTypes'
import { usePermission } from './User'

export type SteelCertificatesData = {
  id: number
  heating: number
  product: {
    id: number
    lsw: string
    material: string
    title: string
    width: number
    height: number
  }
  certificateUrl?: string
  originalFile: FileData
  properties: {
    chemical: {
      [element: string]: {
        issue?: boolean
        key: string
        label?: string
        max?: string | number | null | undefined
        min?: string | number | null | undefined
        value: number | string
      }
    }
    mechanical: {
      [element: string]: {
        issue?: boolean
        key: string
        label?: string
        max?: string | number | null | undefined
        min?: string | number | null | undefined
        value: number | string
      }
    }
  }
  protocolUrl?: string
  version: number
  status: HeatingStatus
}

export type UseSteelCertificatesParams = {
  search?: string | null
  filter?: string | null
  steelHeatingId?: string | number
  steelCertificateId?: string | number
}

export type CreateSteelCertificateInput = {
  readonly supplier: string
  readonly steelHeatingId: number
  readonly lsw: string
  readonly width: string
  readonly height: string
}

export type UpdateSteelCertificateStatus = {
  readonly steelCertificateId?: number
  readonly approval: HeatingStatus['approval']
}

export type UpdateSteelCertificateInput = {
  readonly certificateId?: number
  readonly steelHeatingId?: number
  readonly chemicalProperties: { [key: string]: number | string }
  readonly mechanicalProperties: { [key: string]: number | string }
}

export const useCanManageSteelCertificates = (): boolean => usePermission('Alle_Stahlwerks_Zeugnisse_verwalten')

export const useCreateSteelCertificate = (): {
  run: (data: CreateSteelCertificateInput, onSuccess?: (data: SteelCertificatesData) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: CreateSteelCertificateInput, onSuccess?: (data: SteelCertificatesData) => unknown],
    SteelCertificatesData
  >(
    () => ({
      createRequestData: ({ steelHeatingId, ...data }) => {
        return [
          `/steelheatings/${steelHeatingId}/steelcertificates`,
          prefixApiParams<CreateSteelCertificateInput>({ ...data, steelHeatingId }),
          { method: 'POST' }
        ]
      },
      thenFn: (response, _inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes(response.config.url as string)
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}

export const useUpdateSteelCertificateInput = (): {
  run: (data: UpdateSteelCertificateInput, onSuccess?: (data: SteelCertificatesData) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: UpdateSteelCertificateInput, onSuccess?: (data: SteelCertificatesData) => unknown],
    SteelCertificatesData
  >(
    () => ({
      createRequestData: ({ certificateId, ...data }) => {
        return [
          `/steelcertificates/${certificateId}`,
          prefixApiParams<UpdateSteelCertificateInput>({ ...data }),
          { method: 'PATCH' }
        ]
      },
      thenFn: (response, _inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/steelcertificates')
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}

export const useUpdateSteelCertificateStatus = (): {
  run: (data: UpdateSteelCertificateStatus, onSuccess?: (data: SteelCertificatesData) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()
  return useTrackedAxiosRequest<
    [data: UpdateSteelCertificateStatus, onSuccess?: (data: SteelCertificatesData) => unknown],
    SteelCertificatesData
  >(
    () => ({
      createRequestData: ({ steelCertificateId, ...data }) => {
        return [
          `/steelcertificates/${steelCertificateId}`,
          prefixApiParams<UpdateSteelCertificateStatus>({ ...data }),
          { method: 'PATCH' }
        ]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/steelcertificates')
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}

export const useSteelCertificates = createApiDataHook<SteelCertificatesData[], UseSteelCertificatesParams>(
  ({ steelHeatingId, ...params }) => [
    steelHeatingId ? `/steelheatings/${steelHeatingId}/steelcertificates` : '/steelcertificates',
    params
  ]
)
