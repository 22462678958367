import { t } from '@lingui/macro'
import React from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { Input } from '../../../../components/Form'
import { ModalProps, ModalRef, useModalActions, useModalProps } from '../../../../components/Overlays/Modal'
import { useConfirmationFiles } from '../../../../data/remote/ConfirmationFile'
import { RegulationFileDataMinimal } from '../../../../data/remote/RegulationFile'
import { useAuthentication } from '../../../../data/remote/User'
import useCombinedRef from '../../../../scripts/useCombinedRef'
import ConfirmationFilesTable from '../Columns/ConfirmationFilesTable'

export type ManageConfirmationsModalRef = ModalRef<RegulationFileDataMinimal>
export type ManageConfirmationsModalProps = ModalProps

const ManageConfirmationsModal = React.forwardRef<ManageConfirmationsModalRef, ManageConfirmationsModalProps>(
  function ManageConfirmationsModal(props, ref) {
    const modalActions = useModalActions()
    const modalRef = useCombinedRef(modalActions.ref, ref)

    const [modalProps, file] = useModalProps(props, modalRef)
    const { user } = useAuthentication()
    const { data, loading } = useConfirmationFiles({
      //@ts-ignore
      regulationFileId: file?.id,
      supplierId: user?.id
    })

    return (
      <Modal {...modalProps}>
        <Modal.Header closeButton>
          <Modal.Title>{t`Uploads verwalten`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form as="div">
            <Row>
              <Col xs={12}>
                {/* @ts-ignore */}
                <Input name="filename" label={t`Dateiname`} value={file?.file.properties.title} readOnly />
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>{t`Hochgeladene Bestätigungen`}</Form.Label>
                  <div className="form-table-wrap">
                    <ConfirmationFilesTable data={data} loading={loading} />
                  </div>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default ManageConfirmationsModal
