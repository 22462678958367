import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import _ from 'lodash'
import QueryString from 'qs'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useCurrentLocale } from '../data/remote/Language'
import { defaultLocale } from './config'
import { showToast, ToastProps } from './toast'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TrackedPromise<Args extends unknown[], ResponseType, ErrorType = any> = {
  run: (...args: Args) => void
  running: boolean
  actionAllowed: boolean
  result: ResponseType | undefined
  error: ErrorType | undefined
}

const useTrackedPromise = <Args extends unknown[], ResponseType, ErrorType = unknown>(
  args: () => {
    createPromise: (
      ...args: Args
    ) => Promise<ResponseType> | { promise: Promise<ResponseType>; cancel?: (message: string) => unknown }
    thenFn?: (result: ResponseType, ...args: Args) => void
    catchFn?: (error: ErrorType, ...args: Args) => void
    actionAllowed?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isCancel?: (error: any) => boolean
  },
  dependencies: unknown[]
): TrackedPromise<Args, ResponseType, ErrorType> => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { createPromise, thenFn, catchFn, actionAllowed = true, isCancel } = useMemo(args, dependencies)
  const [running, setRunning] = useState(false)
  const _running = useRef(false)
  const mounted = useRef(true)
  const cancel = useRef<(message: string) => unknown>()

  const [result, setResult] = useState<ResponseType>()
  const [error, setError] = useState<ErrorType>()

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )
  const run = useMemo(
    () =>
      (...args: Args) => {
        if ((!cancel.current && _running.current) || !mounted || !actionAllowed) {
          return
        }
        cancel.current?.('More current request issued by the user.')
        cancel.current = undefined
        _running.current = true
        setRunning(true)
        setError(undefined)
        const result = createPromise(...args)
        const promise = 'promise' in result ? result.promise : result
        cancel.current = 'cancel' in result ? result.cancel : undefined
        promise
          .then((x) => {
            _running.current = false
            setRunning(false)
            cancel.current = undefined
            setResult(x)
            return thenFn?.(x, ...args)
          })
          .catch((error: ErrorType) => {
            if (!isCancel || !isCancel(error)) {
              _running.current = false
              setRunning(false)
              cancel.current = undefined
              setError(error)
              catchFn?.(error, ...args)
            }
          })
      },
    [actionAllowed, catchFn, createPromise, isCancel, thenFn]
  )

  return { run, running, actionAllowed, error, result }
}

export type ServerResponseSuccess<T> = {
  code: 200
  data: T
  message: string
  status: 'success'
}

export type APIError = AxiosError<{
  message?: string
}>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TrackedAxiosRequest<
  Args extends unknown[],
  ResponseType = unknown,
  Error = AxiosError<{
    message?: string
  }>
> = TrackedPromise<Args, AxiosResponse<ServerResponseSuccess<ResponseType>>, Error> & {
  cancel: React.RefObject<(() => void) | undefined>
}
export const useTrackedAxiosRequest = <Args extends unknown[], ResponseType>(
  args: () => {
    createRequestData: (...args: Args) => [url: string, args?: unknown, requestConfig?: AxiosRequestConfig]
    skipRequest?: (...args: Args) => ResponseType | undefined
    thenFn?: (result: AxiosResponse<ServerResponseSuccess<ResponseType>>, ...args: Args) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    catchFn?: (error: any, ...args: Args) => void
    messages?: {
      success?: ToastProps
      error?: ToastProps
    }
    actionAllowed?: boolean
    /** What to do when the function gets called before the previous has finished */
    repetitionBehavior?: 'ignore' | 'cancel'
  },
  dependencies: unknown[]
): TrackedAxiosRequest<Args, ResponseType> => {
  const {
    createRequestData,
    thenFn,
    catchFn,
    messages,
    actionAllowed,
    repetitionBehavior = 'ignore',
    skipRequest
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } = useMemo(() => args(), dependencies)
  const locale = useCurrentLocale() ?? defaultLocale
  const cancelRef = useRef<() => void>()

  return {
    ...(useTrackedPromise(
      () => ({
        // @ts-ignore
        createPromise: (...args: Args) => {
          try {
            const skippedResult = skipRequest?.(...args)
            if (typeof skippedResult !== 'undefined') {
              return {
                promise: Promise.resolve({
                  data: {
                    code: 200,
                    data: skippedResult,
                    message: 'skipped',
                    status: 'success'
                  },
                  status: 200,
                  statusText: 'OK',
                  headers: [],
                  config: {}
                })
              }
            }
          } catch (_e) {}
          const [url, requestArgs = {}, requestConfig = {}] = createRequestData(...args)
          const cancelTokenSource = axios.CancelToken.source()

          const promise = axios({
            method: 'POST',
            url: `/${locale}${url}`,
            data: QueryString.stringify(requestArgs),
            cancelToken: cancelTokenSource.token,
            ...requestConfig
          }).then((response): Promise<AxiosResponse<ServerResponseSuccess<ResponseType>>> => {
            if (response.data.text && response.data.title && response.data.status) {
              const message: ToastProps = {
                type: response.data.status,
                title: response.data.title,
                text: response.data.text
              }
              showToast(message)
              if (message.type === 'success') {
                return Promise.resolve(response)
              } else {
                return Promise.reject(response)
              }
            }
            return Promise.resolve(response)
          })
          cancelRef.current = cancelTokenSource.cancel
          return { promise, cancel: repetitionBehavior === 'cancel' ? cancelTokenSource.cancel : undefined }
        },
        isCancel: axios.isCancel,
        thenFn: (data: AxiosResponse<ServerResponseSuccess<ResponseType>>, ...args) => {
          if (messages?.success) {
            showToast({
              type: 'success',
              ...messages.success
            })
          }
          thenFn?.(data, ...args)
        },
        catchFn: (error, ...args) => {
          if (messages?.error) {
            if (error.message) {
              showToast({
                type: 'error',
                title: messages?.error?.title,
                text: error?.response?.data?.message ?? error.message ?? messages?.error?.text
              })
            }
          }
          catchFn?.(error, ...args)
        },
        actionAllowed
      }),
      [
        actionAllowed,
        catchFn,
        createRequestData,
        locale,
        messages?.error,
        messages?.success,
        repetitionBehavior,
        skipRequest,
        thenFn
      ]
    ) as TrackedAxiosRequest<Args, ResponseType>),
    cancel: cancelRef
  }
}

export const useDebouncedTrackedAxiosRequest = <Args extends unknown[], ResponseType>(
  trackedRequest: TrackedAxiosRequest<Args, ResponseType>,
  instantRun?: (...args: Args) => unknown,
  wait = 400
): TrackedAxiosRequest<Args, ResponseType> => {
  const _run = trackedRequest.run
  const cancelRef = trackedRequest.cancel
  const run = useMemo(() => {
    let nextArgs: Args
    const update = _.debounce(() => _run(...nextArgs), wait)
    return (...args: Args) => {
      cancelRef.current?.()
      instantRun?.(...args)
      nextArgs = args
      update()
    }
  }, [_run, cancelRef, instantRun, wait])
  return {
    ...trackedRequest,
    run
  }
}

export default useTrackedPromise
