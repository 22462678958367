import { t } from '@lingui/macro'
import { forwardRef, useMemo } from 'react'

import Modal, { ModalRef, useModalActions } from '../../../components/Overlays/Modal'
import useCombinedRef from '../../../scripts/useCombinedRef'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDeleteComplaint } from '../../../data/remote/Complaint'
import { ComplaintData } from '../../../types/ComplaintDataTypes'

type DeleteComplaintModalProps = {
  onSuccess?: (data: ComplaintData) => void
  complaintId: number
}

const DeleteComplaintModal = forwardRef<ModalRef, DeleteComplaintModalProps>(function DeleteComplaintModal(
  { onSuccess, complaintId },
  ref
) {
  const modalActions = useModalActions()
  const modalRef = useCombinedRef(modalActions.ref, ref)

  const deleteComplaint = useDeleteComplaint()

  const { handleSubmit } = useForm<{
    complaintId: number
  }>({
    defaultValues: {
      complaintId: complaintId
    }
  })

  const onSubmit = useMemo(
    () =>
      handleSubmit(() => {
        if (!deleteComplaint.running) {
          deleteComplaint.run(
            {
              id: complaintId
            },
            (response?: ComplaintData) => {
              modalActions.close()
              onSuccess?.(response ? response : ({} as ComplaintData))
            }
          )
        }
      }),
    [handleSubmit, deleteComplaint, complaintId, modalActions, onSuccess]
  )

  return (
    <Modal ref={modalRef}>
      <Modal.Header closeButton>
        <Modal.Title>{t`Reklamation löschen`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <p>{t`Möchten Sie die Reklamation wirklich löschen?`}</p>
          <Row className="mt-3">
            <Col xs={4}>
              <div className="d-grid">
                <Button variant="primary" onClick={modalActions.close} className="mr-2 w-100" size="lg">
                  {t`Abbrechen`}
                </Button>
              </div>
            </Col>
            <Col xs={4} className="ml-auto">
              <div className="d-grid">
                <Button variant="danger" type="submit" size="lg" className="w-100">
                  {t`Löschen`}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
})

export default DeleteComplaintModal
