import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { LanguageData, useLanguages } from '../../data/remote/Language'
import { useCreateSupplier } from '../../data/remote/Supplier'
import { UserDataInput } from '../../data/remote/User'
import { Input, Select } from '../Form'

export type AddProfileFormProps = {
  redirectUrl?: string
}
export const AddProfileForm: React.FC<AddProfileFormProps> = ({ redirectUrl }) => {
  const { data: languages } = useLanguages()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<UserDataInput>()

  const createSupplier = useCreateSupplier()

  const onSubmit = useMemo(
    () => handleSubmit((input: UserDataInput) => createSupplier.run(input, redirectUrl)),
    [createSupplier, handleSubmit, redirectUrl]
  )

  return (
    <Form onSubmit={onSubmit} noValidate>
      <div className="form-row">
        <Col xs={12}>
          <h2>{t`Lieferanteninformationen`}</h2>
        </Col>
        <Col md={3}>
          <Input
            {...register('company', { required: t`Bitte den Lieferantenname eingeben.` })}
            controlId="profile-company"
            label={t`Lieferantenname`}
            required
            groupClass="form-group"
            error={errors.company?.message}
          />
        </Col>
        <Col md={3}>
          <Input
            groupClass="form-group"
            {...register('supplierCode')}
            controlId="profile-supplier-code"
            label={t`Lieferantenkennziffer`}
            placeholder={t`Automatisch generieren`}
            error={errors.supplierCode?.message}
          />
        </Col>
        <Col md={3}>
          <Input
            groupClass="form-group"
            {...register('supplierNumber', { required: t`Bitte die Lieferantennummer eingeben.` })}
            controlId="profile-supplier-number"
            label={t`Lieferantennummer`}
            error={errors.supplierNumber?.message}
            required
          />
        </Col>
        <Col md={3}>
          <Input
            groupClass="form-group"
            {...register('email', { required: t`Bitte eine E-Mail-Adresse eingeben.` })}
            controlId="profile-email"
            type="email"
            label={t`E-Mail-Adresse`}
            error={errors.email?.message}
            required
          />
        </Col>
      </div>
      {languages && (
        <div className="mt-3 form-row">
          <Col xs={12}>
            <h2>System</h2>
          </Col>
          <Col md={3}>
            <Select
              groupClass="form-group"
              {...register('language', { required: t`Bitte eine Sprache auswählen.` })}
              controlId="profile-language"
              label={t`Sprache`}
              error={errors.language?.message}
              required
            >
              {languages.map((language: LanguageData) => {
                return (
                  <option key={language.id} value={language.id}>
                    {language.navigationTitle}
                  </option>
                )
              })}
            </Select>
          </Col>
        </div>
      )}
      <div className="form-row">
        <Col md={6} className="ml-auto">
          <Button variant="primary" type="submit" className="w-100" size="lg" disabled={false}>
            {t`Lieferanten anlegen`}
          </Button>
        </Col>
      </div>
    </Form>
  )
}
