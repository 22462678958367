import React from 'react'
import { Icon } from './Icon'
// eslint-disable-next-line import/no-unresolved
import IconMore from 'jsx:./../assets/icons/more.svg'
import { Dropdown, DropdownProps } from './Overlays/Dropdown'
import { t } from '@lingui/macro'

export type DotsMenuProps = {
  icon?: typeof IconMore
  title?: string
  children?: DropdownProps['items']
}

const DotsMenu: React.FC<DotsMenuProps> = ({ icon, children, title }) => {
  const _title = title ?? t`Mehr Optionen`
  return (
    <Dropdown items={children}>
      <button style={{ fontSize: '1.8rem' }} className="btn p-0">
        <Icon svg={icon ?? IconMore} />
        <div className="visually-hidden">{_title}</div>
      </button>
    </Dropdown>
  )
}

export default DotsMenu
