const isSubsetOf = (needles: unknown[] | undefined, haystack: unknown[] | undefined): boolean | undefined => {
  if (!needles || !haystack || needles.length === 0) {
    return false
  }
  let isPartialSubset = false
  let isFullSubset = true
  for (const needle of needles) {
    if (haystack.includes(needle)) {
      isPartialSubset = true
    } else {
      isFullSubset = false
    }
  }
  return isFullSubset ? true : isPartialSubset ? undefined : false
}

export default isSubsetOf
