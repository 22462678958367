import { t } from '@lingui/macro'
import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { CertificateCodeDataMinimal, useCertificateCodes } from '../../../../data/remote/CertificateCode'
import { OrderDataMinimal } from '../../../../data/remote/Order'
import { ProductDataMinimal } from '../../../../data/remote/Product'
import { SetParamsAction } from '../../../../navigation/createRoute'

export const useCertificateCodesData = ({
  setParams,
  generatePath,
  supplierId,
  orderData,
  productsData,
  certificateCode
}: {
  setParams: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => void
  generatePath: (
    action: SetParamsAction<'Certificates'> | SetParamsAction<'CertificatesBySupplier'>,
    clearOptionals?: boolean | undefined
  ) => string
  supplierId: string | number | undefined
  orderData: ColumnData<OrderDataMinimal>
  productsData: ColumnData<ProductDataMinimal>
  certificateCode: string | undefined
}): ColumnData<CertificateCodeDataMinimal> =>
  useColumnData<CertificateCodeDataMinimal>(
    () => ({
      useItems(search, filter) {
        return useCertificateCodes(
          orderData.current && productsData.current
            ? {
                search,
                filter,
                orderId: orderData.current.id,
                productId: productsData.current.id
              }
            : undefined,
          !!orderData.current && !!productsData.current
        )
      },
      isActiveItem(item) {
        return item.code === certificateCode
      },
      shouldHaveActiveItem: !!orderData.current && !!productsData.current && !!certificateCode,
      onActiveItemMiss() {
        setParams({
          supplierId,
          orderId: orderData.current?.identifier,
          productId: productsData.current?.id,
          certificateCode: undefined
        })
      },
      filterOptions: {
        ['']: t`Alle Zeugniscodes`,
        pending: t`Mit ungeprüften Zeugnissen`,
        denied: t`Mit abgelehnten Zeugnissen`,
        open: t`Mit offenem Status`
      },
      renderItem: (certificateCode) => ({
        id: certificateCode.id,
        title: certificateCode.code,
        url: generatePath({
          supplierId,
          orderId: orderData.current?.identifier,
          productId: productsData.current?.id,
          certificateCode: certificateCode.code
        }),
        gray: certificateCode.status?.approval === 'closed',
        denied: certificateCode.status?.approval === 'denied',
        pending: certificateCode.status?.pending
      })
    }),
    [certificateCode, generatePath, orderData, productsData, setParams, supplierId]
  )
