import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { useSWR } from '../local/hooks/swrHooks'
import { PageContent } from './PageContent'

export type PageData = {
  id: number
  title: string
}

export type PageLanguageData = {
  active: number
  available: number
  current: number
  direction: string
  flag: string
  hreflang: string
  languageId: number
  link: string
  locale: string
  navigationTitle: string
  title: string
  twoLetterIsoCode: string
}

export type UsePageParams = {
  search?: string | null
}

export const usePage = (
  params?: UsePageParams
): {
  loading: boolean
  page: PageData | undefined
  content:
    | {
        colPos0: PageContent[]
      }
    | undefined
  languages: PageLanguageData[] | undefined
} => {
  const location = useLocation()
  const { data, error } = useSWR<{
    page: PageData
    languages: PageLanguageData[]
    content: {
      colPos0: PageContent[]
    }
  }>(location.pathname, JSON.stringify(params))

  const loading = !data && !error
  const languages = data?.languages && _.filter(data?.languages, 'available')

  return {
    loading,
    page: data?.page,
    content: data?.content,
    languages: languages
  }
}
