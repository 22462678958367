import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useRoutes } from '../../../data/remote/RouteData'
import { useServiceStatus } from '../../../data/remote/Settings'
import routeComponents, { unauthorizedRouteNames } from '../../routeComponents'
import LoginLayout from './LoginLayout'
import ServiceLoading from './ServiceLoading'
import ServiceUnavailable from './ServiceUnavailable'

const LoginNavigator: React.FC = () => {
  const { routes } = useRoutes()
  const { loading, available } = useServiceStatus()

  if (loading) {
    return <LoginLayout boxContent={<ServiceLoading />} />
  }

  if (!available || !routes.main.length) {
    return <LoginLayout boxContent={<ServiceUnavailable />} />
  }

  return (
    <LoginLayout
      boxContent={
        <Switch>
          {routes.main.map((route) => {
            const Component = routeComponents[route.component]
            if (!Component || !unauthorizedRouteNames.has(route.component)) {
              return null
            }
            return (
              <Route key={route.path} path={route.path}>
                <Component navigatorType="login" />
              </Route>
            )
          })}
          <Route path="*">{React.createElement(routeComponents.Login)}</Route>
        </Switch>
      }
    />
  )
}

export default LoginNavigator
