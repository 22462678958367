import React from 'react'

export const entrypointNames = ['suppliers', 'orders', 'products', 'certificatecodes'] as const
export type Entrypoint = (typeof entrypointNames)[number]
export const entrypointIndexes = Object.fromEntries(entrypointNames.map((key, i) => [key, i])) as {
  [key in Entrypoint]: number
}

export type SearchSelection = {
  [key in Entrypoint]?: number
}

export type SearchColumnProps = {
  setColumnSelection: (
    value: number,
    switchToColumn: boolean,
    setFilter?: React.Dispatch<React.SetStateAction<{ search: string; filter: string }>>,
    search?: string
  ) => void
  setSelection: React.Dispatch<React.SetStateAction<SearchSelection>>
  selection: SearchSelection
  entrypoint: Entrypoint | undefined
  setEntrypoint: React.Dispatch<React.SetStateAction<Entrypoint | undefined>>
}
