import React, { useMemo } from 'react'
import { Tooltip } from '../Overlays/Tooltip'

import { Status, TableStatusProps } from '../../types/StatusTypes'

const data: {
  [key in Status | string]?: {
    readonly className: string
  }
} = {
  [Status.draft]: {
    className: 'bg-dark text-white'
  },
  [Status.approved]: {
    className: 'bg-success text-white'
  },
  [Status.inProgress]: {
    className: 'bg-warning text-white'
  },
  [Status.open]: {
    className: 'bg-danger text-white'
  }
}

const TableStatus = React.forwardRef<HTMLDivElement, TableStatusProps>(function TableStatusIcon(
  { status = 'pending', size = 22, tooltip, ...props },
  ref
) {
  const style = useMemo<React.CSSProperties>(
    () => ({
      ...props.style,
      width: size + 'px',
      height: size + 'px',
      borderRadius: size / 10 + 'px',
      display: 'inline-block'
    }),
    [props.style, size]
  )

  const { className } = data[status] ?? {}

  return (
    <Tooltip text={tooltip}>
      <div
        ref={ref}
        {...props}
        className={['approval-icon', className, props.className].filter((x) => !!x).join(' ')}
        style={style}
      ></div>
    </Tooltip>
  )
})

export default TableStatus
