import React, { useCallback, useMemo, useState } from 'react'
import CommentsIcon from 'jsx:./../../assets/icons/comments.svg'
import CommentsOffIcon from 'jsx:./../../assets/icons/comments-off.svg'
import { Tooltip } from '../Overlays/Tooltip'
import { CommentTarget, useComments, UseCommentsParams } from '../../data/remote/Comment'
import CommentsList from '../CommentsList'
import Button from 'react-bootstrap/esm/Button'
import { t } from '@lingui/macro'

export type TableCommentsIconProps = {
  forType: Exclude<UseCommentsParams['for'], undefined>['type']
  forId: number
  count?: number
  size?: number
  onOpen?: (target: CommentTarget) => void
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

const TableCommentsIcon = React.forwardRef<HTMLButtonElement, TableCommentsIconProps>(function TableStatusIcon(
  { forType, forId, size = 22, count = 0, onOpen, onClick, ...props },
  ref
) {
  const style = useMemo<React.CSSProperties>(
    () => ({
      ...props.style,
      minWidth: size + 'px',
      height: size + 'px',
      borderRadius: size / 10 + 'px',
      border: 'none',
      width: 'auto'
    }),
    [props.style, size]
  )
  const svgStyle = useMemo<React.CSSProperties>(
    () => ({
      width: 0.8 * size + 'px',
      height: 0.8 * size + 'px',
      margin: 0.1 * size + 'px'
    }),
    [size]
  )
  const labelStyle = useMemo<React.CSSProperties>(
    () => ({
      marginRight: 0.2 * size + 'px'
    }),
    [size]
  )

  const [loadComments, setLoadComments] = useState(false)
  const { data: comments, loading } = useComments({ for: { type: forType, id: forId } }, loadComments)

  const addComment = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      onOpen?.({ type: forType, id: forId })
      onClick?.(e)
    },
    [forId, forType, onClick, onOpen]
  )

  const renderTooltip = useCallback(
    () => (
      <div className="comments-tooltip">
        <div style={{ overflow: 'auto' }}>
          <CommentsList items={comments} loading={loading} />
        </div>
        <div className="d-grid gap-2">
          <Button onClick={addComment} size="sm" className="w-100">{t`Kommentar hinzufügen`}</Button>
        </div>
      </div>
    ),
    [addComment, comments, loading]
  )

  return (
    <Tooltip text={renderTooltip} onOpenChange={setLoadComments} trigger="focus" placement="auto">
      <button
        ref={ref}
        {...props}
        className={[
          'btn d-flex flex-row p-0 approval-icon align-items-center',
          count ? 'bg-primary text-white' : 'text-primary',
          props.className
        ]
          .filter((x) => !!x)
          .join(' ')}
        style={style}
      >
        {count ? (
          <>
            <CommentsIcon style={svgStyle} />
            <div style={labelStyle}>{count > 100 ? '99+' : count}</div>
          </>
        ) : (
          <CommentsOffIcon style={svgStyle} />
        )}
      </button>
    </Tooltip>
  )
})

export default TableCommentsIcon
