import { useCallback } from 'react'
import { createRoute } from '../../../navigation/createRoute'
import CertificateCodeColumn from './Columns/CertificateCodeColumn'
import DataColumn from '../../../components/ListView/DataColumn'
import { t } from '@lingui/macro'
import { useOrderData } from './Columns/useOrderData'
import { useProductsData } from './Columns/useProductsData'
import { useCertificateCodesData } from './Columns/useCertificateCodesData'
import { useSupplierData } from './Columns/useSupplierData'
import CertificateFilesTable from './Columns/CertificateFilesTable'
import FilesHeader from './FilesHeader'

export const CertificatesBySupplier = createRoute(
  'CertificatesBySupplier',
  function CertificatesBySupplier({
    params: { supplierId, orderId, productId, certificateCode },
    generatePath,
    setParams
  }) {
    const supplierData = useSupplierData({
      setParams,
      generatePath,
      supplierId
    })

    const orderData = useOrderData({
      setParams,
      generatePath,
      supplierId,
      orderId,
      enabled: !!supplierData.current
    })

    const productsData = useProductsData({
      setParams,
      generatePath,
      supplierId,
      orderData,
      productId
    })

    const certificateCodesData = useCertificateCodesData({
      setParams,
      generatePath,
      supplierId,
      orderData,
      productsData,
      certificateCode
    })

    const generateCertificateCodePath = useCallback(
      (id: string) => generatePath({ supplierId, orderId, productId, certificateCode: id }),
      [generatePath, orderId, productId, supplierId]
    )

    return (
      <>
        <DataColumn
          title={t`Lieferanten`}
          {...supplierData}
          arrow={supplierId ? 'right' : undefined}
          type="certificate"
        />
        {supplierData.current && (
          <DataColumn title={t`Bestellungen`} {...orderData} arrow={orderId ? 'right' : undefined} />
        )}
        {orderData.current && (
          <DataColumn title={t`Artikel`} {...productsData} arrow={productId ? 'right' : undefined} />
        )}
        {orderData.current && productsData.current && (
          <CertificateCodeColumn
            {...certificateCodesData}
            generatePath={generateCertificateCodePath}
            order={orderData.current}
            product={productsData.current}
            arrow={certificateCode ? 'right' : undefined}
          />
        )}
        <FilesHeader
          order={orderData.current}
          product={productsData.current}
          certificateCode={certificateCodesData.current}
        >
          {!!certificateCodesData.current && <CertificateFilesTable certificateCode={certificateCodesData.current} />}
        </FilesHeader>
      </>
    )
  }
)
