import { t } from '@lingui/macro'
import { uniq } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'

import { Button, Col, Form } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { TypeaheadInput } from '../../../components/Form/TypeaheadInput'
import Modal, { ModalRef, useModalActions } from '../../../components/Overlays/Modal'
import { CertificateDataMinimal, useCertificates } from '../../../data/remote/Certificate'
import { useCertificateCodes, useCreateCertificateCode } from '../../../data/remote/CertificateCode'
import { OrderDataMinimal } from '../../../data/remote/Order'
import { ProductDataMinimal } from '../../../data/remote/Product'
import useCombinedRef from '../../../scripts/useCombinedRef'
import { useDebounce } from '../../../scripts/useDebounce'
import SelectCertificatesTable from './SelectCertificatesTable'

export type AddCertificateCodeModalProps = {
  order?: OrderDataMinimal
  product?: ProductDataMinimal
  existingCodes?: string[]
  generatePath: (certificateCodeId: string) => string
}
export type AddCertificateCodeModalRef = {
  open: () => void
}

const useCertificateProposals = (): {
  onCodeChange: (nextValue: string) => void
  certificates: CertificateDataMinimal[] | undefined
  loading: boolean
  reset: () => void
} => {
  const [query, setQuery] = useState('')
  const delayedQuery = useDebounce(query)

  const { data: certificates, loading } = useCertificates({ code: delayedQuery }, !!delayedQuery)

  return {
    onCodeChange: useCallback((nextValue: string) => {
      setQuery(nextValue)
    }, []),
    certificates,
    loading: query !== delayedQuery || (!!delayedQuery && loading),
    reset: useCallback(() => {
      setQuery('')
    }, [])
  }
}

const AddCertificateCodeModal = React.forwardRef<ModalRef, AddCertificateCodeModalProps>(
  function AddCertificateCodeModal({ existingCodes, order, product, generatePath }, ref) {
    const modalActions = useModalActions()
    const modalRef = useCombinedRef(modalActions.ref, ref)

    const {
      onCodeChange,
      certificates,
      loading: certificatesLoading,
      reset: resetCertificateProposals
    } = useCertificateProposals()

    const {
      register,
      handleSubmit,
      control,
      formState: { errors }
    } = useForm<{
      code: string
      certificates: number[]
    }>()

    const createCertificateCode = useCreateCertificateCode()

    const navigate = useHistory()

    const onSubmit = useMemo(
      () =>
        handleSubmit((data) => {
          if (!createCertificateCode.running && order && product) {
            createCertificateCode.run(
              {
                orderId: order.id,
                productId: product.id,
                ...data
              },
              (data) => {
                navigate.push(generatePath(data.code))
                modalActions.close()
              }
            )
          }
        }),
      [createCertificateCode, generatePath, handleSubmit, navigate, modalActions, order, product]
    )

    const { data } = useCertificateCodes({ search: '' })
    const codes = uniq(data?.filter((x) => !existingCodes?.includes(x.code)).map((x) => x.code))

    return (
      <Modal ref={modalRef} centered size="lg" onHide={resetCertificateProposals}>
        <Modal.Header closeButton>
          <Modal.Title>{t`Zeugniscode hinzufügen`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit} noValidate>
            <div className="form-row">
              <Col xs={12}>
                <TypeaheadInput
                  {...register('code', { required: t`Bitte einen Code eingeben.` })}
                  controlId="profile-code"
                  label={t`Zeugniscode`}
                  required
                  error={errors.code?.message}
                  onChange={onCodeChange}
                  options={codes}
                />
              </Col>
            </div>
            <div className="form-row mt-1">
              <Col xs={12}>
                <Form.Group className="form-group">
                  <Form.Label>{t`Dateien aus Zeugniscode übernehmen`}</Form.Label>
                  <Controller
                    name="certificates"
                    control={control}
                    defaultValue={[]}
                    render={({ field: { value, onChange } }) => (
                      <SelectCertificatesTable
                        certificates={certificates}
                        loading={certificatesLoading}
                        {...{ value, onChange }}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </div>
            <div className="form-row">
              <Col xs={6} className="ml-auto">
                <div className="d-flex justify-content-end">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={createCertificateCode.running}
                    size="lg"
                  >
                    {t`Zeugniscode hinzufügen`}
                  </Button>
                </div>
              </Col>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  }
)

export default AddCertificateCodeModal
