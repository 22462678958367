import { t } from '@lingui/macro'
import { createApiDataHook } from '../../scripts/api'
import { useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { HeatingStatus } from '../../types/StatusTypes'

export type SteelHeatingsData = {
  id: number
  supplier: number
  identifier: string
  increment: string
  status: HeatingStatus
}

export type UseSteelHeatingsParams = {
  search?: string | null
  filter?: string | null
  supplierId?: string | number
}

export type CreateSteelHeatingInput = {
  readonly supplier: string
  readonly identifier: string
  readonly content: string
  readonly file: FileList
}

export const useCreateSteelHeating = (): {
  run: (data: CreateSteelHeatingInput, onSuccess?: (data: SteelHeatingsData) => unknown) => void
  running: boolean
} => {
  const refresh = useRefreshSWR()

  return useTrackedAxiosRequest<
    [data: CreateSteelHeatingInput, onSuccess?: (data: SteelHeatingsData) => unknown],
    SteelHeatingsData
  >(
    () => ({
      createRequestData: ({ ...data }) => {
        return [`/steelheatings`, prefixApiParams<CreateSteelHeatingInput>(data), { method: 'POST' }]
      },
      thenFn: (response, inputData, onSuccess) => {
        refresh(({ path }) => {
          return path.includes('/steelheatings')
        }).then(() => {
          onSuccess?.(response.data.data)
        })
      },
      messages: {
        success: {
          title: t`Speichern erfolgreich`
        },
        error: {
          title: t`Speichern fehlgeschlagen`
        }
      }
    }),
    [refresh]
  )
}

export const useSteelHeatings = createApiDataHook<SteelHeatingsData[], UseSteelHeatingsParams>(
  ({ supplierId, ...params }) => [supplierId ? `/suppliers/${supplierId}/steelheatings` : '/steelheating', params]
)
