import { ContainerBox } from '../../../components/Layout/ContainerBox'
import { AddProfileForm } from '../../../components/Profile/AddProfileForm'
import { FlexContainer } from '../../../components/ListView/FlexContainer'
import { t } from '@lingui/macro'

const SupplierNew: React.FC<{
  baseUrl: string
}> = ({ baseUrl }) => {
  const redirectUrl = baseUrl + '/:id'

  return (
    <FlexContainer padding>
      <h1 className="h2">{t`Neuen Lieferanten anlegen`}</h1>
      <ContainerBox>
        <AddProfileForm redirectUrl={redirectUrl} />
      </ContainerBox>
    </FlexContainer>
  )
}

export default SupplierNew
