import { t } from '@lingui/macro'
import { Validate } from 'react-hook-form'

export const passwordSecurity = (value = '') => {
  if (value.length < 6) {
    return t`Das Passwort muss mindestens 6 Zeichen lang sein.`
  }
  if (!value.match(/\d/)) {
    return t`Das Passwort muss mindestens eine Zahl enthalten.`
  }
  if (!value.match(/\W/)) {
    return t`Das Passwort muss mindestens ein Sonderzeichen enthalten.`
  }
  return true
}

export const optionalPasswordSecurity: Validate<string | undefined, string | undefined> = (value = '') => {
  if (value.length === 0) {
    return true
  }
  return passwordSecurity(value)
}

export const equalTo =
  (getValue: () => string | undefined, message: string): Validate<string | undefined, string | undefined> =>
  (value: string | undefined) => {
    if (value !== getValue()) {
      return message
    }
    return true
  }

export const passwordRepeat = (getValue: () => string | undefined) => (value: string) => {
  return value === getValue() || t`Die Passwörter stimmen nicht überein.`
}
