import { t } from '@lingui/macro'
import React from 'react'
import { Spinner } from 'react-bootstrap'

export const Loading: React.FC = () => {
  return (
    <div className="container-center h-100 p-3 text-center">
      <Spinner animation="border" variant="primary-dark" role="status">
        <span className="visually-hidden">{t`Lädt...`}</span>
      </Spinner>
    </div>
  )
}
