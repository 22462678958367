import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Icon } from '../Icon'
import SearchSvg from 'jsx:./../../assets/icons/search.svg'
import MenuClose from 'jsx:./../../assets/icons/menu-close.svg'
import { t } from '@lingui/macro'

export type SearchProps = {
  title: string
  onSearch: (input: string) => void
  search: string | undefined
}

export const Search: React.FC<SearchProps> = ({ title, onSearch, search }) => {
  const [searchInput, setSearchInput] = useState('')
  const [submitted, setSubmitted] = useState(true)
  const searchField = useRef<HTMLInputElement>(null)

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSubmitted(false)
      if (e.key === 'Enter') {
        setSubmitted(true)
        onSearch?.(searchInput)
      }
    },
    [searchInput, onSearch]
  )

  const onBlur = useCallback(() => {
    if (!submitted) {
      setSubmitted(true)
      onSearch?.(searchInput)
    }
  }, [searchInput, submitted, onSearch])

  const searchIconClick = useCallback(() => {
    if (!searchInput) {
      searchField?.current?.focus()
    } else {
      setSearchInput('')
      onSearch?.('')
    }
  }, [searchInput, searchField, onSearch])

  useEffect(() => {
    setSearchInput(search ?? '')
  }, [search])

  return (
    <div className="listview-search">
      <input
        ref={searchField}
        className="listview-search-input"
        placeholder={title}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
      <button
        className="listview-search-btn"
        onClick={searchIconClick}
        aria-label={searchInput ? t`Suche zurücksetzen` : t`Suchen`}
      >
        <Icon svg={searchInput ? MenuClose : SearchSvg} />
      </button>
    </div>
  )
}
