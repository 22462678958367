import { Permission, usePermissions } from '../../../data/remote/User'
import { createRoute } from '../../../navigation/createRoute'

import DataColumn from '../../../components/ListView/DataColumn'
import { t } from '@lingui/macro'

import { useComplaintData } from './Columns/useComplaintData'
import { useOrderDataForComplaints } from './Columns/useOrderDataForComplaints'
import { useCallback } from 'react'
import ComplaintColumn from './Columns/ComplaintColumn'
import ComplaintFilesTable from './Columns/ComplaintFilesTable'
import FilesHeaderComplaints from './FilesHeaderComplaints'
import { useProductsDataForComplaints } from './Columns/useProductsDataForComplaints'

export const Complaints = createRoute(
  'Complaints',
  function Complaints({ params: { orderId, productId, complaintId }, generatePath, setParams }) {
    const { user, actionAllowed } = usePermissions()

    const supplierId = actionAllowed(Permission.Alle_Reklamationen_verwalten) ? undefined : user?.id

    const orderData = useOrderDataForComplaints({
      setParams,
      generatePath,
      supplierId,
      orderId
    })

    const productsData = useProductsDataForComplaints({
      setParams,
      generatePath,
      orderData,
      productId
    })

    const complaintData = useComplaintData({
      setParams,
      generatePath,
      orderData,
      productsData,
      complaintId
    })

    const generateComplaintPath = useCallback(
      (id: string) => generatePath({ orderId, productId, complaintId: id }),
      [generatePath, orderId, productId]
    )

    // if supplier we filter orderData only to show order with a complaint status > 0
    // if not supplier we show all orders

    return (
      <>
        <DataColumn
          title={t`Bestellungen`}
          {...orderData}
          type="complaint"
          arrow={orderId ? 'right' : undefined}
          searchTitle="Bestellnr./CAQ"
        />
        {orderData.current && (
          <DataColumn title={t`Artikel`} {...productsData} type="complaint" arrow={productId ? 'right' : undefined} />
        )}
        {orderData.current && productsData.current && (
          <ComplaintColumn
            order={orderData.current}
            product={productsData.current}
            {...complaintData}
            generatePath={generateComplaintPath}
          />
        )}
        {!!complaintData.current && (
          <>
            <FilesHeaderComplaints
              order={orderData.current}
              product={productsData.current}
              complaint={complaintData.current}
            >
              <ComplaintFilesTable complaint={complaintData.current} />
            </FilesHeaderComplaints>
          </>
        )}
      </>
    )
  }
)
