import { GroupApprovalStatus } from './Status'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'

export type OrderDataMinimal = {
  id: number
  identifier: string
  supplier: number
  status: GroupApprovalStatus
  supplierCompany: string
  supplierEmail: string
  supplierAltEmail?: string
}

export type OrderData = OrderDataMinimal & {
  items: {
    id: number
    product: number
    sku: string
  }[]
}

export const useOrder = createApiItemHook<OrderData>('/orders')

export type UseOrdersParams = {
  search?: string | null
  filter?: string | null
  searchIn?: string | null
  supplierId?: string | number
  productId?: number
  orderId?: number
}

export const useOrders = createApiDataHook<OrderDataMinimal[], UseOrdersParams>(
  ({ supplierId, productId, ...params }) => [
    supplierId ? `/suppliers/${supplierId}/orders` : productId ? `/products/${productId}/orders` : '/orders',
    params
  ]
)
