import { t } from '@lingui/macro'
import React, { useEffect, useMemo } from 'react'
import { Col, Form, Button, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Input, TextArea } from '../../../components/Form'
import Modal, { ModalRef, useModalActions } from '../../../components/Overlays/Modal'
import { useCreateComplaint } from '../../../data/remote/Complaint'
import useCombinedRef from '../../../scripts/useCombinedRef'
import { OrderDataMinimal } from '../../../data/remote/Order'
import { ProductDataMinimal } from '../../../data/remote/Product'
import { ComplaintData } from '../../../types/ComplaintDataTypes'

export type AddComplaintModalProps = {
  onSuccess?: (data: ComplaintData) => unknown
  order?: OrderDataMinimal
  product?: ProductDataMinimal
  generatePath: (complaintId: string) => string
}

const AddComplaintModal = React.forwardRef<ModalRef, AddComplaintModalProps>(function CreateComplaintModal(
  { onSuccess, order, product },
  ref
) {
  const modalActions = useModalActions()
  const modalRef = useCombinedRef(modalActions.ref, ref)

  const { register, handleSubmit, setValue } = useForm<{
    order: string
    product: string
    caq: string
    description: string
    status: number
  }>({
    defaultValues: {
      order: order ? order.id.toString() : '',
      product: product ? product.id.toString() : '',
      caq: '',
      description: '',
      status: 0
    }
  })

  useEffect(() => {
    if (order) {
      setValue('order', order.id.toString())
    }
  }, [order, setValue])

  useEffect(() => {
    if (product) {
      setValue('product', product.id.toString())
    }
  }, [product, setValue])

  const createComplaint = useCreateComplaint()

  const onSubmit = useMemo(
    () =>
      handleSubmit((data) => {
        if (!createComplaint.running) {
          createComplaint.run(data, (response) => {
            modalActions.close()
            onSuccess?.(response)
          })
        }
      }),
    [createComplaint, handleSubmit, modalActions, onSuccess]
  )

  return (
    <Modal ref={modalRef}>
      <Modal.Header closeButton>
        <Modal.Title>{t`Reklamation anlegen`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <Row className="form-row">
            <Col xs={12}>
              <Input label={t`CAQ-Nummer`} {...register('caq', { required: true })} />
            </Col>
          </Row>
          <Row className="form-row">
            <Col xs={12} className="mt-3">
              <TextArea
                label={t`Kurzbeschreibung`}
                {...register('description', { required: true })}
                controlId={'description'}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={4}>
              <div className="d-grid">
                <Button variant="secondary" type="button" size="lg" className="w-100" onClick={modalActions.close}>
                  {t`Abbrechen`}
                </Button>
              </div>
            </Col>
            <Col xs={4} className="ml-auto">
              <div className="d-grid">
                <Button variant="primary" type="submit" size="lg" className="w-100">
                  {t`Speichern`}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
})

export default AddComplaintModal
