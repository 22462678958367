import { TooltipProps } from '../components/Overlays/Tooltip'

export enum Status {
  draft,
  approved,
  inProgress,
  open
}

export type HeatingStatus = {
  approval?: 'approved' | 'pending' | 'completed' | null
  checkedby?: string | null
  checkedDate?: string | null
}

export type SteelCertificateStatus = {
  approval?: 'approved' | 'pending' | 'completed' | null
}

export type TableStatusProps = {
  status?: Status
  size?: number
  tooltip?: TooltipProps['text']
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
