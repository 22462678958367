import React, { useCallback } from 'react'
import classNames from 'classnames'
import OverlayTrigger, { OverlayTriggerProps } from './OverlayTrigger'
import { makeResizableOverlay } from './shared'
import { Popover } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export type DropdownProps = Omit<OverlayTriggerProps, 'overlay'> & {
  content?: string | number | React.ReactElement
  items?: React.ReactElement | (React.ReactElement | false | undefined | null)[]
}

const UpdatingPopover = makeResizableOverlay(Popover)

const defaultTrigger: OverlayTriggerProps['trigger'] = ['click', 'focus']

export type DropdownItemProps = {
  href?: string
  link?: string
  onClick?: () => void
  className?: string
  disabled?: boolean
  children?: React.ReactNode
  textColor?: 'danger'
}
export const DropdownItem: React.FC<DropdownItemProps> = ({
  href,
  link,
  onClick,
  className,
  disabled,
  textColor,
  children
}) => {
  if (!disabled && href) {
    return (
      <a href={href} target="_blank" rel="noreferrer" className={classNames([className, 'dropdown-item'])}>
        {children}
      </a>
    )
  }
  if (!disabled && link) {
    return (
      <Link to={link} className={classNames([className, 'dropdown-item'])}>
        {children}
      </Link>
    )
  }
  return (
    <button
      onClick={disabled ? undefined : onClick}
      className={classNames([className, 'dropdown-item', textColor && 'text-' + textColor])}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export const DropdownSeperator: React.FC = () => <div className="dropdown-divider"></div>

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  content,
  items,
  trigger = defaultTrigger,
  placement = 'bottom',
  ...props
}) => {
  const renderOverlay = useCallback(
    (props: DropdownProps) => (
      <UpdatingPopover id="" content="true" {...props} className="popover-dropdown">
        {content}
        {items}
      </UpdatingPopover>
    ),
    [content, items]
  )

  return (
    <OverlayTrigger {...props} trigger={trigger} placement={placement} overlay={renderOverlay} delay={200}>
      {children}
    </OverlayTrigger>
  )
}
