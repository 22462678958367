import { createRoute } from '../../../navigation/createRoute'
import RequestEmail from './RequestEmail'
import ResetPassword from './ResetPassword'

export const ForgotPassword = createRoute('ForgotPassword', ({ params: { confirmationHash, userId } }) => {
  if (userId && confirmationHash) {
    return <ResetPassword userId={Number(userId)} confirmationHash={confirmationHash} />
  }

  return <RequestEmail />
})
