import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import {
  PageContent,
  Typo3ContentLayout,
  Typo3ContentLayoutClasses,
  Typo3ContentSpace,
  Typo3ContentSpaceClasses
} from '../../data/remote/PageContent'
import TextPicModule from './TextPicModule'

export type ContentElementsProps = {
  contents?: PageContent[]
  colPos?: number
}

const modules: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in PageContent['type']]?: React.FC<{ data: any }>
} = {
  text: TextPicModule,
  textpic: TextPicModule,
  image: TextPicModule,
  textmedia: TextPicModule
}

const layoutClass = (layout: Typo3ContentLayout) => {
  if (layout in Typo3ContentLayoutClasses) {
    return 'content-element-layout-' + Typo3ContentLayoutClasses[layout]
  }
  return ''
}

const spacerClass = (spacer: Typo3ContentSpace, location: 'top' | 'bottom'): string => {
  if (spacer in Typo3ContentSpaceClasses) {
    return `m${location.substring(0, 1)}-${Typo3ContentSpaceClasses[spacer]}`
  }
  return ''
}

const ContentElements: React.FC<ContentElementsProps> = ({ contents = [] }) => {
  const history = useHistory()

  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault()
      const target = e.target as HTMLElement
      if (target.tagName === 'A' && (target as HTMLAnchorElement).href.startsWith(location.origin)) {
        const href = (target as HTMLAnchorElement).href.substring(location.origin.length)
        history.push(href)
      } else {
        const href = target.closest('a')?.href
        if (href) {
          window.open(href, '_blank')
        }
      }
    },
    [history]
  )

  return (
    <div className="content-elements" onClick={handleClick}>
      {contents.map((item) => (
        <div
          key={item.id}
          id={`content-${item.id}`}
          className={[
            'content-element',
            `content-element-${item.type}`,
            layoutClass(item.appearance.layout),
            spacerClass(item.appearance.spaceBefore, 'top'),
            spacerClass(item.appearance.spaceAfter, 'bottom')
          ]
            .filter((x) => !!x)
            .join(' ')}
        >
          {item.type in modules ? React.createElement(modules[item.type]!, { data: item }) : ''}
        </div>
      ))}
    </div>
  )
}

export default ContentElements
