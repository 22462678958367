import { plural, t } from '@lingui/macro'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useRef } from 'react'

import DataTable, { DataTableItem, dynamicRows, sectionHeader } from '../../../../components/DataTable/DataTable'
import TableStatusIcon from '../../../../components/DataTable/TableStatusIcon'
import DotsMenu from '../../../../components/DotsMenu'
import { DropdownItem, DropdownSeperator } from '../../../../components/Overlays/Dropdown'
import { useUploadFiles } from '../../../../data/remote/FileData'
import { getStatusText, RegulationFileData } from '../../../../data/remote/RegulationFile'
import { useAuthentication } from '../../../../data/remote/User'
import ManageConfirmationsModal, { ManageConfirmationsModalRef } from '../Modals/ManageConfirmationsModal'

export type RegulationFilesTableSupplierProps = {
  data?: RegulationFileData[]
  loading?: boolean
  folderId?: number
  isSpecificFiles?: boolean
}

const MoreMenu: React.FC<{
  item: RegulationFileData
  manageConfirmations: React.RefObject<ManageConfirmationsModalRef>
  supplierId?: number
}> = ({ item, manageConfirmations, supplierId }) => {
  const [uploadFiles] = useUploadFiles({
    type: 'confirmationfile',
    regulationFileId: item.id,
    supplierId: supplierId ?? -1
  })

  return (
    <DotsMenu>
      <DropdownItem href={item.file.publicUrl}>{t`Datei öffnen`}</DropdownItem>
      {!!item.interval && (
        <>
          <DropdownSeperator />
          <DropdownItem onClick={uploadFiles}>{t`Upload zu dieser Datei`}</DropdownItem>
          <DropdownItem
            disabled={item.confirmationFiles === 0}
            onClick={() => {
              manageConfirmations.current?.open(item)
            }}
          >{t`Uploads verwalten`}</DropdownItem>
        </>
      )}
    </DotsMenu>
  )
}

const RegulationFilesTableSupplier: React.FC<RegulationFilesTableSupplierProps> = ({
  data,
  loading,
  folderId = -1,
  isSpecificFiles
}) => {
  const columns = useMemo(
    () =>
      isSpecificFiles
        ? {
            name: t`Dateiname`,
            date: t`Änderungsdatum`,
            menu: ''
          }
        : {
            name: t`Dateiname`,
            interval: t`Intervall`,
            date: t`Änderungsdatum`,
            status: t`Status`,
            menu: ''
          },
    [isSpecificFiles]
  )

  const [uploadFiles, canUploadFiles] = useUploadFiles({
    type: 'regulationfile',
    folderId
  })

  const items = useMemo<DataTableItem<RegulationFileData>[]>(
    () => [
      ...(canUploadFiles ? sectionHeader({ newLabel: t`Dateien hinzufügen`, onNew: uploadFiles }) : []),
      ...dynamicRows(data, loading, t`Für diesen Ordner wurden noch keine Dateien hochgeladen.`)
    ],
    [canUploadFiles, data, loading, uploadFiles]
  )

  const columnClasses = useMemo(
    () => ({
      menu: 'text-right'
    }),
    []
  )

  const manageConfirmations = useRef<ManageConfirmationsModalRef>(null)

  const { user } = useAuthentication()

  return (
    <>
      {/* @ts-ignore */}
      <DataTable items={items} columns={columns} columnClasses={columnClasses}>
        {useCallback(
          (item: RegulationFileData) => ({
            name: (
              <a href={item.file.publicUrl} target="_blank" className="text-break" rel="noreferrer">
                {item.file.properties.title}
              </a>
            ),
            interval: item.interval
              ? plural(item.interval, {
                  one: 'Jeden Monat',
                  other: 'Alle # Monate'
                })
              : '',
            date: dayjs(item.tstamp).format('L'),
            status: item.interval ? (
              <TableStatusIcon
                status={item.status.pending ? 'pending' : item.status.approval ? item.status.approval : 'open'}
                tooltip={getStatusText(item.status, true)}
              />
            ) : undefined,
            menu: <MoreMenu item={item} manageConfirmations={manageConfirmations} supplierId={user?.id} />
          }),
          [user?.id]
        )}
      </DataTable>
      <ManageConfirmationsModal ref={manageConfirmations} />
    </>
  )
}

export default RegulationFilesTableSupplier
