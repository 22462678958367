import { createApiDataHook } from '../../scripts/api'

export type SteelProductsData = {
  id: number
  lsw: string
  material: string
  title: string
  width: number
  height: number
}

export const useSteelProducts = createApiDataHook<SteelProductsData[], never>('/steelproducts')
