import React, { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useLocalizedPath } from '../data/remote/Language'

export type LocalizedLinkProps = {
  to: string | number | undefined
  title?: string
  className?: string
  component?: React.ComponentType | undefined
  componentIsLink?: boolean
  children?: React.ReactNode
}

const LocalizedLink: React.FC<LocalizedLinkProps> = ({
  children,
  to,
  title,
  className,
  component,
  componentIsLink
}) => {
  const localizedPath = useLocalizedPath()

  const _component = useMemo(() => {
    if (component && componentIsLink) {
      return function WrappedLink(
        props: React.PropsWithChildren<{ navigate: () => void; href: string }>
      ): React.ReactNode {
        const { navigate, href } = props
        const onClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
          (e) => {
            e?.preventDefault?.()
            navigate()
          },
          [navigate]
        )
        return React.createElement(
          component as React.ComponentType<
            React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
          >,
          {
            href,
            onClick
          },
          props.children
        )
      }
    } else {
      return component
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, [component, componentIsLink]) as React.ComponentType<any>

  return (
    <Link
      to={localizedPath(to) ?? (typeof to === 'string' ? to : '')}
      title={title}
      className={className}
      component={_component}
    >
      {children}
    </Link>
  )
}

export default LocalizedLink
