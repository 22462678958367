import { t } from '@lingui/macro'
import DataColumn, { useColumnData } from '../../../components/ListView/DataColumn'
import { Header } from '../../../components/ListView/Header'
import { useConfirmationFiles } from '../../../data/remote/ConfirmationFile'
import { RegulationFileDataMinimal, useRegulationFiles } from '../../../data/remote/RegulationFile'
import { SupplierDataMinimal, useSuppliers } from '../../../data/remote/Supplier'
import { createRoute } from '../../../navigation/createRoute'
import { toNumber } from '../../../scripts/utils'
import ConfirmationFilesTable from './Columns/ConfirmationFilesTable'

const FileCheck = createRoute(
  'FileCheck',
  function FileCheck({ params: { regulationFileId, supplierId }, generatePath, setParams }) {
    const supplierData = useColumnData<SupplierDataMinimal>(
      () => ({
        useItems(search, filter) {
          return useSuppliers({ search, filter })
        },
        isActiveItem(item) {
          return toNumber(supplierId) === item.id
        },
        shouldHaveActiveItem: !!supplierId,
        onActiveItemMiss() {
          setParams({}, true)
        },
        renderItem(supplier) {
          return {
            id: supplier.id,
            title: supplier.company,
            url: generatePath({ supplierId: supplier.id }, true),
            gray: supplier.status.regulations.approval === 'closed',
            denied: supplier.status.regulations.approval === 'denied',
            uploadMissing: supplier.status.regulations.approval === 'open',
            pending: supplier.status.regulations.pending
          }
        },
        filterOptions: {
          ['']: t`Alle Lieferanten`,
          regulation_pending: t`Mit ungeprüften Bestätigungen`,
          regulation_denied: t`Mit abgelehnten Bestätigungen`,
          regulation_open: t`Mit ausstehenden Uploads`
        }
      }),
      [generatePath, setParams, supplierId]
    )

    const regulationFileData = useColumnData<RegulationFileDataMinimal>(
      () => ({
        useItems(search, filter) {
          return useRegulationFiles({ search, filter, supplierId: supplierData.current?.id }, !!supplierData.current)
        },
        isActiveItem(regulationFile) {
          return regulationFile.id === toNumber(regulationFileId)
        },
        shouldHaveActiveItem: !!supplierData.current && !!regulationFileId,
        onActiveItemMiss() {
          setParams({ supplierId }, true)
        },
        renderItem(file) {
          return {
            id: file.id,
            title: file.file.properties.title,
            url: generatePath({ supplierId, regulationFileId: file.id }, true),
            // gray: file.status.approval === 'closed',
            denied: file.status.approval === 'denied',
            uploadMissing: file.status.approval === 'open',
            pending: file.status.pending
          }
        },
        filterOptions: {
          ['']: t`Alle Dateien`,
          regulation_pending: t`Mit ungeprüften Bestätigungen`,
          regulation_denied: t`Mit abgelehnten Bestätigungen`,
          regulation_open: t`Mit ausstehenden Uploads`
        }
      }),
      [generatePath, regulationFileId, setParams, supplierData, supplierId]
    )

    const { data: files, loading: filesLoading } = useConfirmationFiles(
      { supplierId: supplierData.current?.id, regulationFileId: regulationFileData.current?.id },
      !!supplierData.current?.id && !!regulationFileData.current?.id
    )

    return (
      <>
        <DataColumn title={t`Lieferanten`} {...supplierData} arrow={supplierId ? 'right' : undefined} />
        {!!supplierData.current && (
          <DataColumn title={t`Dateien`} {...regulationFileData} arrow={regulationFileId ? 'right' : undefined} />
        )}
        {!!regulationFileData.current && (
          <div className="d-flex flex-grow-1 flex-shrink-1 flex-column">
            <Header title={t`Bestätigungsdateien`} />
            <ConfirmationFilesTable data={files} loading={filesLoading} />
          </div>
        )}
      </>
    )
  }
)

export default FileCheck
