import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { createApiDataHook, createApiItemHook } from '../../scripts/api'
import { TrackedAxiosRequest, useTrackedAxiosRequest } from '../../scripts/useTrackedPromise'
import { prefixApiParams } from '../../scripts/utils'
import { useRefreshSWR } from '../local/hooks/swrHooks'
import { ApprovalStatus } from './Status'
import { usePermission } from './User'
import { FileData } from '../../types/FileDataTypes'

export type ConfirmationFileDataMinimal = {
  id: number
  file: FileData
  interval: number
  tstamp: string
  crdate: string
  regulationFile: number
  status: {
    approval: ApprovalStatus
    checkedBy: string
    checkedDate: string
  }
}

export const getConfirmationFileStatusText = (status: ConfirmationFileDataMinimal['status']): string => {
  switch (status.approval) {
    case 'approved':
      const approvedCheckedBy = status.checkedBy ? t` durch ${status.checkedBy}` : ''
      const approvedCheckedDate = status.checkedDate ? t` am ${dayjs(status.checkedDate).format('L')}` : ''
      return t`Bestätigt ${approvedCheckedBy} ${approvedCheckedDate}`
    case 'denied':
      const deniedCheckedBy = status.checkedBy ? t` durch ${status.checkedBy}` : ''
      const deniedCheckedDate = status.checkedDate ? t` am ${dayjs(status.checkedDate).format('L')}` : ''
      return t`Abgelehnt ${deniedCheckedBy} ${deniedCheckedDate}`
    default:
      return t`Bestätigung ausstehend`
  }
}

export type ConfirmationFileData = ConfirmationFileDataMinimal

export const useConfirmationFile = createApiItemHook<ConfirmationFileData>('/confirmationfiles')

export type UseConfirmationFilesParams = {
  supplierId?: number
  regulationFileId?: number
}

export const useConfirmationFiles = createApiDataHook<ConfirmationFileDataMinimal[], UseConfirmationFilesParams>(
  ({ supplierId, regulationFileId }) => [
    supplierId && regulationFileId
      ? `/suppliers/${supplierId}/regulationfiles/${regulationFileId}/confirmationfiles`
      : null,
    {}
  ]
)

export const useCanApproveConfirmationFiles = (): boolean => usePermission('Alle_Dateien_pruefen')

export const useUpdateConfirmationFileApproval = (
  fileId: number
): TrackedAxiosRequest<[{ approval: 'pending' | 'approved' | 'denied' }]> & {
  approve: () => void
  deny: () => void
} => {
  const refresh = useRefreshSWR()
  const actionAllowed = useCanApproveConfirmationFiles()
  const result = useTrackedAxiosRequest<
    [
      {
        approval: 'pending' | 'approved' | 'denied'
      }
    ],
    unknown
  >(
    () => ({
      createRequestData: (args) => {
        return [
          `/confirmationfiles/${fileId}`,
          prefixApiParams<{ approval: 'pending' | 'approved' | 'denied' }>(args),
          { method: 'PATCH' }
        ]
      },
      thenFn: () => {
        refresh()
      },
      messages: {
        error: {
          title: t`Die Datei konnte nicht angepasst werden.`
        }
      },
      actionAllowed
    }),
    [actionAllowed, fileId, refresh]
  )

  return useMemo(
    () => ({
      ...result,
      approve: () => result.run({ approval: 'approved' }),
      deny: () => result.run({ approval: 'denied' })
    }),
    [result]
  )
}
