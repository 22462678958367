import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useSWROriginal, { SWRResponse, useSWRConfig } from 'swr'
import { useSelector } from '.'
import swrRequests from '../modules/swrRequests'
import { i18n } from '@lingui/core'
import { defaultLocale } from '../../../scripts/config'

const getPathLocale = (path: string): string | undefined => {
  const _path = path.startsWith('/') ? path : `/${path}`
  if (_path.length === 3 || _path.substring(3, 4) === '/') {
    return _path.substring(1, 3)
  }
}

const useCurrentLocale = (): string => {
  const [locale, setLocale] = useState(i18n.locale)
  useEffect(
    () =>
      i18n.on('change', () => {
        setLocale(i18n.locale)
      }),
    []
  )
  return locale
}

export const useSWR = <ResponseType, ErrorType = Error>(
  path: string | null,
  requestData?: string
): SWRResponse<ResponseType, ErrorType> => {
  const locale = useCurrentLocale() ?? defaultLocale
  if (typeof path === 'string' && !getPathLocale(path)) {
    path = `/${locale}${path}`
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(swrRequests.actions.openKey({ path, requestData }))
    return () => {
      dispatch(swrRequests.actions.closeKey({ path, requestData }))
    }
  }, [path, requestData, dispatch])

  return useSWROriginal(requestData && path ? [path, requestData] : path)
}

export const useRefreshSWR = (): ((
  refresh?: (key: { path: string; requestData: string | undefined }) => boolean
) => Promise<
  {
    path: string
    requestData: string | undefined
    data: unknown
  }[]
>) => {
  const keys = useSelector((state) => state.swrRequests)
  const { mutate } = useSWRConfig()

  return useCallback(
    (refresh?: (key: { path: string; requestData: string | undefined }) => boolean) => {
      const result: Promise<{
        path: string
        requestData: string | undefined
        data: unknown
      }>[] = []
      for (const el of keys) {
        const { path, requestData } = el
        if (!refresh || refresh({ path, requestData })) {
          result.push(
            mutate(requestData ? [path, requestData] : path, undefined, true).then((data) => ({
              path,
              data,
              requestData
            }))
          )
        }
      }
      return Promise.all(result)
    },
    [keys, mutate]
  )
}
