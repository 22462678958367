import { t } from '@lingui/macro'
import mailSvg from 'jsx:./../../../assets/icons/envelope.svg'
import React, { useCallback } from 'react'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DotsMenu from '../../../components/DotsMenu'
import { Header, HeaderToolbarButton } from '../../../components/ListView/Header'
import { SteelCertificatesData, useCanManageSteelCertificates } from '../../../data/remote/SteelCertificates'
import { SteelHeatingsData } from '../../../data/remote/SteelHeatings'
import { SupplierDataMinimal, useSupplierData } from '../../../data/remote/Supplier'

import { useUploadSteelCertificateFile } from '../../../data/remote/FileData'
import { useSettings } from '../../../data/remote/Settings'

type FilesHeaderSteelworksProps = {
  children?: React.ReactNode
  steelHeating: SteelHeatingsData | undefined
  certificate: SteelCertificatesData | undefined
  supplier: SupplierDataMinimal
}

const FilesHeaderSteelworks: React.FC<FilesHeaderSteelworksProps> = ({
  children,
  steelHeating,
  certificate,
  supplier
}) => {
  const canManageSteelCertificates = useCanManageSteelCertificates()
  const { settings } = useSettings()

  const { data: supplierData } = useSupplierData(supplier.id)

  const sendEmail = useCallback(() => {
    if (!steelHeating || !settings || !supplierData) {
      return
    }
    let subject = t`Schmelzennummer: ${steelHeating.identifier}`
    if (certificate) {
      subject += t`, Abmessungen: ${certificate?.product.title}`
    }
    const body = t`Sehr geehrte Damen und Herren,`
    const link = `mailto:${encodeURIComponent(supplierData?.email)}?subject=${encodeURIComponent(
      subject
    )}&bcc=${encodeURIComponent(settings.steelworks.email)}&body=${encodeURIComponent(body)}`
    location.href = link
  }, [certificate, settings, steelHeating, supplierData])

  const [uploadSteelHeatingFiles] = useUploadSteelCertificateFile({
    type: 'steelcertificatefile',
    steelcertificatesId: certificate?.id.toString()
  })

  return (
    <div className="d-flex flex-column flex-grow-1 bg-white">
      <>
        <Header
          title={t`Zeugnis`}
          toolbar={
            canManageSteelCertificates ? (
              <>
                {!!steelHeating && (
                  <HeaderToolbarButton icon={mailSvg} title={t`E-Mail an den Lieferanten`} onClick={sendEmail} />
                )}
                {certificate && (
                  <DotsMenu>
                    {certificate.protocolUrl ? (
                      <DropdownItem
                        onClick={() => window.open(certificate.protocolUrl, '_blank')}
                      >{t`WEK-Protokoll öffnen`}</DropdownItem>
                    ) : null}
                    {certificate.certificateUrl &&
                    (certificate.status.approval === 'approved' || certificate.status.approval === 'completed') ? (
                      <DropdownItem onClick={() => window.open(certificate.certificateUrl, '_blank')}>
                        {t`Zeugnis öffnen`}
                      </DropdownItem>
                    ) : null}
                    {certificate.originalFile && (
                      <DropdownItem onClick={() => window.open(certificate.originalFile.publicUrl, '_blank')}>
                        {t`Originaldatei öffnen`}
                      </DropdownItem>
                    )}
                    {certificate.status.approval === 'pending' && (
                      <DropdownItem
                        onClick={() => uploadSteelHeatingFiles()}
                      >{t`Werte aus PDF importieren`}</DropdownItem>
                    )}
                  </DotsMenu>
                )}
              </>
            ) : undefined
          }
        />
        {children}
      </>
    </div>
  )
}

export default FilesHeaderSteelworks
