import { t } from '@lingui/macro'
import React from 'react'

export const Error404: React.FC = () => {
  return (
    <div className="container-center p-3 text-center">
      <div className="error-title">404</div>
      <div className="error-subline">{t`Die gewünschte Seite existiert nicht (mehr).`}</div>
    </div>
  )
}
