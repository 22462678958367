import { t } from '@lingui/macro'
import { useCallback } from 'react'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'
import DotsMenu from '../../../components/DotsMenu'
import { CommentTarget } from '../../../data/remote/Comment'

import { InternalFileDataMinimal, useUpdateInternalFileVisibility } from '../../../data/remote/InternalFile'

type InternalFilesMenuProps = {
  file: InternalFileDataMinimal
  addCommentClick: () => void
  toggleVisibility: () => void
  canComment: boolean
  canManage: boolean
}

const InternalFilesMenuStructure = ({
  file,
  addCommentClick,
  toggleVisibility,
  canComment,
  canManage
}: InternalFilesMenuProps) => {
  return (
    <DotsMenu>
      {canComment && <DropdownItem key="comment" onClick={addCommentClick}>{t`Kommentar hinzufügen`}</DropdownItem>}
      <DropdownItem href={file.file.publicUrl} target="_blank" key="download">{t`Öffnen`}</DropdownItem>
      {canManage && (
        <DropdownItem key="visibility" onClick={toggleVisibility}>
          {file.public ? t`Datei unsichtbar schalten` : t`Datei auf "öffentlich" stellen`}
        </DropdownItem>
      )}
      {/* <DropdownItem key="delete" textColor="danger">
      Löschen
    </DropdownItem> */}
    </DotsMenu>
  )
}

const InternalFileMenu: React.FC<{
  file: InternalFileDataMinimal
  canManage: boolean
  canComment?: boolean
  addComment?: (target: CommentTarget) => void
}> = ({ file, canManage, canComment, addComment }) => {
  const addCommentClick = useCallback(() => {
    addComment?.({
      type: 'internalfiles',
      id: file.id
    })
  }, [addComment, file.id])

  const updateInternalFileVisibility = useUpdateInternalFileVisibility()
  const toggleVisibility = useCallback(() => {
    updateInternalFileVisibility.run({
      id: file.id,
      public: !file.public
    })
  }, [file.id, file.public, updateInternalFileVisibility])

  return (
    <InternalFilesMenuStructure
      file={file}
      addCommentClick={addCommentClick}
      toggleVisibility={toggleVisibility}
      canComment={canComment ? canComment : false}
      canManage={canManage}
    />
  )
}

export default InternalFileMenu
