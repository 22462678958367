import { plural, t } from '@lingui/macro'
import dayjs from 'dayjs'
import React, { useCallback, useMemo } from 'react'
import Checkbox from '../../../../components/Checkbox'
import DataTable, { DataTableItem, dynamicRows } from '../../../../components/DataTable/DataTable'
import DotsMenu from '../../../../components/DotsMenu'
import { DropdownItem } from '../../../../components/Overlays/Dropdown'
import { useLocalizedPath } from '../../../../data/remote/Language'
import { RegulationFileData } from '../../../../data/remote/RegulationFile'
import { SupplierDataMinimal } from '../../../../data/remote/Supplier'

export type SelectFilesRegulationFilesTableProps = {
  data?: RegulationFileData[]
  supplier?: SupplierDataMinimal
  value?: number[]
  onChange?: (nextValue: number[]) => unknown
  loading?: boolean
}

const SelectFilesRegulationFilesTable: React.FC<SelectFilesRegulationFilesTableProps> = ({
  data,
  loading,
  value,
  supplier,
  onChange
}) => {
  const columns = useMemo(
    () => ({
      select: '',
      name: t`Dateiname`,
      interval: t`Intervall`,
      date: t`Änderungsdatum`,
      menu: ''
    }),
    []
  )

  const items = useMemo<DataTableItem<RegulationFileData>[]>(
    () => dynamicRows(data, loading, t`Für diesen Ordner wurden noch keine Dateien hochgeladen.`),
    [data, loading]
  )

  const columnClasses = useMemo(
    () => ({
      select: 'checkbox-column',
      name: 'pl-0',
      menu: 'text-right'
    }),
    []
  )

  const toggleFile = useCallback(
    (id?: number, isChecked?: boolean) => {
      if (typeof id === 'undefined') {
        return
      }
      if (isChecked === false) {
        onChange?.((value ?? []).filter((x) => x !== id))
      } else {
        onChange?.([...(value ?? []), id])
      }
    },
    [onChange, value]
  )

  const localizedPath = useLocalizedPath()

  return (
    <DataTable items={items} columns={columns} columnClasses={columnClasses} greyHeader>
      {useCallback(
        (item: RegulationFileData) => ({
          select: (
            <Checkbox value={value?.includes(item?.id)} onChange={(isChecked) => toggleFile(item?.id, isChecked)} />
          ),
          name: (
            <a href={item.file.publicUrl} target="_blank" className="text-break" rel="noreferrer">
              {item.file.properties.title}
            </a>
          ),
          interval: item.interval
            ? plural(item.interval, {
                one: 'Jeden Monat',
                other: 'Alle # Monate'
              })
            : '',
          date: dayjs(item.tstamp).format('L'),
          menu: (
            <DotsMenu>
              <DropdownItem
                link={localizedPath(`/dateipruefung/`) + '/' + supplier?.id + '/' + item.id}
              >{t`Zur Dateiprüfung`}</DropdownItem>
            </DotsMenu>
          )
        }),
        [localizedPath, supplier?.id, toggleFile, value]
      )}
    </DataTable>
  )
}

export default SelectFilesRegulationFilesTable
