import React, { useEffect, useMemo, useState } from 'react'
import { i18n, Messages } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { defaultLocale } from '../../scripts/config'
import { UserData } from '../../data/remote/User'
import { getPathLocale, LanguageData, useLocalizedRedirect } from '../../data/remote/Language'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import { en, de } from 'make-plural/plurals'
import { useSWR } from '../../data/local/hooks/swrHooks'
import { isArray } from 'lodash'

dayjs.extend(localizedFormat)

i18n.loadLocaleData('en', { plurals: en })
i18n.loadLocaleData('de', { plurals: de })

i18n.on('change', () => {
  dayjs.locale(i18n.locale)
})

type LanguageProviderProps = {
  children: React.ReactNode
}

const selectLanguagePromises: { [key: string]: Promise<void> } = {}
const selectLanguage = async (key: string): Promise<void> => {
  let promise = selectLanguagePromises[key]
  if (!promise) {
    const loadLang = ({ messages }: { messages: Messages }) => {
      i18n.load(key, messages)
    }
    switch (key) {
      case 'de':
        promise = import('../../locales/de').then(loadLang)
        break
      case 'en':
        promise = import('../../locales/en').then(loadLang)
        break
      default:
        promise = Promise.reject('Unknown language')
    }
    selectLanguagePromises[key] = promise
  }
  return promise.then(() => i18n.activate(key))
}

const usePreferredLocale = (): string | undefined => {
  const { data } = useSWR<{ user: UserData }>('/de/user')
  const { data: languages } = useSWR<LanguageData[]>('/de/languages')

  return useMemo((): string | undefined => {
    if (!languages) {
      return undefined
    }
    if (typeof data?.user?.language !== 'undefined') {
      const lang = Array.isArray(languages) ? languages?.find((x) => x.id === data.user.language) : undefined
      if (lang?.twoLetterIsoCode) {
        return lang.twoLetterIsoCode
      } else {
        return defaultLocale
      }
    }

    const languageCodes =
      isArray(languages) && languages.length > 0 ? languages.map((x) => x.twoLetterIsoCode) : [defaultLocale]
    const urlLocale = getPathLocale(location.pathname)
    if (urlLocale && languageCodes.includes(urlLocale)) {
      return urlLocale
    }
    const navigatorLang = navigator.languages.map((x) => x.substring(0, 2)).find((x) => languageCodes.includes(x))
    return navigatorLang ?? defaultLocale
  }, [languages, data])
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  const [loaded, setLoaded] = useState(false)
  const preferredLocale = usePreferredLocale()
  useEffect(() => {
    if (preferredLocale && preferredLocale !== i18n.locale) {
      selectLanguage(preferredLocale).then(() => setLoaded(true))
    }
  }, [preferredLocale])

  const pathLocale = getPathLocale(location.pathname)
  const localizedRedirect = useLocalizedRedirect()
  useEffect(() => {
    if (preferredLocale && pathLocale !== preferredLocale) {
      localizedRedirect(location.pathname, preferredLocale)
    }
  }, [localizedRedirect, pathLocale, preferredLocale])

  if (!loaded) {
    return <></>
  }

  return (
    <>
      {/* Preferred locale: {preferredLocale}, {pathLocale} */}
      <I18nProvider i18n={i18n}>{children}</I18nProvider>
    </>
  )
}
