import React from 'react'
import { Redirect } from 'react-router-dom'
import { useLocalizedPath } from '../data/remote/Language'

export type LocalizedRedirectProps = {
  to: string
  push?: boolean
  from?: string
  path?: string
  exact?: boolean
  strict?: boolean
}

export const LocalizedRedirect: React.FC<LocalizedRedirectProps> = ({ to, push, from, path, exact, strict }) => {
  const localizedPath = useLocalizedPath()

  return <Redirect to={localizedPath(to) ?? to} push={push} from={from} path={path} exact={exact} strict={strict} />
}
