import React from 'react'
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { Column } from '../../../components/ListView/Column'
import { ListItem } from '../../../components/ListView/ListItem'
import { useCurrentRoute } from '../../../data/remote/RouteData'
import routeComponents from '../../routeComponents'
import { RouteName } from '../../routeParams'
import { Error404 } from '../Error404'

export const Submenu: React.FC = () => {
  const currentRoute = useCurrentRoute()
  const history = useHistory()
  const location = useLocation()

  if (!currentRoute?.children) {
    return null
  }

  return (
    <>
      <Column autoHideScrollbar>
        {currentRoute.children?.map((item) => {
          return (
            <ListItem
              key={item.id}
              active={
                !!matchPath(location.pathname, {
                  path: item.href
                })
              }
              onClick={() => history.push(item.href)}
            >
              {item.title}
            </ListItem>
          )
        })}
      </Column>
      <Switch>
        {currentRoute.children?.map((route, index) => {
          const Component = routeComponents[route.component as RouteName]
          if (!Component) {
            return null
          }

          return (
            <Route key={index} path={route.href}>
              <Component navigatorType="main" />
            </Route>
          )
        })}
        {currentRoute.children && <Redirect from={location.pathname} exact={true} to={currentRoute.children[0].href} />}
        <Route path="*">
          <Error404 />
        </Route>
      </Switch>
    </>
  )
}
