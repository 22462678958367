import { t } from '@lingui/macro'
import { toNumber } from 'lodash'
import DataColumn, { useColumnData } from '../../../components/ListView/DataColumn'
import { useModalActions } from '../../../components/Overlays/Modal'
import { SupplierDataMinimal, useSuppliers } from '../../../data/remote/Supplier'
import { createRoute } from '../../../navigation/createRoute'
import { AddSteelCertificateModal } from './AddSteelCertificateModal'
import { AddSteelHeatingModal } from './AddSteelHeatingModal'
import { useSteelCertificatesData } from './Columns/useSteelCertificatesData'
import { useSteelHeatingData } from './Columns/useSteelHeatingData'
import SteelWorksCertificateView from './SteelworksCertificateView'
import { useCanManageSteelCertificates } from '../../../data/remote/SteelCertificates'

export const SteelworkManagement = createRoute(
  'SteelworkManagement',
  function Steelworks({ params: { supplierId, steelHeatingId, steelCertificateId }, generatePath, setParams }) {
    const { ref: addSteelHeatingModal, open: openAddSteelHeatingModal } = useModalActions()
    const { ref: addSteelCertificateModal, open: openAddSteelCertificateModal } = useModalActions()
    const addSteelHeating = true ? openAddSteelHeatingModal : undefined
    const addSteelCertificate = true ? openAddSteelCertificateModal : undefined

    const canManageSteelCertificates = useCanManageSteelCertificates()

    const supplierData = useColumnData<SupplierDataMinimal>(
      () => ({
        useItems(search) {
          return useSuppliers({ search, filter: 'steel_all' })
        },
        isActiveItem(supplier) {
          return supplierId !== 'new' && supplier.id === toNumber(supplierId)
        },
        shouldHaveActiveItem: !!supplierId,
        onActiveItemMiss() {
          setParams({
            supplierId: undefined,
            steelHeatingId: undefined,
            steelCertificateId: undefined
          })
        },
        renderItem(supplier) {
          return {
            id: supplier.id,
            title: supplier.company,
            url: generatePath({ supplierId: supplier.id, steelHeatingId: undefined, steelCertificateId: undefined }),
            steelcertificateStatus: supplier.status.steelcertificates
          }
        }
      }),
      [generatePath, setParams, supplierId]
    )

    const steelHeatingData = useSteelHeatingData({
      setParams,
      generatePath,
      supplierId,
      steelHeatingId,
      canManageSteelCertificates
    })

    const steelCertificatesData = useSteelCertificatesData({
      setParams,
      generatePath,
      supplierId,
      steelHeatingId,
      steelCertificateId
    })

    return (
      <>
        <DataColumn
          title={t`Stahlwerke`}
          {...supplierData}
          type="steelworks"
          arrow={supplierId ? 'right' : undefined}
        />
        {supplierData.current && (
          <>
            <DataColumn
              title={t`Schmelze`}
              {...steelHeatingData}
              newLabel={t`Schmelze anlegen`}
              type="steelworks"
              status={steelHeatingData.current?.status}
              arrow={steelHeatingId ? 'right' : undefined}
              onNew={addSteelHeating}
            />

            <AddSteelHeatingModal
              ref={addSteelHeatingModal}
              advancedPermission={canManageSteelCertificates}
              supplierId={supplierId}
              steelHeatingData={steelHeatingData.items}
            />
          </>
        )}
        {steelHeatingData.current && supplierId && (
          <>
            <DataColumn
              title={t`Abmessung`}
              {...steelCertificatesData}
              arrow={steelCertificateId ? 'right' : undefined}
              newLabel={t`Abmessung hinzufügen`}
              type="steelworks"
              onNew={addSteelCertificate}
            />
            {supplierData.current && (
              <AddSteelCertificateModal
                ref={addSteelCertificateModal}
                supplier={supplierData.current}
                steelHeatingId={steelHeatingData.current.id}
              />
            )}
          </>
        )}
        {steelCertificatesData.current && steelHeatingData.current && supplierData.current && (
          <SteelWorksCertificateView
            steelCertificate={steelCertificatesData.current}
            steelHeating={steelHeatingData.current}
            supplier={supplierData.current}
          />
        )}
      </>
    )
  }
)
