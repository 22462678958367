import { useCallback, useEffect, useState } from 'react'
import { generatePath, matchPath, useHistory } from 'react-router-dom'
import { i18n } from '@lingui/core'
import { createApiDataHook } from '../../scripts/api'
import { usePathlangs } from './RouteData'
import { useSWR } from '../local/hooks/swrHooks'

export type LanguageData = {
  uid: number
  id: number
  locale: string
  title: string
  navigationTitle: string
  websiteTitle: string
  twoLetterIsoCode: string
  hreflang: string
  direction: string
}

export const useLanguages = createApiDataHook<LanguageData[], never>('/languages')

/**
 * Make sure the path starts with a slash and that there is no trailing slash (only exception is "/")
 * Will also replace multiple slashes with one single slash ("a///b" => "a/b")
 *
 * @param path input path
 * @returns normalized path
 */
export const normalizePath = (path: string): string => {
  let _path = path.trim().replace(/\/+/g, '/')
  _path = _path.startsWith('/') ? _path : `/${_path}`
  _path = _path.length > 1 && _path.endsWith('/') ? _path.substring(0, _path.length - 1) : _path
  return _path
}

export const getPathLocale = (path: string): string | undefined => {
  const _path = normalizePath(path)
  if (_path.length === 3 || _path.substring(3, 4) === '/') {
    return _path.substring(1, 3)
  }
}

const stripLocale = (path: string, languages: LanguageData[] | undefined): string => {
  const _path = normalizePath(path)

  for (const language of languages ?? []) {
    if (_path === `/${language.twoLetterIsoCode}`) {
      return '/'
    }
    if (_path.startsWith(`/${language.twoLetterIsoCode}/`)) {
      return _path.substring(3)
    }
  }
  return _path
}

export const useCurrentLocale = (): string => {
  const [locale, setLocale] = useState(i18n.locale)
  useEffect(
    () =>
      i18n.on('change', () => {
        setLocale(i18n.locale)
      }),
    []
  )
  return locale
}

export const useCurrentLanguage = (): LanguageData | undefined => {
  const { data: languages } = useSWR<LanguageData[]>('/de/languages')
  const locale = useCurrentLocale()
  return languages?.find((x) => x.twoLetterIsoCode === locale)
}

export const useLocalizedPath = (): ((
  path: string | number | undefined,
  locale?: string | number
) => string | undefined) => {
  const { data: languages } = useSWR<LanguageData[]>('/de/languages')
  const routes = usePathlangs()
  const currentLocale = useCurrentLocale()

  return useCallback(
    function localizedPath(path, locale): string | undefined {
      if (typeof path === 'undefined') {
        return undefined
      }
      const _locale =
        (typeof locale === 'string'
          ? languages?.find((x) => x.twoLetterIsoCode === locale)?.twoLetterIsoCode
          : typeof locale === 'number'
          ? languages?.find((x) => x.id === locale)?.twoLetterIsoCode
          : undefined) ?? currentLocale
      if (!_locale) {
        return undefined
      }
      if (typeof path === 'number') {
        const href = routes?.find((x) => x.id === path)?.hreflang[_locale]
        return href
      }
      const _path = stripLocale(path, languages)
      if (_path === '/') {
        return '/' + _locale
      }
      if (!languages) {
        console.warn('Calling localizedPath before loading all languages')
        return _path
      }
      if (!routes) {
        return undefined
      }
      for (const route of routes) {
        for (const routepath of Object.values(route.pathlang)) {
          const match = matchPath(routepath.substring(3) + '/*', _path)
          if (match && _locale in route.pathlang) {
            return generatePath(route.pathlang[_locale], match.params)
          }
        }
      }
      return undefined
    },
    [currentLocale, languages, routes]
  )
}

export const useLocalizedRedirect = (): ((path: string, locale?: string | number) => void) => {
  const localizedPath = useLocalizedPath()
  const routes = usePathlangs()
  const navigate = useHistory()
  return useCallback(
    (path, locale): void => {
      const gotoPath = localizedPath(path, locale)
      if (gotoPath && routes?.length) {
        navigate.push(gotoPath)
      }
    },
    [navigate, localizedPath, routes?.length]
  )
}
