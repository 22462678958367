import { t } from '@lingui/macro'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useRef } from 'react'
import DataTable, { DataTableItem, dynamicRows, sectionHeader } from '../../../../components/DataTable/DataTable'
import TableCommentsIcon from '../../../../components/DataTable/TableCommentsIcon'
import TableStatusIcon from '../../../../components/DataTable/TableStatusIcon'
import {
  CertificateDataMinimal,
  getStatusText,
  useCertificates,
  useUpdateCertificateApproval
} from '../../../../data/remote/Certificate'
import { CertificateCodeDataMinimal } from '../../../../data/remote/CertificateCode'
import { InternalFileDataMinimal, useInternalFiles } from '../../../../data/remote/InternalFile'
import { DropdownItem, DropdownSeperator } from '../../../../components/Overlays/Dropdown'
import { CommentTarget, useCanWriteComments } from '../../../../data/remote/Comment'
import DotsMenu from '../../../../components/DotsMenu'
import { Header } from '../../../../components/ListView/Header'
import AddCommentModal, { AddCommentModalRef } from '../../Certificates/AddCommentModal'

export type CertificateFilesTableProps = {
  certificateCode: CertificateCodeDataMinimal | undefined
}

type ItemType = CertificateDataMinimal | InternalFileDataMinimal

const isCertificate = (item: ItemType): item is CertificateDataMinimal => {
  return !('type' in item) && 'status' in item
}

const isInternalFile = (item: ItemType): item is InternalFileDataMinimal => {
  return !('type' in item) && !('status' in item)
}

const CertificateMenu: React.FC<{
  certificate: CertificateDataMinimal
  addComment?: (target: CommentTarget) => void
  canComment: boolean
}> = ({ certificate, canComment, addComment }) => {
  const addCommentClick = useCallback(() => {
    addComment?.({
      type: 'certificates',
      id: certificate.id
    })
  }, [addComment, certificate.id])

  const updateCertificateApproval = useUpdateCertificateApproval(certificate.id)

  return (
    <DotsMenu>
      {updateCertificateApproval.actionAllowed && (
        <>
          <DropdownItem
            key="accept"
            onClick={updateCertificateApproval.approve}
            disabled={certificate.status?.approval === 'approved'}
          >{t`Zeugnis freigeben`}</DropdownItem>
          <DropdownItem
            key="decline"
            onClick={updateCertificateApproval.deny}
            disabled={certificate.status?.approval === 'denied'}
          >{t`Zeugnis ablehnen`}</DropdownItem>
          <DropdownSeperator key="divider" />
        </>
      )}
      {canComment && <DropdownItem key="comment" onClick={addCommentClick}>{t`Kommentar hinzufügen`}</DropdownItem>}
      <DropdownItem href={certificate.file.publicUrl} key="download">{t`Zeugnis öffnen`}</DropdownItem>
    </DotsMenu>
  )
}

const InternalFileMenu: React.FC<{
  file: InternalFileDataMinimal
  canComment: boolean
  addComment?: (target: CommentTarget) => void
}> = ({ file, canComment, addComment }) => {
  const addCommentClick = useCallback(() => {
    addComment?.({
      type: 'internalfiles',
      id: file.id
    })
  }, [addComment, file.id])

  return (
    <DotsMenu>
      {canComment && <DropdownItem key="comment" onClick={addCommentClick}>{t`Kommentar hinzufügen`}</DropdownItem>}
      <DropdownItem href={file.file.publicUrl} key="download">{t`Öffnen`}</DropdownItem>
    </DotsMenu>
  )
}

const SearchFilesTable: React.FC<CertificateFilesTableProps> = ({ certificateCode }) => {
  const showComments = useCanWriteComments()

  const addComment = useRef<AddCommentModalRef>(null)
  const { data: certificates, loading: certificatesLoading } = useCertificates(
    useMemo(
      () => ({
        codeId: certificateCode?.id
      }),
      [certificateCode?.id]
    ),
    !!certificateCode?.id
  )

  const { data: internalFiles, loading: internalFilesLoading } = useInternalFiles(
    { codeId: certificateCode?.id },
    !!certificateCode?.id
  )

  const columns = useMemo(
    () => ({
      name: t`Dateiname`,
      date: t`Änderungsdatum`,
      comments: '',
      status: t`Status`,
      menu: ''
    }),
    []
  )

  const columnClasses = useMemo(
    () => ({
      menu: 'text-right'
    }),
    []
  )

  const items = useMemo<DataTableItem<ItemType>[]>(
    () =>
      certificateCode
        ? [
            ...dynamicRows(
              certificates,
              certificatesLoading,
              t`Für diesen Zeugniscode wurden noch keine Dateien hochgeladen`
            ),
            ...(internalFiles && internalFiles.length
              ? [
                  ...sectionHeader({
                    title: t`Interne Dateien`
                  }),
                  ...dynamicRows(
                    internalFiles,
                    internalFilesLoading,
                    t`Für diesen Zeugniscode wurden noch keine internen Dateien hochgeladen`
                  )
                ]
              : [])
          ]
        : [],
    [certificateCode, certificates, certificatesLoading, internalFiles, internalFilesLoading]
  )

  return (
    <div className="d-flex flex-column flex-grow-1">
      <Header title={t`Zeugnisse`} />
      <div className="d-flex flex-grow-1">
        <DataTable
          items={items}
          columns={columns}
          columnClasses={columnClasses}
          hiddenColumns={useMemo(() => (showComments ? [] : ['comments']), [showComments])}
        >
          {useCallback(
            (item: ItemType) =>
              isCertificate(item)
                ? {
                    name: (
                      <a
                        href={item.file.publicUrl}
                        target="_blank"
                        download={item.file.properties.filename}
                        rel="noreferrer"
                      >
                        {item.file.properties.title}
                      </a>
                    ),
                    date: dayjs(item.tstamp).format('L'),
                    comments: (
                      <TableCommentsIcon
                        onOpen={addComment.current?.open}
                        forType="certificates"
                        forId={item.id}
                        count={item.comments}
                      />
                    ),
                    status: <TableStatusIcon status={item.status.approval} tooltip={getStatusText(item.status)} />,
                    menu: (
                      <CertificateMenu
                        certificate={item}
                        addComment={addComment.current?.open}
                        canComment={showComments}
                      />
                    )
                  }
                : isInternalFile(item)
                ? {
                    name: (
                      <a
                        href={item.file.publicUrl}
                        target="_blank"
                        download={item.file.properties.filename}
                        rel="noreferrer"
                      >
                        {item.file.properties.title}
                      </a>
                    ),
                    date: dayjs(item.tstamp).format('L'),
                    comments: (
                      <TableCommentsIcon
                        onOpen={addComment.current?.open}
                        forType="internalfiles"
                        forId={item.id}
                        count={item.comments}
                      />
                    ),
                    status: (
                      <TableStatusIcon
                        status={item.public ? 'visible' : 'hidden'}
                        tooltip={item.public ? t`sichtbar` : t`unsichtbar`}
                      />
                    ),
                    menu: (
                      <InternalFileMenu file={item} canComment={showComments} addComment={addComment.current?.open} />
                    )
                  }
                : undefined,
            [showComments]
          )}
        </DataTable>
      </div>
      <AddCommentModal ref={addComment} />
    </div>
  )
}

export default SearchFilesTable
