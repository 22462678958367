import { ColumnData, useColumnData } from '../../../../components/ListView/DataColumn'
import { SteelCertificatesData, useSteelCertificates } from '../../../../data/remote/SteelCertificates'
import { SetParamsAction } from '../../../../navigation/createRoute'

export const useSteelCertificatesData = ({
  setParams,
  generatePath,
  supplierId,
  steelHeatingId,
  steelCertificateId
}: {
  setParams: (
    action: SetParamsAction<'SteelworkManagement' | 'SteelworkProtocols'>,
    clearOptionals?: boolean | undefined
  ) => void
  generatePath: (
    action: SetParamsAction<'SteelworkManagement' | 'SteelworkProtocols'>,
    clearOptionals?: boolean | undefined
  ) => string
  supplierId: string | number | undefined
  steelHeatingId?: string | undefined
  steelCertificateId?: string | undefined
}): ColumnData<SteelCertificatesData> =>
  useColumnData<SteelCertificatesData>(
    () => ({
      useItems(search, filter) {
        return useSteelCertificates(
          steelHeatingId
            ? {
                search,
                filter,
                steelHeatingId
              }
            : undefined,
          !!steelHeatingId
        )
      },
      isActiveItem(item) {
        if (!item || item.id === undefined || item.id === null) {
          return false
        }
        return item.id.toString() === steelCertificateId
      },
      shouldHaveActiveItem: !!steelCertificateId,
      onActiveItemMiss() {
        setParams({ supplierId, steelHeatingId, steelCertificateId: undefined })
      },
      filterOptions: {},
      renderItem: (steelCertificate) => ({
        id: steelCertificate.id,
        title: steelCertificate.product.title,
        additionalLabel: steelCertificate.product.lsw,
        url: generatePath({
          supplierId,
          steelHeatingId,
          steelCertificateId: steelCertificate.id
        }),
        heatingStatus: steelCertificate.status
      })
    }),
    [generatePath, setParams, steelCertificateId, steelHeatingId, supplierId]
  )
