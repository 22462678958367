import React, { useEffect } from 'react'
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay'
import { useResizeDetector } from 'react-resize-detector'

export const makeResizableOverlay = <T extends React.PropsWithChildren<OverlayInjectedProps & { id: string }>>(
  Component: React.ComponentType<T>
) =>
  React.forwardRef<HTMLDivElement, T>(function UpdatingPopover(props, ref) {
    const { width, height, ref: contentRef } = useResizeDetector<HTMLDivElement>()

    useEffect(() => {
      const popover = contentRef.current?.parentElement?.parentElement

      popover?.classList.add('animated-size')
      props.popper.scheduleUpdate()
      const timeout = setTimeout(() => {
        popover?.classList.remove('animated-size')
      }, 200)
      return () => clearTimeout(timeout)
    }, [width, height, props.popper, contentRef])

    return (
      <Component {...props} ref={ref}>
        <div ref={contentRef}>{props.children}</div>
      </Component>
    )
  })
