import React, { useEffect } from 'react'
import { useAuthentication } from '../../data/remote/User'

export const Logout: React.FC = () => {
  const { logout } = useAuthentication()

  useEffect(() => {
    logout()
  }, [logout])

  return null
}
